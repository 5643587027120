export default {
  id: "StudentExercise",
  states: {
    "StudentExerciseHasReachedAttemptThreshold?": {
      id: "StudentExerciseHasReachedAttemptThreshold?",
      states: {},
      on: {
        THRESHOLD_NOT_REACHED: "#StudentExerciseDisplayTooltip",
        THRESHOLD_REACHED: "#StudentExerciseExecuteLaneAdjustments",
      },
    },
    StudentExerciseLanding: {
      id: "StudentExerciseLanding",
      states: {},
      on: {
        EXERCISE_START: "#StudentExerciseSetup",
        SESSION_CLOSED: "#StudentSignedOut",
      },
    },
    StudentExerciseSetup: {
      id: "StudentExerciseSetup",
      states: {},
      on: {
        SESSION_CLOSED: "#StudentSignedOut",
        SETUP_COMPLETE: "#StudentQuestionSetup",
      },
    },
    StudentExerciseFinished: {
      id: "StudentExerciseFinished",
      states: {},
      on: {
        PROGRAM_CONTINUE: "#StudentProgramStart",
        PROGRAM_DONE: "#StudentProgramDone",
        EXERCISE_RESTART: "#StudentExerciseSetup",
      },
    },
    "StudentExerciseHasFollowingQuestion?": {
      id: "StudentExerciseHasFollowingQuestion?",
      states: {},
      on: {
        FOLLOWING_QUESTION_EMPTY: "#StudentExerciseFinished",
        FOLLOWING_QUESTION_EXISTS: "#StudentQuestionSetup",
      },
    },
    StudentExerciseCalculateRewards: {
      id: "StudentExerciseCalculateRewards",
      states: {},
      on: {
        CONTINUE: "#StudentExerciseHasFollowingQuestion?",
      },
    },
  },
  initial: "StudentExerciseSetup",
  on: {},
}
