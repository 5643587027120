import React from "react"

const NoticeAction = ({ action, children, type = "primary" }) => (
  <div className="d-flex justify-content-center">
    <button className={`btn btn-lg btn-${type}`} onClick={action}>
      {children}
    </button>
  </div>
)

export default NoticeAction
