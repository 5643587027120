import "./ProgressItem.scss"

import React, { Component } from "react"

import { object, string, bool, number, func } from "prop-types"
import classNames from "classnames"
import cond from "ramda/src/cond"
import prop from "ramda/src/prop"
import always from "ramda/src/always"
import equals from "ramda/src/equals"
import T from "ramda/src/T"

import { Asset } from "components/Assets"
import { getProgramCover, getProgramTitle } from "helpers/data/program"

export default class ProgressItem extends Component {
  static propTypes = {
    program: object,
    learning_program_title: string,
    active: bool,
    done: bool,
    locked: bool,
    countExercisesDone: number,
    countExercisesTotal: number,
    onClick: func,
    learningRecommendationProgramId: string,
  }

  handleClick = () => {
    const { onClick } = this.props
    if (onClick) {
      onClick()
    }
  }

  render = () => {
    const { program, learningRecommendationProgramId } = this.props

    const isProgramActive = prop("active", program)
    const isProgramDone = prop("done", program)
    const isProgramLocked = prop("locked", program)
    const programId = prop("learning_program_id", program)

    const programExercisesDoneCount = prop("countExercisesDone", program)
    const programExercisesTotalCount = prop("countExercisesTotal", program)

    const itemClasses = classNames("progress-item d-flex flex-row", {
      "progress-item--active": isProgramActive,
      "progress-item--done": isProgramDone,
      "progress-item--locked": isProgramLocked,
    })

    const iconPostfix = cond([
      [prop("active"), always("active")],
      [prop("locked"), always("locked")],
      [T, always("done")],
    ])(program)

    const isLearningRecommendation = equals(
      learningRecommendationProgramId,
      programId,
    )

    const programCover = getProgramCover("default", this.props.program)
    const programCoverElement = programCover ? (
      <Asset
        path={`programs/${programCover}`}
        className="progress-item__icon"
        type="svg"
      />
    ) : (
      <Asset
        path={`program-overview.visual.${iconPostfix}`}
        className="progress-item__icon"
        type="svg"
      />
    )

    return (
      <div onClick={this.handleClick} className={itemClasses}>
        {programCoverElement}
        <div className="d-flex flex-column progress-item__text flex-grow-1 mr-2">
          {isLearningRecommendation &&
            !isProgramDone && (
              <div className="progress-item__recommendation font-weight-bold">
                Starte hier 👉
              </div>
            )}
          <div className="progress-item__title font-weight-bold">
            {getProgramTitle(program)}
          </div>
          {isProgramActive && (
            <div className="progress-item__exercises font-weight-light font-size-sm">
              <span className={"font-weight-normal"}>
                {programExercisesDoneCount}
              </span>{" "}
              Sterne von{" "}
              <span className={"font-weight-normal"}>
                {programExercisesTotalCount}
              </span>
            </div>
          )}
        </div>
        {/*{isProgramActive && (*/}
        {/*<div className="progress-item__start">*/}
        {/*starten*/}
        {/*<Asset*/}
        {/*className="progress-item__start-icon"*/}
        {/*type="svg"*/}
        {/*path="button_forward"*/}
        {/*/>*/}
        {/*</div>*/}
        {/*)}*/}
      </div>
    )
  }
}
