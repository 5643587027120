import React, { Component } from "react"
import { Asset } from "components/Assets"

import "./Ground.scss"

class Ground extends Component {
  render = () => (
    <>
      <Asset
        type="svg"
        path="ground"
        className="fixed-bottom"
        style={{ height: "auto", width: "100%", zIndex: -1 }}
      />
    </>
  )
}

export default Ground
