import "babel-polyfill"
import "./scss/bootstrap.scss"

import FastClick from "fastclick"

import React from "react"
import ReactDOM from "react-dom"
import path from "ramda/src/path"
import lte from "ramda/src/lte"
import compareVersions from "compare-versions"
import App from "components/App/App"
import GenericWarning from "components/Assets/GenericWarning/GenericWarning"
import DeprecatedVersionWarning from "components/Assets/DeprecatedVersionWarning/DeprecatedVersionWarning"
import { unregister } from "./registerServiceWorker"
import { getVersion } from "helpers/firebase/firestore"
import pckgjsn from "../package.json"

const checkVersion = minimumVersion => {
  lte(0, compareVersions(pckgjsn.version, minimumVersion))
    ? startApp()
    : startApp(DeprecatedVersionWarning)
}

const fetchVersion = () => {
  getVersion()
    .map(path(["data", "minimumVersion"]))
    .fork(() => startApp(GenericWarning), checkVersion)
}

const startApp = (Component = App) => {
  ReactDOM.render(<Component />, document.getElementById("root"))
}

if (!window.cordova) {
  startApp()
} else {
  document.addEventListener("deviceready", fetchVersion)
  document.addEventListener("resume", fetchVersion)
  document.addEventListener("online", fetchVersion)
  window.addEventListener("online", fetchVersion)
  window.addEventListener("load", () => {
    FastClick.attach(document.body)
  })
}

unregister()
