import React from "react"
import { StateMachineContext } from "contexts"
import { State } from "react-automata"

import Placeholder from "components/Chat/Placeholder/Placeholder"

import addIndex from "ramda/src/addIndex"
import always from "ramda/src/always"
import compose from "ramda/src/compose"
import cond from "ramda/src/cond"
import equals from "ramda/src/equals"
import filter from "ramda/src/filter"
import isNil from "ramda/src/isNil"
import length from "ramda/src/length"
import map from "ramda/src/map"
import not from "ramda/src/not"
import slice from "ramda/src/slice"
import T from "ramda/src/T"

const StudentQuestionAnswerMultiple = ({
  bucket,
  row,
  studentAnswer,
  questionAnswer,
}) => {
  return addIndex(map)(
    (rowContent, rowContentIndex) =>
      cond([
        [
          isNil,
          () => {
            const nullIndex = compose(
              length,
              filter(isNil),
              slice(0, rowContentIndex),
            )(row)

            const position = bucket[nullIndex]

            const hasSuccess = equals(
              studentAnswer[position],
              questionAnswer[position],
            )
            const hasError = not(hasSuccess)

            return (
              <React.Fragment key={rowContentIndex}>
                <State is="*StudentQuestionAnswerEvaluation">
                  <Placeholder
                    type={"bubble"}
                    hasError={hasError}
                    hasSuccess={hasSuccess}
                  >
                    {studentAnswer[position]}
                  </Placeholder>
                </State>
                <State
                  is={[
                    "*StudentQuestionAnswerEmpty",
                    "*StudentQuestionAnswerNotEmpty",
                    "*StudentQuestionAnswerComplete",
                  ]}
                >
                  <Placeholder type={"bubble"}>
                    {studentAnswer[position]}
                  </Placeholder>
                </State>
              </React.Fragment>
            )
          },
        ],
        [
          T,
          always(
            <span className="placeholder-filled" key={rowContentIndex}>
              {rowContent}
            </span>,
          ),
        ],
      ])(rowContent),
    row,
  )
}

export default componentProps => (
  <StateMachineContext.Consumer>
    {props => <StudentQuestionAnswerMultiple {...componentProps} {...props} />}
  </StateMachineContext.Consumer>
)
