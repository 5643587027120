import React, { Component } from "react"

import { State } from "react-automata"
import { StateMachineContext } from "contexts"

import StudentExerciseSetup from "./StudentExerciseSetup/StudentExerciseSetup"
import StudentExerciseExecuteLaneAdjustments from "./StudentExerciseExecuteLaneAdjustments/StudentExerciseExecuteLaneAdjustments"
import StudentExerciseFinished from "./StudentExerciseFinished/StudentExerciseFinished"
import StudentExerciseCalculateRewards from "./StudentExerciseCalculateRewards/StudentExerciseCalculateRewards"
import StudentExerciseHasFollowingQuestion from "./StudentExerciseHasFollowingQuestion/StudentExerciseHasFollowingQuestion"

class StudentExerciseComponent extends Component {
  render() {
    return (
      <>
        <State is={["*Setup*"]}>
          <StudentExerciseSetup />
        </State>
        <State is={["*ExecuteLaneAdjustments"]}>
          <StudentExerciseExecuteLaneAdjustments />
        </State>
        <State is={["*Finished"]}>
          <StudentExerciseFinished />
        </State>
        <State is={["*CalculateRewards"]}>
          <StudentExerciseCalculateRewards />
        </State>
        <State is={["*HasFollowingQuestion?"]}>
          <StudentExerciseHasFollowingQuestion />
        </State>
      </>
    )
  }
}

const StudentExercise = () => (
  <StateMachineContext.Consumer>
    {props => <StudentExerciseComponent {...props} />}
  </StateMachineContext.Consumer>
)

export default StudentExercise
