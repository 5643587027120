/* eslint-disable no-console, react/display-name */
import React from "react"
import { node } from "prop-types"
import Async from "crocks/Async"

import { getDocumentById } from "helpers/firebase/firestore"
import { auth } from "helpers/firebase"
import {
  getStudentHistoryExerciseId,
  getStudentHistoryExercisesByProgramId,
} from "helpers/data/studentHistoryExercises"
import {
  getChapterById,
  getChapterPrograms,
  getChapterRelatedPrograms,
} from "helpers/data/chapter"
import { getProgramExercises, getProgramId } from "helpers/data/program"
import { firestore } from "helpers/firebase"

import add from "ramda/src/add"
import compose from "ramda/src/compose"
import cond from "ramda/src/cond"
import flatten from "ramda/src/flatten"
import isNil from "ramda/src/isNil"
import length from "ramda/src/length"
import map from "ramda/src/map"
import not from "ramda/src/not"
import reduce from "ramda/src/reduce"
import subtract from "ramda/src/subtract"
import T from "ramda/src/T"
import uniqBy from "ramda/src/uniqBy"

const UserContext = React.createContext()
const UserContextConsumer = UserContext.Consumer

const withUser = Component => ({ children }) => (
  <UserContextConsumer>
    {userContext => <Component userContext={userContext}>{children}</Component>}
  </UserContextConsumer>
)

const addCompletedUniqueExercises = (programs, program) =>
  add(programs, length(getProgramExercises(program)))

// const addCompletedUniqueExercises = (programs, program) =>
//   compose(
//     add(programs, length(getProgramExercises(program))),
//     uniqBy(getStudentHistoryExerciseId),
//   )

const sumExercisesInProgramsList = programList =>
  reduce(addCompletedUniqueExercises, 0, programList)

const initialState = {
  student: null,
  exercisesDone: 0,
  exercisesExtra: 0,
  exercisesTotal: 0,
  learningRecommendation: null,
}

class UserContextProvider extends React.Component {
  static propTypes = {
    children: node.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      ...initialState,
      setLearningRecommendation: this.setLearningRecommendation.bind(this),
    }

    this.fetchUser = this.fetchUser.bind(this)
    this.fetchExercises = this.fetchExercises.bind(this)
    this.addListeners = this.addListeners.bind(this)
    this.removeListeners = this.removeListeners.bind(this)
    this.resetContext = this.resetContext.bind(this)

    auth.onAuthStateChanged(
      cond([
        [
          compose(
            not,
            isNil,
          ),
          this.fetchUser,
        ],
        [T, this.resetContext],
      ]),
    )
  }

  componentWillUnmount() {
    this.removeListeners()
  }

  removeListeners() {
    try {
      this.unsubscribeExercisesCollection()
    } catch (err) {
      // No collection to unsubscribe
    }
  }

  addListeners() {
    const {
      student: { id },
    } = this.state
    this.unsubscribeExercisesCollection = firestore
      .collection(`school_students/${id}/student_history_exercises`)
      .onSnapshot(this.fetchExercises)
  }

  fetchUser({ uid }) {
    getDocumentById("school_students", uid).fork(console.error, student => {
      this.setState({ student }, this.addListeners)
    })
  }

  fetchExercises() {
    Async.all([getChapterById("MATH01"), getChapterById("MATH02")]).fork(
      console.error,
      chapterList => map(this.getChapterPrograms, chapterList),
    )
  }

  getChapterPrograms = chapter => {
    const { student } = this.state
    const chapterPrograms = getChapterPrograms(chapter)

    getChapterRelatedPrograms(chapterPrograms).fork(
      console.error,
      chapterRelatedPrograms => {
        const programExerciseCountRequestList = map(
          _ =>
            getStudentHistoryExercisesByProgramId(student.id, getProgramId(_)),
          chapterRelatedPrograms,
        )

        this.setState(
          {
            student,
            exercisesDone: 0,
            exercisesExtra: 0,
            exercisesTotal: 0,
          },
          () =>
            Async.all(programExerciseCountRequestList).fork(
              console.error,
              programHistoryList => {
                const flatHistoryList = flatten(programHistoryList)

                const exercisesDone = compose(
                  length,
                  uniqBy(getStudentHistoryExerciseId),
                )(flatHistoryList)

                const exercisesExtra = compose(
                  _ => subtract(_, exercisesDone),
                  length,
                )(flatHistoryList)

                const exercisesTotal = sumExercisesInProgramsList(
                  chapterRelatedPrograms,
                )

                return this.setState({
                  exercisesDone: this.state.exercisesDone + exercisesDone,
                  exercisesExtra: this.state.exercisesExtra + exercisesExtra,
                  exercisesTotal: this.state.exercisesTotal + exercisesTotal,
                })
              },
            ),
        )
      },
    )
  }

  resetContext() {
    this.setState(initialState)
    this.removeListeners()
  }

  setLearningRecommendation(learningRecommendation) {
    this.setState({ learningRecommendation })
  }

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

export { UserContextProvider, UserContextConsumer, withUser }
export default UserContext
