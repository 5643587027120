import React from "react"
import { Container } from "reactstrap"
import FadeInOut from "components/Animations/FadeInOut"
import { Asset } from "components/Assets"

const Loader = () => (
  <FadeInOut delay={380}>
    <Container
      fluid
      className="d-flex align-items-center justify-content-center main-container"
    >
      <div className="d-flex flex-column text-center">
        <div>
          <Asset
            path="piggie_loader"
            className="align-self-center"
            style={{ height: "80px", width: "80px" }}
            type="svg"
          />
        </div>
        <div className={"mt-3 text-brainimals-dark"}>Lädt...</div>
      </div>
    </Container>
  </FadeInOut>
)

export default Loader
