import translations from "translations"

import compose from "ramda/src/compose"
import curry from "ramda/src/curry"
import isNil from "ramda/src/isNil"
import prop from "ramda/src/prop"

export const getTranslation = curry((locale, type, key) =>
  compose(
    _ => {
      const selectedTranslation = prop(locale, _)
      const fallbackTranslation = prop("de-DE", _)
      return isNil(selectedTranslation)
        ? fallbackTranslation
        : selectedTranslation
    },
    _ => _.get(key),
    prop(type),
  )(translations),
)
