import React, { Component } from "react"

import { AuthenticationContext, StateMachineContext } from "contexts"

import { auth } from "helpers/firebase"
import { getDocumentById } from "helpers/firebase/firestore"

import compose from "ramda/src/compose"
import cond from "ramda/src/cond"
import isNil from "ramda/src/isNil"
import not from "ramda/src/not"
import T from "ramda/src/T"

import { Loader } from "components/Assets"

class StudentHasSessionComponent extends Component {
  constructor(props) {
    super(props)

    const { transition } = props

    auth.onAuthStateChanged(
      cond([
        [
          compose(
            not,
            isNil,
          ),
          _ => {
            // TODO: Double check is context update always is correct
            getDocumentById("school_students", _.uid).fork(
              (er) => {
                console.log('ERROR GETTING STUDENT', er)
              },
              student => {
                transition("SESSION_EXISTS")
                this.props.studentContext.updateStudentSession(student)
              }
            )
          },
        ],
        [T, () => transition("SESSION_EMPTY")],
      ]),
    )
  }
  render() {
    return <Loader />
  }
}

const StudentHasSession = () => (
  <AuthenticationContext.Consumer>
    {student => (
      <StateMachineContext.Consumer>
        {props => <StudentHasSessionComponent {...props} studentContext={student} />}
      </StateMachineContext.Consumer>
    )}
  </AuthenticationContext.Consumer>
)

export default StudentHasSession
