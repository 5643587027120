import { firestore } from "firebase/app"
import { getProgramId, getProgramIsAssessment } from "helpers/data/program"

import {
  getStudentSubcollection,
  getStudentPath,
} from "helpers/data/student"

export const addHistoryProgram = async data => {
  const { program, difficultyLevel } = data

  const doc = {
    learning_program: firestore().doc(
      `/learning_programs/${getProgramId(program)}`,
    ),
    history_program_time_finished_at: Date.now(),
    history_program_is_assessment: getProgramIsAssessment(program),
  }

  if (getProgramIsAssessment(program)) {
    doc.history_program_difficulty_level = difficultyLevel
    const userDoc = firestore().doc(getStudentPath())
    await userDoc.update({
      student_dl: firestore.FieldValue.arrayUnion({
        learning_program_id: getProgramId(program),
        dl: difficultyLevel,
      }),
    })
  }

  await getStudentSubcollection("program").add(doc)
}
