/* eslint-disable no-console */
import React from "react"
import { Detector } from "react-detect-offline"
import { AuthenticationContext } from "contexts"
import { rejectAfter } from "crocks/Async"

import { retrieveUserAuthentication } from "helpers/firebase/auth"
import { getDocumentById } from "helpers/firebase/firestore"
import compose from "ramda/src/compose"
import cond from "ramda/src/cond"
import equals from "ramda/src/equals"
import prop from "ramda/src/prop"
import T from "ramda/src/T"

import { consoleInfo } from "helpers/generic"

import Student from "models/Student"

export default Component =>
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props)
      this.runUserAuthenticationRetrieval = this.runUserAuthenticationRetrieval.bind(
        this,
      )
      this.resolveAuthenticationFailure = this.resolveAuthenticationFailure.bind(
        this,
      )
      this.updateStudentSession = this.updateStudentSession.bind(this)
      this.removeStudentSession = this.removeStudentSession.bind(this)

      this.runUserAuthenticationRetrieval()
    }

    state = { student: null }

    runUserAuthenticationRetrieval() {
      return rejectAfter(3000, location.reload.bind(location)) // eslint-disable-line no-restricted-globals
        .race(retrieveUserAuthentication)
        .chain(({ uid }) => getDocumentById("school_students", uid))
        .fork(this.resolveAuthenticationFailure, this.updateStudentSession)
    }

    removeStudentSession = compose(
      () => this.setState({ student: null }),
      _ => consoleInfo("No session available.")(_),
    )

    resolveAuthenticationFailure(input) {
      return cond([
        [equals("function"), input],
        [T, this.removeStudentSession],
      ])(typeof input)
    }

    componentDidMount() {
      window.addEventListener(
        "online",
        this.runUserAuthenticationRetrieval,
        false,
      )
    }

    componentWillUnmount() {
      window.removeEventListener("online", this.runUserAuthenticationRetrieval)
    }

    updateStudentSession = compose(
      cond([
        [
          // TODO: Move check for existence to getDocumentById method and make it fail there
          compose(
            equals(true),
            prop("exists"),
          ),
          _ => {
            consoleInfo("Got student document, setting model")()
            this.setState({ student: Student(_.data(), _.id) })
          },
        ],
        [T, () => consoleInfo("No student document")()],
      ]),
      _ => {
        consoleInfo("Session available.")(_, _.data())
        return _
      },
    )

    render() {
      const value = {
        ...this.state.student,
        updateStudentSession: this.updateStudentSession.bind(this),
      }
      return (
        <AuthenticationContext.Provider value={value}>
          <>
            <Detector
              onChange={online =>
                online ? this.runUserAuthenticationRetrieval : null
              }
              render={() => <span />}
            />
            <Component />
          </>
        </AuthenticationContext.Provider>
      )
    }
  }
