import "./StudentSignedInOnboarding.scss"

import { AuthenticationContext, StateMachineContext } from "contexts"
import React, { Component } from "react"
import Swipeable from "react-swipeable"

import cond from "ramda/src/cond"
import equals from "ramda/src/equals"
import not from "ramda/src/not"
import isNil from "ramda/src/isNil"
import prop from "ramda/src/prop"
import gt from "ramda/src/gt"
import lt from "ramda/src/lt"

import FadeInOut from "components/Animations/FadeInOut"
import { withUser } from "contexts/UserContext"

import { Asset, Loader } from "components/Assets"
import Owl from "components/Assets/Owl/Owl"

import { Button, Carousel, CarouselItem, CarouselIndicators } from "reactstrap"

import { studentIsOnboarded, updateStudent } from "helpers/data/student"
import { consoleDebug } from "helpers/generic"

const getRaw = prop("_raw")

class StudentSignedInOnboardingComponent extends Component {
  owlReference = React.createRef()

  constructor(props) {
    super(props)

    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)

    this.goToIndex = this.goToIndex.bind(this)

    this.goToPreviousSlide = this.goToPreviousSlide.bind(this)
    this.goToNextSlide = this.goToNextSlide.bind(this)

    this.verifyStudentSession()
  }

  verifyStudentSession() {
    cond([
      [
        equals(true),
        () =>
          window.requestAnimationFrame(() =>
            this.props.transition("GOTO_LANDING"),
          ),
      ],
    ])(studentIsOnboarded(getRaw(this.props.student)))
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      not(equals(getRaw(this.props.student), getRaw(nextProps.student))) ||
      not(equals(this.state, nextState)) ||
      not(
        equals(
          this.props.userContext.learningRecommendation,
          nextProps.userContext.learningRecommendation,
        ),
      )
    )
  }

  componentDidUpdate() {
    this.verifyStudentSession()
  }

  state = {
    activeIndex: 0,
    animating: false,
    isOnboarded: false,
  }

  onExiting() {
    this.setState({ animating: true })
  }

  onExited() {
    this.setState({ animating: false })
  }

  goToPreviousSlide() {
    if (this.state.animating) return
    const { activeIndex } = this.state

    cond([[lt(0), () => this.setState({ activeIndex: activeIndex - 1 })]])(
      activeIndex,
    )
  }

  goToNextSlide() {
    if (this.state.animating) return
    const { activeIndex } = this.state

    cond([
      [
        gt(this.itemList.length - 1),
        () => this.setState({ activeIndex: activeIndex + 1 }),
      ],
    ])(activeIndex)
  }

  goToIndex(newIndex) {
    if (this.state.animating) return

    this.setState({ activeIndex: newIndex })
  }

  goToLanding(learningRecommendationIndex) {
    const studentRecord = getRaw(this.props.student)
    this.props.userContext.setLearningRecommendation(learningRecommendationIndex)

    updateStudent(studentRecord, {
      student_is_onboarded: true,
    }).fork(console.error, () => this.props.transition("GOTO_LANDING"))
  }

  loadTimeout = setTimeout(location.reload.bind(location), 3000) // eslint-disable-line

  itemList = [
    {
      content: (
        <div className="d-flex flex-column h-100">
          <div>
            <div className="col-12 mx-auto p-0">
              <p className="onboarding-carousel-title text-brainimals-dark">
                Wir sind die OLLIS!
              </p>
            </div>
            <div className="col-10 mx-auto p-0">
              <p className="onboarding-carousel-description text-brainimals-dark">
                Einfach und mit viel Spaß Mathematik lernen!
                <br />
                Meine tierischen Freunde und ich helfen dir bei den Aufgaben.
                <br />
                Du wirst staunen, wie schnell du das kannst!
              </p>
            </div>
          </div>
          <div className="flex-grow-1 position-relative d-flex align-content-center m-1">
            <div className="col-6 m-auto">
              <Owl
                startWith="wave"
                defaultAnimation="wave"
                minInterval={15000}
                ref={ref => (this.owlReference = ref)}
              />
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center justify-content-center">
              <FadeInOut delay={200}>
                <Button
                  color="primary"
                  className="m-3"
                  onClick={this.goToNextSlide.bind(this)}
                  size="lg"
                >
                  Weiter
                </Button>
              </FadeInOut>
            </div>
          </div>
        </div>
      ),
    },
    {
      content: (
        <div className="d-flex flex-column h-100">
          <div>
            <div className="col-12 mx-auto p-0">
              <p className="onboarding-carousel-title text-brainimals-dark">
                Dein eigenes Lerntempo
              </p>
            </div>
            <div className="col-10 mx-auto p-0">
              <p className="onboarding-carousel-description text-brainimals-dark">
                Wir wiederholen mit dir den Stoff.
                <br />
                Manchmal fordern wir dich auch beim Knobeln heraus.
                <br />
                Immer so, wie es am Besten für dich passt.
              </p>
            </div>
          </div>
          <div className="flex-grow-1 position-relative d-flex align-content-center m-1">
            <div className="onboarding-character col-6 m-auto">
              <Asset path="rabbit.hole.smile" type="svg" />
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center justify-content-center">
              <FadeInOut delay={200}>
                <Button
                  color="primary"
                  className="m-3"
                  onClick={this.goToNextSlide.bind(this)}
                  size="lg"
                >
                  Weiter
                </Button>
              </FadeInOut>
            </div>
          </div>
        </div>
      ),
    },
    {
      content: (
        <div className="d-flex flex-column h-100">
          <div>
            <div className="col-12 mx-auto p-0">
              <p className="onboarding-carousel-title text-brainimals-dark">
                Überall und jederzeit
              </p>
            </div>
            <div className="col-10 mx-auto p-0">
              <p className="onboarding-carousel-description text-brainimals-dark">
                Du kannst mit uns auf dem Computer, Tablet oder Smartphone üben.
              </p>
            </div>
          </div>
          <div className="flex-grow-1 position-relative d-flex align-content-center m-1">
            <div className="onboarding-character col-10 col-sm-8 col-lg-11 m-auto">
              <Asset path="devices" type="svg" />
              <h4 className="onboarding-carousel-text">
                Lege ein Lernkonto an, wenn du auf verschiedenen Geräten üben
                willst.
              </h4>
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center justify-content-center">
              <FadeInOut delay={200}>
                <Button
                  color="primary"
                  className="m-3"
                  onClick={this.goToNextSlide.bind(this)}
                  size="lg"
                >
                  Weiter
                </Button>
              </FadeInOut>
            </div>
          </div>
        </div>
      ),
    },
    {
      content: (
        <div className="d-flex flex-column h-100">
          <div>
            <div className="col-12 mx-auto p-0">
              <p className="onboarding-carousel-title text-brainimals-dark">
                Fleiß wird belohnt!
              </p>
            </div>
            <div className="col-10 mx-auto p-0">
              <p className="onboarding-carousel-description text-brainimals-dark">
                Löse Aufgabenkarten der Reihe nach und sammle Sterne. So kommst du ans Ziel.
              </p>
            </div>
          </div>
          <div className="flex-grow-1 position-relative d-flex flex-column align-content-center justify-content-center">
            <Asset path="onboarding_4" type="svg" />
          </div>
          <div>
            <div className="d-flex align-items-center justify-content-center">
              <FadeInOut delay={200}>
                <Button
                  color="primary"
                  className="m-3"
                  onClick={this.goToNextSlide.bind(this)}
                  size="lg"
                >
                  Weiter
                </Button>
              </FadeInOut>
            </div>
          </div>
        </div>
      ),
    },
    {
      content: (
        <div className="d-flex flex-column h-100">
          <div>
            <div className="col-12 mx-auto p-0">
              <p className="onboarding-carousel-title text-brainimals-dark">
                Erklärungen und Tipps
              </p>
            </div>
            <div className="col-10 mx-auto p-0">
              <p className="onboarding-carousel-description text-brainimals-dark">
                Fehler sind nicht schlimm.
                <br />
                Wir helfen dir immer, wenn du nicht weiter weißt.
              </p>
            </div>
          </div>
          <div className="flex-grow-1 position-relative d-flex align-content-center m-1">
            <div className="onboarding-character col-6 mx-auto">
              <Asset path="owl.helping" type="svg" />
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center justify-content-center">
              <FadeInOut delay={200}>
                <Button
                  color="primary"
                  className="m-3"
                  onClick={this.goToNextSlide.bind(this)}
                  size="lg"
                >
                  Weiter
                </Button>
              </FadeInOut>
            </div>
          </div>
        </div>
      ),
    },
    {
      content: (
        <div className="d-flex flex-column h-100">
          <div>
            <div className="col-12 mx-auto p-0">
              <p className="onboarding-carousel-title text-brainimals-dark">
                Womit möchtest du starten?
              </p>
            </div>
          </div>
          <div className="d-flex flex-grow-1 align-items-center justify-content-center">
            <div className="d-flex align-items-center justify-content-center flex-column">
              <FadeInOut delay={200}>
                <Button
                  color="secondary"
                  className="m-3"
                  onClick={() => this.goToLanding(0)}
                  size="lg"
                  block
                >
                  Wissen der 2. Klasse testen
                </Button>
              </FadeInOut>
              <FadeInOut delay={200}>
                <Button
                  color="secondary"
                  className="m-3 flex-grow"
                  onClick={() => this.goToLanding(1)}
                  size="lg"
                  block
                >
                  Zahlenraum bis 1000 lernen
                </Button>
              </FadeInOut>
              <FadeInOut delay={200}>
                <Button
                  color="secondary"
                  className="m-3"
                  onClick={() => this.goToLanding(2)}
                  size="lg"
                  block
                >
                  Im Zahlenraum bis 1000 rechnen
                </Button>
              </FadeInOut>
            </div>
          </div>
        </div>
      ),
    },
  ]

  render() {
    const { student } = this.props
    const { activeIndex } = this.state
    const { itemList } = this

    const slideList = itemList.map((item, index) => {
      return (
        <CarouselItem
          key={index}
          onExiting={this.onExiting}
          onExited={this.onExited}
        >
          {item.content}
        </CarouselItem>
      )
    })

    consoleDebug("Student content:")(student, getRaw(student))

    if (not(isNil(getRaw(student)))) {
      consoleDebug("Not empty, clearing reload")(
        "Student content:",
        getRaw(student),
      )
      clearTimeout(this.loadTimeout)
    } else {
      consoleDebug("Empty, timeout set")("Student content:", getRaw(student))
    }

    return getRaw(student) ? (
      <FadeInOut delay={150}>
        <Swipeable
          onSwipingLeft={this.goToNextSlide.bind(this)}
          onSwipingRight={this.goToPreviousSlide.bind(this)}
          className="h-100"
          preventDefaultTouchmoveEvent
          trackMouse
        >
          <div className="d-flex flex-column h-100 env-padding-top">
            <div className="onboarding-background">
              <Asset path="mountains" type="svg" />
            </div>
            <div className="flex-grow-1 d-flex align-content-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-6 mx-auto p-0 d-flex flex-column align-content-center justify-content-center">
                <div className="onboarding-carousel">
                  <Carousel
                    className="onboarding"
                    activeIndex={activeIndex}
                    next={() => {}}
                    previous={() => {}}
                    wrap={false}
                  >
                    {slideList}
                  </Carousel>
                </div>
              </div>
            </div>
            <div className="onboarding-indicators p-2 m-1 env-padding-bottom">
              <CarouselIndicators
                activeIndex={activeIndex}
                items={slideList}
                onClickHandler={this.goToIndex}
              />
            </div>
          </div>
        </Swipeable>
      </FadeInOut>
    ) : (
      <Loader />
    )
  }
}

const StudentSignedInOnboarding = ({ userContext }) => (
  <AuthenticationContext.Consumer>
    {student => (
      <FadeInOut leave={{ opacity: 0 }}>
        <StateMachineContext.Consumer>
          {props => (
            <StudentSignedInOnboardingComponent userContext={userContext} {...props} student={student} />
          )}
        </StateMachineContext.Consumer>
      </FadeInOut>
    )}
  </AuthenticationContext.Consumer>
)

export default withUser(StudentSignedInOnboarding)
