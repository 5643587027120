import "./StudentQuestionDialog.scss"

import { StateMachineContext } from "contexts"

import React, { Component } from "react"

import addIndex from "ramda/src/addIndex"
import map from "ramda/src/map"

import FadeInOut from "components/Animations/FadeInOut"
import Bubble, { getBubbleDelay } from "components/Chat/Bubble/Bubble"
import Markdown from "react-markdown"

class StudentQuestionDialog extends Component {
  dialogRef = React.createRef()

  constructor(props) {
    super(props)
    this.scrollToBottom = this.scrollToBottom.bind(this)
  }

  componentDidMount() {
    this.scrollToBottom()
    setTimeout(this.scrollToBottom, 120)
  }

  componentDidUpdate() {
    this.scrollToBottom()
    setTimeout(this.scrollToBottom, 120)
  }

  scrollToBottom() {
    const dialogRef = this.dialogRef.current
    if (dialogRef && dialogRef.scrollTo) {
      dialogRef.scrollTo(0, dialogRef.scrollHeight)
    }
  }

  render() {
    const { dialogList } = this.props

    return (
      <FadeInOut
        duration={1500}
        from={{
          opacity: 0,
          transform: "translateY(4rem)",
        }}
        to={{
          opacity: 1,
          transform: "translateY(0rem)",
        }}
      >
        <div
          ref={this.dialogRef}
          className="p-2 m-1 d-flex flex-column content-bottom flex-grow-1 overflow-y-scroll"
        >
          <span>
            {addIndex(map)(
              (dialog, index) => (
                <React.Fragment key={index}>
                  <Bubble delay={getBubbleDelay(500, index)}>
                    <Markdown escapeHtml={false}>{dialog}</Markdown>
                  </Bubble>
                  <div />
                </React.Fragment>
              ),
              dialogList,
            )}
          </span>
        </div>
      </FadeInOut>
    )
  }
}

export default componentProps => (
  <StateMachineContext.Consumer>
    {props => <StudentQuestionDialog {...componentProps} {...props} />}
  </StateMachineContext.Consumer>
)
