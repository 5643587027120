import React from "react"
import { Spring } from "react-spring"
import { TimingAnimation } from "react-spring/dist/addons"

const FadeInOut = ({
  children,
  delay = 0,
  duration = 180,
  from = { opacity: 0 },
  to = { opacity: 1 },
  ...props
}) => {
  const config = { delay, duration, ...props }

  return (
    <Spring impl={TimingAnimation} config={config} from={from} to={to}>
      {style => {
        return {
          ...children,
          props: {
            ...children.props,
            style: { ...children.props.style, ...style },
          },
        }
      }}
    </Spring>
  )
}

export default FadeInOut
