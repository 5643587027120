import React, { Component } from "react"
import { object, func, number } from "prop-types"
import { AuthenticationContext, StateMachineContext } from "contexts"

import { Container, Col } from "reactstrap"

import FadeInOut from "components/Animations/FadeInOut"
import { Ground, Loader, Asset } from "components/Assets"
import Animation from "components/Assets/Animation/Animation"

import badgeAppear from "assets/animations/badge.appear.json"
import powerAppear from "assets/animations/power.orange.appear.json"

import { analytics } from "helpers/tracking"

import compose from "ramda/src/compose"
import cond from "ramda/src/cond"
import equals from "ramda/src/equals"
import filter from "ramda/src/filter"
import length from "ramda/src/length"
import lt from "ramda/src/lt"
import prop from "ramda/src/prop"
import T from "ramda/src/T"
import uniqBy from "ramda/src/uniqBy"

import { getExerciseId } from "helpers/data/exercise"
import { getProgramId, getProgramIsAssessment } from "helpers/data/program"
import { getProgramExercises } from "helpers/data/program"
import { getHistoryExercisesByProgramId } from "helpers/data/student"

import { addHistoryProgram } from "helpers/data/studentHistoryPrograms"
import { calculateDifficultyLevel } from "helpers/firebase/firestore"
import "./StudentExerciseFinished.scss"

const getLength = prop("length")

class StudentExerciseFinished extends Component {
  constructor(props) {
    super(props)

    this.addCompletedProgramToHistory = this.addCompletedProgramToHistory.bind(
      this,
    )
    this.continue = this.continue.bind(this)
  }

  state = {
    exercises: getProgramExercises(this.props.program),
    finishedExercises: [],
  }

  static defaultProps = {
    questionExecutionCount: 1,
  }

  static propTypes = {
    exercise: object,
    questionExecutionCount: number,
    student: object,
    transition: func,
    program: object,
  }

  // Tracking for StudentExerciseFinished is located in StudentExerciseHasFollowingQuestion

  componentDidMount = async () => {
    const { program } = this.props
    const exercises = getProgramExercises(program)
    const finishedExercises = await getHistoryExercisesByProgramId(
      getProgramId(program),
    )

    this.setState({ exercises, finishedExercises }, this.updateExercises)
  }
  //
  // updateExercises = () => {
  //   const { exercises, finishedExercises } = this.state
  //   const finishedUniqueExercises = uniqBy(prop("id"), finishedExercises)
  //
  //   return cond([[equals, this.addCompletedProgramToHistory]])(
  //     getLength(exercises),
  //     getLength(finishedUniqueExercises),
  //   )
  // }

  updateExercises = () => {
    const { exercises, finishedExercises } = this.state
    const finishedUniqueExercises = uniqBy(prop("id"), finishedExercises)

    cond([
      [
        equals,
        () =>
          cond([[lt, () => {}], [T, this.addCompletedProgramToHistory]])(
            getLength(exercises),
            getLength(finishedExercises),
          ),
      ],
    ])(getLength(exercises), getLength(finishedUniqueExercises))
  }

  addCompletedProgramToHistory = async () => {
    let difficultyLevel
    const { program } = this.props

    if (getProgramIsAssessment(program)) {
      const dl = await calculateDifficultyLevel(getProgramId(program))
      difficultyLevel = dl.difficultyLevel
    }

    await addHistoryProgram({
      program,
      difficultyLevel,
    })

    analytics.page(null, "Program Done", {
      path: `/StudentProgramDone/${program.learning_program_id}`,
    })
  }

  continue = () => {
    const { transition } = this.props
    const { exercises, finishedExercises } = this.state
    const finishedUniqueExercises = uniqBy(prop("id"), finishedExercises)

    cond([
      [
        equals,
        () =>
          cond([
            [lt, () => transition("PROGRAM_CONTINUE")],
            [T, () => transition("PROGRAM_DONE")],
          ])(getLength(exercises), getLength(finishedExercises)),
      ],
      [T, () => transition("PROGRAM_CONTINUE")],
    ])(getLength(exercises), getLength(finishedUniqueExercises))
  }

  repeat = () => {
    const { exercise, program, transition } = this.props
    transition("EXERCISE_RESTART", { exercise, program })
  }

  hasMatchingExerciseId = compose(
    equals(getExerciseId(this.props.exercise)),
    prop("id"),
  )

  render() {
    const { exercises, finishedExercises } = this.state

    const exerciseExecutionCount = compose(
      length,
      filter(this.hasMatchingExerciseId),
    )(finishedExercises)

    return (
      <Container
        fluid
        className="d-flex flex-column align-items-center justify-content-center main-container p-0"
      >
        <Col xs={10} className="d-flex flex-column justify-content-center">
          {length(finishedExercises) ? (
            <FadeInOut>
              <div className="d-flex flex-column align-items-center justify-content-center h-100">
                {exerciseExecutionCount < 2 ? (
                  <>
                    <h1 className="exercise-finished__headline text-brainimals-dark text-center">
                      Super gemacht!
                      <br />
                      <span className={"text-white font-weight-600"}>
                        +1 Stern
                      </span>
                    </h1>
                    <div className="exercise-finished__character-badge">
                      <Animation
                        key={"appear"}
                        data={badgeAppear}
                        speed={0.5}
                        autoplay
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <h1 className="exercise-finished__headline text-brainimals-dark text-center">
                      Weiter so!
                      <br />
                      <span className={"text-white font-weight-600"}>
                        Fleiß macht dich stärker.
                      </span>
                    </h1>
                    <div className="exercise-finished__character-power py-4">
                      <Animation
                        key={"appear"}
                        data={powerAppear}
                        speed={1.5}
                        autoplay
                      />
                    </div>
                  </>
                )}
                <div>
                  <div
                    className={"btn btn-block btn-lg btn-primary"}
                    onClick={this.continue}
                  >
                    Weiter
                  </div>
                  <div
                    className={"btn btn-block btn-lg btn-link text-white"}
                    onClick={this.repeat}
                  >
                    <span className={"d-inline-block mr-2 cursor-pointer"}>
                      <Asset
                        type="svg"
                        path="icon.repeat"
                        style={{ width: "1.5rem", height: "1.5rem" }}
                      />
                    </span>
                    Wiederholen
                  </div>
                </div>
              </div>
            </FadeInOut>
          ) : (
            <Loader />
          )}
        </Col>
        <FadeInOut delay={2000}>
          <Ground
            completed={getLength(finishedExercises)}
            total={getLength(exercises)}
          />
        </FadeInOut>
      </Container>
    )
  }
}

const StudentExerciseFinishedContainer = () => (
  <AuthenticationContext.Consumer>
    {student => (
      <StateMachineContext.Consumer>
        {props => <StudentExerciseFinished {...props} student={student} />}
      </StateMachineContext.Consumer>
    )}
  </AuthenticationContext.Consumer>
)

export default StudentExerciseFinishedContainer
