import { State } from "react-automata"
import { StateMachineContext } from "contexts"

import React, { Component } from "react"

import StudentAnonymousProfileCheck from "./StudentAnonymousProfileCheck/StudentAnonymousProfileCheck"
import StudentAnonymousProfileUpdate from "./StudentAnonymousProfileUpdate/StudentAnonymousProfileUpdate"

class StudentAnonymousProfile extends Component {
  render = () => (
    <>
      <State is={["*StudentAnonymousProfileCheck*"]}>
        <StudentAnonymousProfileCheck />
      </State>
      <State is={["*StudentAnonymousProfileUpdate*"]}>
        <StudentAnonymousProfileUpdate />
      </State>
    </>
  )
}

export default ownProps => (
  <StateMachineContext.Consumer>
    {stateMachineProps => (
      <StudentAnonymousProfile {...stateMachineProps} {...ownProps} />
    )}
  </StateMachineContext.Consumer>
)
