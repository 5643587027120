import React from "react"
import SVGComponent from "react-svg"

const SVG = ({ path, className, wrapperClassName, style, ...props }) => (
  <SVGComponent
    alt={""}
    src={`assets/svg/${path}.svg`}
    svgClassName={className}
    svgStyle={style}
    className={wrapperClassName}
    {...props}
  />
)

export default SVG
