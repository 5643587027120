import { getDocumentById } from "helpers/firebase/firestore"

import __ from "ramda/src/__"
import compose from "ramda/src/compose"
import curry from "ramda/src/curry"
import objOf from "ramda/src/objOf"
import converge from "ramda/src/converge"
import merge from "ramda/src/merge"
import path from "ramda/src/path"
import prop from "ramda/src/prop"
import slice from "ramda/src/slice"

export const getExerciseId = prop("learning_exercise_id")
export const getExerciseTitle = prop("learning_exercise_title")
export const getExerciseSubtitle = prop("learning_exercise_subtitle")
export const getExerciseCover = curry((theme, exerciseRecord) =>
  path(
    ["learning_exercise_theme", theme, "learning_exercise_cover"],
    exerciseRecord,
  ),
)

// TODO Change to learning_exercise_composition_amount_dl_
export const getExerciseCompositionAmountAtDifficultyLevel = questionDifficultyLevel =>
  prop(`learning_exercise_composition_dl_${questionDifficultyLevel}`)

export const getExerciseCompositionAtDifficultyLevel = curry(
  (studentDifficultyLevel, exerciseRecord) =>
    compose(
      prop(`learning_exercise_composition_dl_${studentDifficultyLevel}`),
      prop("learning_exercise_composition"),
    )(exerciseRecord),
)

export const getExerciseAdjustmentAtDifficultyLevel = curry(
  (studentDifficultyLevel, exerciseRecord) =>
    compose(
      prop(`learning_exercise_adjustment_dl_${studentDifficultyLevel}`),
      prop("learning_exercise_adjustment"),
    )(exerciseRecord),
)

export const getExerciseQuestionsForLevel = curry(
  (studentDifficultyLevel, questionDifficultyLevel, exerciseRecord) =>
    compose(
      slice(
        0,
        __,
        getExerciseQuestionListAtDifficultyLevel(
          exerciseRecord,
          questionDifficultyLevel,
        ),
      ),
      getExerciseCompositionAmountAtDifficultyLevel(questionDifficultyLevel),
      getExerciseCompositionAtDifficultyLevel(studentDifficultyLevel),
    )(exerciseRecord),
)

export const getExerciseQuestionList = prop("learning_exercise_questions")

/**
 * @argument {Number} level - The requested Difficulty Level to be retrieved from the list
 * @argument {Object} exerciseRecord - The Record of the Exercise which contains the questions
 * @returns {Array} - A list of the available Questions at the requested level
 */
export const getExerciseQuestionListAtDifficultyLevel = curry(
  (exerciseRecord, questionDifficultyLevel) =>
    compose(
      prop(`learning_exercise_question_dl_${questionDifficultyLevel}`),
      getExerciseQuestionList,
    )(exerciseRecord),
)

const getExerciseDocumentData = doc => doc.data()
const getExerciseDocumentId = compose(
  objOf("learning_exercise_id"),
  prop("id"),
)

/**
 *
 * @param {String} exerciseId - The ID of the exercise to be retrieved from the Database
 * @returns {Object} - The ExerciseRecord at the DB (if available)
 */
export const getExerciseById = exerciseId =>
  getDocumentById("learning_exercises", exerciseId).map(
    converge(merge, [getExerciseDocumentData, getExerciseDocumentId]),
  )
