export default {
  id: "StudentQuestion",
  states: {
    StudentQuestionSetup: {
      id: "StudentQuestionSetup",
      states: {},
      on: {
        TYPE_MULTIPLE: "#StudentQuestionTypeMultiple",
        TYPE_NUMPAD: "#StudentQuestionTypeNumpad",
        TYPE_THZE: "#StudentQuestionTypeThze",
      },
    },
    StudentQuestionActive: {
      id: "StudentQuestionActive",
      states: {
        StudentQuestionType: {
          id: "StudentQuestionType",
          states: {
            StudentQuestionTypeMultiple: {
              id: "StudentQuestionTypeMultiple",
              states: {},
            },
            StudentQuestionTypeNumpad: {
              id: "StudentQuestionTypeNumpad",
              states: {},
            },
            StudentQuestionTypeThze: {
              id: "StudentQuestionTypeThze",
              states: {},
            },
          },
          initial: "StudentQuestionTypeMultiple",
          on: {},
        },
        StudentQuestionHelp: {
          id: "StudentQuestionHelp",
          states: {
            StudentQuestionHelpActive: {
              id: "StudentQuestionHelpActive",
              states: {},
              on: { HELP_CLOSE: "#StudentQuestionHelpInactive" },
            },
            StudentQuestionHelpInactive: {
              id: "StudentQuestionHelpInactive",
              states: {},
              on: { HELP_OPEN: "#StudentQuestionHelpActive" },
            },
          },
          initial: "StudentQuestionHelpInactive",
          on: {},
        },
        StudentQuestionAnswer: {
          id: "StudentQuestionAnswer",
          states: {
            StudentQuestionAnswerEmpty: {
              id: "StudentQuestionAnswerEmpty",
              states: {},
              on: {
                ANSWER_ADD: "#StudentQuestionAnswerNotEmpty",
                ANSWER_ADD_AND_DISABLE: "#StudentQuestionAnswerComplete",
              },
            },
            StudentQuestionAnswerNotEmpty: {
              id: "StudentQuestionAnswerNotEmpty",
              states: {},
              on: {
                ANSWER_ADD: "#StudentQuestionAnswerNotEmpty",
                ANSWER_ADD_AND_DISABLE: "#StudentQuestionAnswerComplete",
                ANSWER_ERASE: "#StudentQuestionAnswerNotEmpty",
                ANSWER_ERASE_AND_DISABLE: "#StudentQuestionAnswerEmpty",
              },
            },
            StudentQuestionAnswerComplete: {
              id: "StudentQuestionAnswerComplete",
              states: {},
              on: {
                ANSWER_ERASE: "#StudentQuestionAnswerNotEmpty",
                ANSWER_ERASE_AND_DISABLE: "#StudentQuestionAnswerEmpty",
                ANSWER_SUBMIT: "#StudentQuestionAnswerEvaluation",
              },
            },
            StudentQuestionAnswerEvaluation: {
              id: "StudentQuestionAnswerEvaluation",
              states: {},
              on: { QUESTION_RETRY: "#StudentQuestionActive" },
            },
          },
          initial: "StudentQuestionAnswerEmpty",
          on: {},
        },
        StudentQuestionSuccess: {
          id: "StudentQuestionSuccess",
          states: {
            StudentQuestionSuccessEmpty: {
              id: "StudentQuestionSuccessEmpty",
              states: {},
              on: { DISPLAY_SUCCESS: "#StudentQuestionSuccessActive" },
            },
            StudentQuestionSuccessActive: {
              id: "StudentQuestionSuccessActive",
              states: {},
              on: { CONTINUE: "#StudentExerciseCalculateRewards" },
            },
          },
          initial: "StudentQuestionSuccessEmpty",
          on: {},
        },
        StudentQuestionError: {
          id: "StudentQuestionError",
          states: {
            StudentQuestionErrorEmpty: {
              id: "StudentQuestionErrorEmpty",
              states: {},
              on: { DISPLAY_ERROR: "#StudentQuestionErrorActive" },
            },
            StudentQuestionErrorActive: {
              id: "StudentQuestionErrorActive",
              states: {},
              on: {
                ERROR_1: "#StudentQuestionErrorActive1st",
                ERROR_2: "#StudentQuestionErrorActive2nd",
                ERROR_3: "#StudentQuestionErrorActive3rd",
              },
            },
            StudentQuestionErrorActive1st: {
              id: "StudentQuestionErrorActive1st",
              states: {},
              on: { QUESTION_RETRY: "#StudentQuestionActive" },
            },
            StudentQuestionErrorActive2nd: {
              id: "StudentQuestionErrorActive2nd",
              states: {},
              on: { QUESTION_RETRY: "#StudentQuestionActive" },
            },
            StudentQuestionErrorActive3rd: {
              id: "StudentQuestionErrorActive3rd",
              states: {},
              on: { CONTINUE: "#StudentExerciseCalculateRewards" },
            },
          },
          initial: "StudentQuestionErrorEmpty",
          on: {},
        },
      },
      parallel: true,
      on: {},
    },
  },
  initial: "StudentQuestionSetup",
  on: {
    QUESTION_EXIT: "#StudentProgramStart",
    SESSION_CLOSED: "#StudentSignedOut",
  },
}
