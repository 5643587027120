import React from "react"
import { node, shape, number } from "prop-types"

import { withUser } from "contexts/UserContext"
import { Asset, ProgressIcon } from "components/Assets"

import "./ProgressHeader.scss"

const ProgressHeader = props => {
  const {
    children,
    userContext: {
      exercisesDoneInProgramsDone,
      exercisesDoneInProgramActive,
      exercisesTotal,
    },
  } = props
  const current = exercisesDoneInProgramsDone + exercisesDoneInProgramActive
  return (
    <div className="progress-header">
      {children}
      <ProgressIcon current={current} total={exercisesTotal}>
        <Asset path="star-red" className="progress-header__icon" type="svg" />
      </ProgressIcon>
    </div>
  )
}

ProgressHeader.propTypes = {
  children: node.isRequired,
  userContext: shape({
    exercisesDoneInProgramsDone: number.isRequired,
    exercisesDoneInProgramActive: number.isRequired,
    exercisesTotal: number.isRequired,
  }),
}

export default withUser(ProgressHeader)
