import prop from "ramda/src/prop"
import propOr from "ramda/src/propOr"
import compose from "ramda/src/compose"
import last from "ramda/src/last"

import { getStudentPrograms } from "helpers/data/student"
import { auth } from "helpers/firebase"

class Student {
  constructor(data, student_uid) {
    this._raw = { ...data, student_uid }
  }

  getStudentFirstName = () => prop("student_name_first", this._raw)

  getStudentLastName = () => prop("student_name_last", this._raw)

  getStudentFullName = () =>
    `${this.getStudentFirstName()} ${this.getStudentLastName()}`

  getStudentFormalName = () =>
    `${this.getStudentLastName()}, ${this.getStudentFirstName()}`

  getStudentEmail = () => prop("student_email", this._raw)

  getStudentSchoolYear = () => prop("student_school_year", this._raw)

  getStudentPrograms = () => getStudentPrograms(this._raw)

  getStudentDifficultyLevel = () =>
    compose(
      prop("dl"),
      last,
      // TODO: Remove default fallback once all user have initial dl
      propOr([{ dl: 1 }], "student_dl"),
    )(this._raw)

  getStudentUid = () => prop("student_uid", this._raw)

  hasRepliedToAppReviewRequest = () => prop("student_app_review_replied", this._raw)

  getStudentEnrolledCourses = () => prop("student_enrolled_courses", this._raw)

  isAnonymous = () => prop("student_is_anonymous", this._raw)

  signOut = () => auth.signOut()
}

export default (data, uid) => new Student(data, uid)
