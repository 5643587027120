import "./Trees.scss"

import React from "react"
import { Asset } from "components/Assets"
import FadeInOut from "components/Animations/FadeInOut"

const Trees = () => (
  <>
    <FadeInOut
      delay={500}
      duration={500}
      from={{
        opacity: 0,
        transform: "translateX(-1rem)",
      }}
      to={{
        opacity: 1,
        transform: "translateX(0)",
      }}
    >
      <Asset
        path="tree.teal.inactive.large"
        className="background-tree background-tree__left"
        type="svg"
      />
    </FadeInOut>
    <FadeInOut
      delay={750}
      duration={500}
      from={{
        opacity: 0,
        transform: "translateX(1rem)",
      }}
      to={{
        opacity: 1,
        transform: "translateX(0)",
      }}
    >
      <Asset
        path="tree.teal.inactive.large"
        className="background-tree background-tree__right"
        type="svg"
      />
    </FadeInOut>
  </>
)

export default Trees
