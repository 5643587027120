import { firestore, functions } from "./index"
import Async from "crocks/Async"
import curry from "ramda/src/curry"
import invoker from "ramda/src/invoker"

export const simpleGet = curry((collection, conditions) =>
  Async((failure, success) => {
    firestore
      .collection(collection)
      .where(...conditions)
      .get()
      .then(success)
      .catch(failure)
  }),
)

export const simpleSet = curry((collection, conditions, data) =>
  Async((failure, success) => {
    firestore
      .collection(collection)
      .where(...conditions)
      .set(data, { merge: true })
      .then(success)
      .catch(failure)
  }),
)

export const getCollection = collection =>
  Async((failure, success) => {
    firestore
      .collection(collection)
      .get()
      .then(success)
      .catch(failure)
  })

export const getDocument = path =>
  Async((failure, success) =>
    firestore
      .doc(path)
      .get()
      .then(success)
      .catch(failure),
  )

export const getDocumentInstance = ({ docs }) => docs
export const getDocumentReference = ({ ref }) => ref
export const getDocumentData = invoker(0, "data")

export const getDocumentById = curry((collection, documentId) =>
  Async((failure, success) =>
    firestore
      .collection(collection)
      .doc(documentId)
      .get()
      .then(success)
      .catch(failure),
  ),
)

export const setDocumentId = curry((collection, documentId, data) =>
  Async((failure, success) =>
    firestore
      .collection(collection)
      .doc(documentId)
      .set(data, { merge: true })
      .then(success)
      .catch(failure),
  ),
)

export const calculateDifficultyLevel = async programId => {
  const request = functions.httpsCallable("endpoints-calculateDifficultyLevel")
  const { data } = await request({ programId })
  return data
}

export const getStatistics = async programId => {
  const request = functions.httpsCallable("endpoints-statistics")
  const { data } = await request({ programId })
  return data
}

export const setupStudent = () =>
  Async((failure, success) =>
    functions
      .httpsCallable("endpoints-setupStudent")()
      .then(success)
      .catch(failure),
  )

export const getVersion = () =>
  Async((failure, success) =>
    functions
      .httpsCallable("endpoints-version")()
      .then(success)
      .catch(failure),
  )
