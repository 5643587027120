/* eslint-disable no-console */
import React, { Component } from "react"
import { StateMachineContext, AuthenticationContext } from "contexts"
import { addHistoryQuestion } from "helpers/data/student"
import { Loader } from "components/Assets"

class StudentExerciseCalculateRewards extends Component {
  constructor(props) {
    super(props)
    this.addToHistory()
  }

  async addToHistory() {
    const {
      activeQuestion: question,
      questionExecutionCount,
      activeAttempts,
      questionList,
      transition,
      exercise,
      program,
      helpCount,
      timestampStart,
    } = this.props

    const saveData = {
      question,
      questionExecutionCount,
      exercise,
      program,
      activeAttempts,
      helpCount,
      timestampStart,
    }
    try {
      await addHistoryQuestion(saveData)
      window.requestAnimationFrame(() =>
        transition("CONTINUE", {
          questionExecutionCount,
          questionList,
          program,
          exercise,
        }),
      )
    } catch (er) {
      console.log("ERROR SAVING HISTORY", er)
    }
  }

  render() {
    return <Loader />
  }
}

const Container = () => (
  <AuthenticationContext.Consumer>
    {student => (
      <StateMachineContext.Consumer>
        {props => (
          <StudentExerciseCalculateRewards {...props} student={student} />
        )}
      </StateMachineContext.Consumer>
    )}
  </AuthenticationContext.Consumer>
)

export default Container
