import React from "react"
import { StateMachineContext } from "contexts"
import { State } from "react-automata"

import { Droppable } from "react-beautiful-dnd"

import classnames from "classnames"

import add from "ramda/src/add"
import addIndex from "ramda/src/addIndex"
import append from "ramda/src/append"
import cond from "ramda/src/cond"
import filter from "ramda/src/filter"
import is from "ramda/src/is"
import isNil from "ramda/src/isNil"
import last from "ramda/src/last"
import length from "ramda/src/length"
import map from "ramda/src/map"
import reduce from "ramda/src/reduce"
import T from "ramda/src/T"

import Bubble from "components/Chat/Bubble/Bubble"
import StudentQuestionAnswerMultiple from "./StudentQuestionAnswerMultiple"
import StudentQuestionAnswerNumpad from "./StudentQuestionAnswerNumpad"
import StudentQuestionAnswerThze from "./StudentQuestionAnswerThze"

const StudentQuestionAnswer = ({
  questionPlaceholderList,
  studentAnswer,
  questionAnswer,
  notice,
}) => {
  // NOTE: This function is responsible for keeping
  //       track of the position of the row placeholders
  //       in relation to the overall questionAnswer indexes.

  const placeholderBucketList = addIndex(reduce)(
    (x, xs) => {
      const lastIndex = cond([
        [is(Array), () => add(1, last(last(x)))],
        [T, () => 0],
      ])(last(x))

      const placeholderCount = length(filter(isNil, xs))
      const indexBucket = Array(placeholderCount)
        .fill(0)
        .map((_, i) => add(lastIndex, i))

      return append(indexBucket, x)
    },
    [],
    questionPlaceholderList,
  )

  return (
    <Droppable droppableId={"studentAnswerBubble"}>
      {(provided, snapshot) => (
        <Bubble
          isDraggingOver={snapshot.isDraggingOver}
          provided={provided}
          {...provided.droppableProps}
          type={notice ? "info" : "interactive"}
          title={notice ? "Richtige Lösung" : ""}
        >
          {addIndex(map)((row, rowIndex) => {
            const contentClass = classnames({
              "mt-3": rowIndex !== 0,
              "bubble__info-content": notice,
            })

            return (
              <div key={rowIndex} className={contentClass}>
                <State is="*StudentQuestionTypeMultiple">
                  <StudentQuestionAnswerMultiple
                    bucket={placeholderBucketList[rowIndex]}
                    row={row}
                    studentAnswer={studentAnswer}
                    questionAnswer={questionAnswer}
                  />
                </State>
                <State is="*StudentQuestionTypeNumpad">
                  <StudentQuestionAnswerNumpad
                    bucket={placeholderBucketList[rowIndex]}
                    row={row}
                    studentAnswer={studentAnswer}
                    questionAnswer={questionAnswer}
                  />
                </State>
                <State is="*StudentQuestionTypeThze">
                  <StudentQuestionAnswerThze
                    bucket={placeholderBucketList[rowIndex]}
                    row={row}
                    studentAnswer={studentAnswer}
                    questionAnswer={questionAnswer}
                  />
                </State>
              </div>
            )
          }, questionPlaceholderList)}
        </Bubble>
      )}
    </Droppable>
  )
}

export default componentProps => (
  <StateMachineContext.Consumer>
    {props => <StudentQuestionAnswer {...componentProps} {...props} />}
  </StateMachineContext.Consumer>
)
