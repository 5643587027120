import React from "react"
import { func } from "prop-types"

import "./LogoutButton.scss"

import { Asset } from "components/Assets"
import FadeInOut from "components/Animations/FadeInOut"

const LogoutButton = ({ onClick }) => (
  <FadeInOut>
    <span className="logout-button d-flex align-items-center" {...{ onClick }}>
      <Asset type="svg" path="logout" />
    </span>
  </FadeInOut>
)

LogoutButton.propTypes = {
  onClick: func.isRequired,
}

export default LogoutButton
