import Async from "crocks/Async"

import path from "ramda/src/path"
import prop from "ramda/src/prop"
import compose from "ramda/src/compose"
import curry from "ramda/src/curry"
import init from "ramda/src/init"
import map from "ramda/src/map"
import nth from "ramda/src/nth"
import converge from "ramda/src/converge"
import last from "ramda/src/last"
import merge from "ramda/src/merge"
import objOf from "ramda/src/objOf"

import { getDocumentById } from "helpers/firebase/firestore"

/**
 *
 * @param {String} questionId - The ID of the Question to be retrieved from the database
 * @returns {Object} - The QuestionRecord from the database (if available)
 */

export const getQuestionByIdAsDocument = questionId =>
  getDocumentById("learning_questions", questionId)

/**
 * @param {String} questionId - The ID of the Question to be retrieved from the database
 * @param {Object} exerciseRecord - The ExerciseRecord from which the Question ID is to be retrieved
 * @returns {String} - The Question ID contained at the requested Index from the ExerciseRecord (if available)
 */
export const getQuestionIdAtIndex = curry((questionIndex, exerciseRecord) =>
  Async((failure, success) => {
    try {
      const questionId = compose(
        prop("id"),
        nth(questionIndex),
        path(["learning_exercise_questions"]),
      )(exerciseRecord)

      return success(questionId)
    } catch (message) {
      return failure(message)
    }
  }),
)

export const getQuestionAnswers = prop("learning_question_answer")
export const getQuestionDialogList = prop("learning_question_dialog")
export const getQuestionHelpModal = prop("learning_question_help_modal")

export const getQuestionMessageSuccess = prop(
  "learning_question_message_success",
)

export const getQuestionOptionsList = prop("learning_question_options")

export const getQuestionPlaceholdersList = compose(
  map(prop("learning_question_placeholder_list")),
  prop("learning_question_placeholders"),
)
export const getQuestionPoints = prop("learning_question_points")

export const getQuestionErrorNotice = prop("learning_question_notice_error")
export const getQuestionErrorNoticeList1st = compose(
  prop("learning_question_notice_error_1"),
  getQuestionErrorNotice,
)
export const getQuestionErrorNoticeList2nd = compose(
  init,
  prop("learning_question_notice_error_2"),
  getQuestionErrorNotice,
)
export const getQuestionErrorNoticeTip2nd = compose(
  last,
  prop("learning_question_notice_error_2"),
  getQuestionErrorNotice,
)
export const getQuestionErrorNoticeList3rd = compose(
  prop("learning_question_notice_error_3"),
  getQuestionErrorNotice,
)

export const getQuestionTitle = prop("learning_question_title")
export const getQuestionType = prop("learning_question_type")
export const getQuestionCognitiveType = prop("learning_question_cog_type")
export const getQuestionVersion = prop("learning_question_version")
export const getQuestionVersionList = prop("learning_question_versions")
export const getQuestionId = prop("learning_question_id")

const getQuestionDocumentData = compose(
  last,
  getQuestionVersionList,
  doc => doc.data(),
)
const getQuestionDocumentId = compose(
  objOf("learning_question_id"),
  prop("id"),
)

export const getQuestionById = questionId =>
  getQuestionByIdAsDocument(questionId).map(
    converge(merge, [getQuestionDocumentData, getQuestionDocumentId]),
  )

