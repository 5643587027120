import React from "react"
import "./DeprecatedVersionWarning.scss"

const DeprecatedVersionWarning = () => (
  <div
    className={`d-flex flex-column align-items-center justify-content-center h-100 w-100 bg-backdrop-brainimals-deep-sea-blue position-absolute zindex-backdrop px-3 env-padding`}
    style={{ zIndex: 3999 }}
  >
    <h1 className={"text-center text-brainimals-green-light"}>
      Deine Version von OLLIS ist veraltet.
    </h1>
    <p className="deprectated-version-warning text-white">
      Bitte besuche den App Store und aktualisiere OLLIS.
    </p>
  </div>
)

export default DeprecatedVersionWarning
