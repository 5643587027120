import "./Multiple.scss"

import React, { Component } from "react"
import { StateMachineContext } from "contexts"

import Asset from "components/Assets/Asset"
import Button from "components/Exercise/Keyboards/Button/Button"
import { getBubbleDelay } from "components/Chat/Bubble/Bubble"
import { array, func } from "prop-types"
// import { Draggable, Droppable } from "react-beautiful-dnd"

import addIndex from "ramda/src/addIndex"
import compose from "ramda/src/compose"
import cond from "ramda/src/cond"
import curry from "ramda/src/curry"
import lt from "ramda/src/lt"
import map from "ramda/src/map"
import length from "ramda/src/length"
import splitEvery from "ramda/src/splitEvery"
import T from "ramda/src/T"

const makeOptionList = curry((callback, row) =>
  addIndex(map)(
    (option, index) => (
      // <Draggable key={index} draggableId={String(option)} index={index}>
      //   {(provided, snapshot) => (
      <Button
        data-value={option}
        // isDragging={snapshot.isDragging}
        // provided={provided}
        // {...provided.draggableProps}
        // {...provided.dragHandleProps}
        delay={getBubbleDelay(60, index)}
        key={index}
        onClick={() => callback(option)}
        type="multiple"
      >
        {option}
      </Button>
      //   )}
      // </Draggable>
    ),
    row,
  ),
)

class KeyboardMultipleComponent extends Component {
  static propTypes = {
    addAnswer: func,
    eraseAnswer: func,
    optionList: array,
  }

  eraseButtonBaseProps = {
    children: <Asset path="eraser" type="svg" />,
    delay: getBubbleDelay(22),
    duration: 50,
    key: "erase",
    type: "erase-floating",
    disabled: true,
  }

  eraseButtonActiveProps = {
    ...this.eraseButtonBaseProps,
    onClick: this.props.eraseAnswer,
    disabled: false,
  }

  render() {
    const { addAnswer /*, hasMultipleAnswers, */, optionList } = this.props

    const DragDropArea = ( // provided => (
      // <span ref={provided.innerRef} {...provided.droppableProps}>
      <span>
        {compose(
          splittedList =>
            addIndex(map)((row, rowIndex) => (
              <div
                className="d-flex flex-wrap justify-content-center"
                key={rowIndex}
              >
                {makeOptionList(addAnswer, row)}
              </div>
            ))(splittedList),
          cond([
            [lt(5), () => splitEvery(length(optionList) / 2 + 1, optionList)],
            [T, () => splitEvery(100, optionList)],
          ]),
        )(length(optionList))}
      </span>
    )

    // const EraseArea = (
    //   <>
    //     <State is="*StudentQuestionAnswerEmpty">
    //       <Button {...this.eraseButtonBaseProps} />
    //     </State>
    //     <State
    //       is={[
    //         "*StudentQuestionAnswerNotEmpty",
    //         "*StudentQuestionAnswerComplete",
    //       ]}
    //     >
    //       <Button {...this.eraseButtonActiveProps} />
    //     </State>
    //   </>
    // )

    // return hasMultipleAnswers ? (
    //   <>
    //     <span className="col-10 col-md-11">
    //       <div className="d-flex flex-column justify-content-around align-items-center">
    //         <Droppable droppableId={"numpadKeyboard"} isDropDisabled>
    //           {DragDropArea}
    //         </Droppable>
    //       </div>
    //     </span>
    //     <span className="col-2 col-md-1 pl-0">{EraseArea}</span>
    //   </>
    // ) : (
    //   <div className="d-flex flex-colomn justify-content-around align-items-center">
    //     <Droppable droppableId={"numpadKeyboard"} isDropDisabled>
    //       {DragDropArea}
    //     </Droppable>
    //   </div>
    // )

    return (
      <div className="d-flex flex-colomn justify-content-around align-items-center">
        {/*<Droppable droppableId={"numpadKeyboard"} isDropDisabled>*/}
        {DragDropArea}
        {/*</Droppable>*/}
      </div>
    )
  }
}

const KeyboardMultiple = componentProps => (
  <StateMachineContext.Consumer>
    {props => <KeyboardMultipleComponent {...componentProps} {...props} />}
  </StateMachineContext.Consumer>
)

export default KeyboardMultiple
