import { StateMachineContext } from "contexts"
import { sendPasswordResetEmail } from "helpers/firebase/auth"

import React, { Component } from "react"

import { Loader } from "components/Assets"

class IsAccountRecoverySuccessfulComponent extends Component {
  constructor(props) {
    super(props)

    const { inputEmail } = props

    this.handleRecovery(inputEmail)
  }

  handleRecovery = inputEmail =>
    sendPasswordResetEmail(inputEmail).fork(
      _ => this.props.transition("RECOVERY_FAILURE", _),
      _ => this.props.transition("RECOVERY_SUCCESS", _),
    )

  render() {
    return <Loader />
  }
}

const IsAccountRecoverySuccess = () => (
  <StateMachineContext.Consumer>
    {props => <IsAccountRecoverySuccessfulComponent {...props} />}
  </StateMachineContext.Consumer>
)

export default IsAccountRecoverySuccess
