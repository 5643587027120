import { StateMachineContext, AuthenticationContext } from "contexts"
import React, { Component } from "react"

import { func, object } from "prop-types"

import { Loader } from "components/Assets"

import add from "ramda/src/add"
import always from "ramda/src/always"
import compose from "ramda/src/compose"
import concat from "ramda/src/concat"
import cond from "ramda/src/cond"
import converge from "ramda/src/converge"
import curry from "ramda/src/curry"
import equals from "ramda/src/equals"
import findIndex from "ramda/src/findIndex"
import groupBy from "ramda/src/groupBy"
import length from "ramda/src/length"
import min from "ramda/src/min"
import nth from "ramda/src/nth"
import path from "ramda/src/path"
import prop from "ramda/src/prop"
import reduce from "ramda/src/reduce"
import T from "ramda/src/T"

import { all } from "crocks/Async"

import {
  getExerciseQuestionsForLevel,
  getExerciseId,
} from "helpers/data/exercise"
import { getQuestionById, getQuestionType } from "helpers/data/question"
import { analytics } from "helpers/tracking"
import {
  getHistoryQuestionsById,
  getHistoryQuestionsByExerciseId,
} from "helpers/data/student"

export const getQuestionId = curry((currentStep, questionList) =>
  compose(
    prop("id"),
    nth(currentStep),
  )(questionList),
)

export const toList = compose(
  reduce(concat, []),
  Array.of,
)

class StudentQuestionSetupComponent extends Component {
  static propTypes = {
    exercise: object,
    program: object,
    student: object,
    transition: func,
  }

  constructor(props) {
    super(props)

    const { exercise, program, student, transition } = props
    const exerciseId = getExerciseId(exercise)

    getHistoryQuestionsByExerciseId(student.getStudentUid(), exerciseId).fork(
      console.error,
      questionHistory => {
        const studentDifficultyLevel = student.getStudentDifficultyLevel()

        const questionList = converge(toList, [
          getExerciseQuestionsForLevel(studentDifficultyLevel, 1),
          getExerciseQuestionsForLevel(studentDifficultyLevel, 2),
          getExerciseQuestionsForLevel(studentDifficultyLevel, 3),
        ])(exercise)

        // Here we evaluate the following:
        // If the number of different questions answered
        // is shorter than the total, we take this count as reference
        // Otherwise, we find in the shortest bucket of unique questions
        // in the student's history and take that index as reference
        // That way account for exercises being done for the first time (first case)
        // as well as exercises being repeated (second case)

        // Here we create the buckets
        const questionGroupsObject = groupBy(
          path(["learning_question", "id"]),
          questionHistory,
        )

        // Here we make sure we keep the order defined in the DB
        const questionGroupList = reduce(
          (list, item) => {
            const row = questionGroupsObject[item.id]
            if (row) {
              list.push(row)
            }
            return list
          },
          [],
          questionList,
        )

        // Here we find the shortest list in the history
        // (i.e. the one containing the next question to be answered)
        const shortest = reduce(
          (acc, next) => min(acc, length(next)),
          Infinity,
          questionGroupList,
        )

        // Here we evaluate if the student is doing it for the
        // first time or not.
        const currentStep = cond([
          [
            compose(
              equals(length(questionList)),
              length,
            ),
            findIndex(
              compose(
                equals(shortest),
                length,
              ),
            ),
          ],
          [T, length],
        ])(questionGroupList)
        const questionId = getQuestionId(currentStep, questionList)

        analytics.page(null, "Question", {
          path: `/StudentQuestion/${questionId}`,
        })

        all([
          getQuestionById(questionId),
          getHistoryQuestionsById(student.getStudentUid(), questionId),
        ]).fork(console.error, ([question, questionExecutionList]) => {
          const questionType = getQuestionType(question)

          const selectedTransition = cond([
            [equals("multiple"), always("TYPE_MULTIPLE")],
            [equals("numpad"), always("TYPE_NUMPAD")],
            [equals("table-thze"), always("TYPE_THZE")],
          ])(questionType)

          const questionExecutionCount = add(1, length(questionExecutionList))

          return transition(selectedTransition, {
            currentStep,
            question,
            questionExecutionCount,
            questionType,
            exercise,
            program,
            questionList,
          })
        })
      },
    )
  }

  render() {
    return <Loader />
  }
}

const StudentQuestionSetup = () => (
  <AuthenticationContext.Consumer>
    {student => (
      <StateMachineContext.Consumer>
        {props => (
          <StudentQuestionSetupComponent {...props} student={student} />
        )}
      </StateMachineContext.Consumer>
    )}
  </AuthenticationContext.Consumer>
)

export default StudentQuestionSetup
