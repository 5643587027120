import Async from "crocks/Async"

import always from "ramda/src/always"
import compose from "ramda/src/compose"
import concat from "ramda/src/concat"
import cond from "ramda/src/cond"
import converge from "ramda/src/converge"
import curry from "ramda/src/curry"
import equals from "ramda/src/equals"
import head from "ramda/src/head"
import isNil from "ramda/src/isNil"
import map from "ramda/src/map"
import merge from "ramda/src/merge"
import path from "ramda/src/path"
import prop from "ramda/src/prop"
import T from "ramda/src/T"
import __ from "ramda/src/__"

import {
  simpleGet,
  getDocumentData,
  getDocumentById,
  setDocumentId,
  getCollection,
  getDocument,
  getDocumentReference,
} from "helpers/firebase/firestore"
import {
  getProgramDocumentData,
  getProgramDocumentId,
  getProgramId,
} from "helpers/data/program"
import { getExerciseId } from "helpers/data/exercise"

import {
  getQuestionId,
  getQuestionVersion,
  getQuestionPoints,
  getQuestionType,
  getQuestionCognitiveType,
} from "helpers/data/question"

import { auth, firestore } from "helpers/firebase"

export const getStudentByEmail = studentEmail =>
  simpleGet("school_students", ["student_email", "==", studentEmail])
    .map(({ docs }) => head(docs))
    .map(getDocumentData)

export const getStudentId = studentRecord =>
  simpleGet("school_students", [
    "student_email",
    "==",
    prop("student_email", studentRecord),
  ])
    .map(({ docs }) => head(docs))
    .map(prop("id"))

export const getStudentSchool = studentRecord =>
  getDocumentById("schools", path(["student_school_id", "id"], studentRecord))
    .map(doc => doc.data())
    .map(student_school_aggregated_info => ({
      ...studentRecord,
      student_school_aggregated_info,
    }))

export const getStudentCompetences = studentRecord =>
  compose(
    interestsList =>
      Async.all(map(getDocumentById("learning_competences"), interestsList))
        .map(map(getDocumentData))
        .map(student_interests_aggregated_info => ({
          ...studentRecord,
          student_interests_aggregated_info,
        })),
    map(path(["id"])),
    path(["student_competences"]),
  )(studentRecord)

export const getStudentPrograms = studentRecord =>
  compose(
    enrolledPrograms =>
      Async.all(
        map(getDocumentById("learning_programs"), enrolledPrograms),
      ).map(
        map(converge(merge, [getProgramDocumentData, getProgramDocumentId])),
      ),
    map(path(["student_enrolled_program_id", "id"])),
    path(["student_enrolled_programs"]),
  )(studentRecord)

export const getStudentSubcollectionPath = cond([
  [equals("exercise"), always("/student_history_exercises/")],
  [equals("question"), always("/student_history_questions/")],
  [equals("program"), always("/student_history_programs/")],
])

export const getStudentSubcollection = collectionName =>
  compose(
    firestore.collection.bind(firestore),
    concat("school_students/"),
    concat(path(["currentUser", "uid"], auth)),
    getStudentSubcollectionPath,
  )(collectionName)

export const getStudentPath = () =>
  concat("school_students/", path(["currentUser", "uid"], auth))

// export const getStudentCollection = getStudentSubcollection(
//   auth,
//   bind(firestore.collection, firestore),
// )

export const addHistoryQuestion = async data => {
  const {
    question,
    questionExecutionCount,
    exercise,
    program,
    activeAttempts,
    helpCount,
    timestampStart,
  } = data

  const doc = {
    learning_question: firestore.doc(
      `/learning_questions/${getQuestionId(question)}`,
    ),
    learning_program: firestore.doc(
      `/learning_programs/${getProgramId(program)}`,
    ),
    learning_exercise: firestore.doc(
      `/learning_exercises/${getExerciseId(exercise)}`,
    ),
    history_question_execution_count: questionExecutionCount,
    history_question_points_possible: getQuestionPoints(question),
    history_question_type: getQuestionType(question),
    history_question_cognitive_type: getQuestionCognitiveType(question),
    history_question_version: getQuestionVersion(question),
    history_question_attempts: activeAttempts,
    history_question_help_count: helpCount,
    history_question_time_started_at: timestampStart,
    history_question_time_finished_at: Date.now(),
  }
  await getStudentSubcollection("question").add(doc)
}

export const addHistoryExercise = async data => {
  const { exercise, program } = data

  const doc = {
    learning_program: firestore.doc(
      `/learning_programs/${getProgramId(program)}`,
    ),
    learning_exercise: firestore.doc(
      `/learning_exercises/${getExerciseId(exercise)}`,
    ),
    history_exercise_time_finished_at: Date.now(),
  }
  await getStudentSubcollection("exercise").add(doc)
}

export const getHistoryExercisesByProgramId = async programId => {
  const programRef = firestore.doc(`/learning_programs/${programId}`)
  const snapshot = await getStudentSubcollection("exercise")
    .where("learning_program", "==", programRef)
    .orderBy("history_exercise_time_finished_at", "asc")
    .get()
  return snapshot.docs.map(doc => doc.data().learning_exercise)
}

export const studentIsAnonymous = studentRecord =>
  cond([
    [isNil, always(false)],
    [equals(true), always(true)],
    [T, always(false)],
  ])(prop("student_is_anonym", studentRecord))

export const studentIsOnboarded = studentRecord =>
  cond([
    [isNil, always(false)],
    [equals(true), always(true)],
    [T, always(false)],
  ])(prop("student_is_onboarded", studentRecord))

export const updateStudent = curry((studentRecord, data) =>
  getStudentId(studentRecord).chain(_ =>
    setDocumentId("school_students", _, data),
  ),
)

export const getCurrentProgram = async () => {
  const [allProgramsSnapshot, programsHistorySnapshot] = await Promise.all([
    firestore.doc("learning_chapters/MATH01").get(),
    getStudentSubcollection("program").get(),
  ])
  const programsHistoryIds = programsHistorySnapshot.docs.map(
    doc => doc.data().learning_program.id,
  )
  const openPrograms = allProgramsSnapshot
    .data()
    .learning_chapter_programs.filter(
      doc => !programsHistoryIds.includes(doc.id),
    )
  return head(openPrograms).get()
}

const getLearningProgramData = compose(
  prop("learning_program"),
  getDocumentData,
)

export const getStudentHistoryPrograms = compose(
  collection =>
    getCollection(collection)
      .map(({ docs }) => docs.map(getLearningProgramData))
      .chain(programs =>
        Async.all(
          programs.map(ref =>
            getDocument(ref.path).map(
              converge(merge, [getProgramDocumentData, getProgramDocumentId]),
            ),
          ),
        ),
      ),
  concat(__, "/student_history_programs"),
  concat("school_students/"),
)

const getQuestionData = compose(
  prop("learning_question"),
  getDocumentData,
)

export const getHistoryQuestionsByExerciseId = curry((studentId, exerciseId) =>
  simpleGet(`school_students/${studentId}/student_history_questions`, [
    "learning_exercise",
    "==",
    firestore.doc(`learning_exercises/${exerciseId}`),
  ]).map(({ docs }) => docs.map(getDocumentData)),
)

export const getHistoryQuestionsById = curry((studentId, questionId) =>
  simpleGet(`school_students/${studentId}/student_history_questions`, [
    "learning_question",
    "==",
    firestore.doc(`learning_questions/${questionId}`),
  ]).map(({ docs }) => docs.map(getQuestionData)),
)

export const getAllStudentRecords = Async((failure, success) =>
  firestore
    .collection("school_students")
    .get()
    .then(success)
    .catch(failure),
) //.map(({ docs }) => docs.map(getDocumentData))

export const getAllHistoryProgramRecords = ({ id }) =>
  Async((failure, success) =>
    firestore
      .collection("school_students")
      .doc(id)
      .collection("student_history_programs")
      .get()
      .then(success)
      .catch(failure),
  )

export const updateHistoryProgram = curry(
  (payload, { ref: documentReference }) =>
    Async((failure, success) =>
      documentReference
        .update(payload)
        .then(success)
        .catch(failure),
    ),
)

export const updateHistoryProgramList = firestoreDocumentList => {
  const documentReferenceList = map(getDocumentReference, firestoreDocumentList)
  Async.all(map(updateHistoryProgram), documentReferenceList)
}
