import "./Footer.scss"

import React, { PureComponent } from "react"
import { StateMachineContext } from "contexts"

class FooterComponent extends PureComponent {
  handleShowImprint = () => {
    this.props.transition("OVERLAY_OPEN_IMPRINT")
  }

  handleShowPrivacy = () => {
    this.props.transition("OVERLAY_OPEN_DATA_PRIVACY")
  }

  render() {
    return (
      <>
        <div className="footer flex-shrink-0 env-padding-bottom">
          <span className="footer__link cursor-pointer" onClick={this.handleShowImprint}>
            Impressum
          </span>
          <span className="footer__divider">|</span>
          <span className="footer__link cursor-pointer" onClick={this.handleShowPrivacy}>
            Datenschutz
          </span>
        </div>
      </>
    )
  }
}

const Footer = () => (
  <StateMachineContext.Consumer>
    {stateMachine => <FooterComponent {...stateMachine} />}
  </StateMachineContext.Consumer>
)

export default Footer
