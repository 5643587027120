import "./ProgressIcon.scss"

import React, { Component } from "react"
import { number, shape, object } from "prop-types"

import and from "ramda/src/and"
import cond from "ramda/src/cond"
import equals from "ramda/src/equals"
import not from "ramda/src/not"
import T from "ramda/src/T"

import { withUser } from "contexts/UserContext"
import { Asset } from "components/Assets"
import Student from "models/Student"
import { requestAppReview } from "helpers/appReview"
import { consoleInfo } from "helpers/generic"

class ProgressIcon extends Component {
  componentDidUpdate(prevProps) {
    const { userContext } = this.props

    const {
      userContext: { exercisesDone },
    } = this.props

    if (
      userContext.student &&
      prevProps.userContext.student !== userContext.student
    ) {
      const student = Student(
        userContext.student.data(),
        userContext.student.id,
      )

      const hasCompatibleExerciseCount = count =>
        cond([[equals(3), () => true], [T, () => count % 5 === 0]])(count)

      cond([
        [
          equals(true),
          () =>
            requestAppReview.fork(
              consoleInfo("App Review Request Failed"),
              consoleInfo("App Review Request Success"),
            ),
        ],
        [
          T,
          consoleInfo(
            "App Review Request skipped: no compatible exercise count.",
          ),
        ],
      ])(
        and(
          not(student.hasRepliedToAppReviewRequest()),
          hasCompatibleExerciseCount(exercisesDone),
        ),
      )
    }
  }

  render = () => (
    <span className="header-icon rounded ml-1 pl-1 py-1 pr-2">
      <span className="d-inline-block mr-3">
        <Asset path="star-red" type="svg" />
      </span>
      <span className="d-inline-block text-white">
        <strong>{this.props.userContext.exercisesDone}</strong> Sterne von{" "}
        <strong>{this.props.userContext.exercisesTotal}</strong>
      </span>
    </span>
  )
}

ProgressIcon.propTypes = {
  userContext: shape({
    exercisesDone: number.isRequired,
    exercisesTotal: number.isRequired,
    student: object,
  }),
}

export default withUser(ProgressIcon)
