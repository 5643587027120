import cond from "ramda/src/cond"
import gt from "ramda/src/gt"
import length from "ramda/src/length"
import T from "ramda/src/T"
import { number, array, func, object } from "prop-types"

import React, { Component } from "react"
import { StateMachineContext } from "contexts"
import { addHistoryExercise } from "helpers/data/student"
import { analytics } from "helpers/tracking"

import { Loader } from "components/Assets"

class StudentExerciseHasFollowingQuestion extends Component {
  static propTypes = {
    nextStep: number,
    exercise: object,
    questionList: array,
    questionExecutionCount: number,
    transition: func,
  }

  constructor(props) {
    super(props)

    const {
      nextStep,
      questionList,
      questionExecutionCount,
      transition,
    } = this.props

    window.requestAnimationFrame(() =>
      cond([
        [
          gt(length(questionList)),
          () =>
            transition("FOLLOWING_QUESTION_EXISTS", {
              activeAnswers: [],
              activeAttempts: 0,
              activeQuestion: null,
              hadAccessToHelpModal: false,
              hadAccessToHelpNotice: false,
              hasCorrectAnswer: false,
              hasIncorrectAnswer: false,
              questionList,
              helpCount: 0,
              timestampStart: Date.now(),
            }),
        ],
        [
          T,
          async () => {
            const { program, exercise } = this.props
            const saveData = {
              exercise,
              program,
            }

            analytics.page(null, "Exercise Finished", {
              path: `/StudentExerciseFinished/${exercise.learning_exercise_id}`,
            })

            await addHistoryExercise(saveData)
            transition("FOLLOWING_QUESTION_EMPTY", {
              activeAnswers: [],
              activeAttempts: 0,
              activeQuestion: null,
              exercise,
              hadAccessToHelpModal: false,
              hadAccessToHelpNotice: false,
              hasCorrectAnswer: false,
              hasIncorrectAnswer: false,
              questionExecutionCount,
              questionList,
              helpCount: 0,
              timestampStart: Date.now(),
            })
          },
        ],
      ])(nextStep),
    )
  }
  render() {
    return <Loader />
  }
}

const StudentExerciseHasFollowingQuestionContainer = () => (
  <StateMachineContext.Consumer>
    {props => <StudentExerciseHasFollowingQuestion {...props} />}
  </StateMachineContext.Consumer>
)

export default StudentExerciseHasFollowingQuestionContainer
