import React, { PureComponent } from "react"

import cond from "ramda/src/cond"
import equals from "ramda/src/equals"

import wormDataAppear from "assets/animations/worm.appear.json"

import Animation from "components/Assets/Animation/Animation"

const animationDataList = {
  appear: wormDataAppear,
}

class Worm extends PureComponent {
  state = {
    active: "appear",
  }

  constructor(props) {
    super(props)

    this.setAnimationType = this.setAnimationType.bind(this)
    this.playAnimation = this.playAnimation.bind(this)
    this.handleOnComplete = this.handleOnComplete.bind(this)
  }

  componentDidMount() {
    const { active } = this.state
    this.playAnimation(active)
  }

  componentDidUpdate() {
    const { active } = this.state
    this.playAnimation(active)
  }

  setAnimationType(animation) {
    this.setState({ active: animation })
  }

  playAnimation(animation) {
    this[`${animation}Ref`].play()
  }

  handleOnComplete(animation) {
    const { active } = this.state
    cond([[equals(animation), () => this.setState({ active: "appear" })]])(
      active,
    )
  }

  render() {
    const { active } = this.state
    return (
      <>
        {Object.keys(animationDataList).map(animation => (
          <Animation
            key={animation}
            data={animationDataList[animation]}
            hide={active !== animation}
            autoplay={false}
            onComplete={() => this.handleOnComplete(animation)}
            ref={ref => (this[`${animation}Ref`] = ref)}
          />
        ))}
      </>
    )
  }
}

export default Worm
