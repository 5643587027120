import "./StudentAnonymousProfileUpdate.scss"
import React, { Component } from "react"
import { EnvironmentContext, StateMachineContext } from "contexts"
import { State } from "react-automata"

import { Alert, Button, Form, Input, Label } from "reactstrap"
import { Owl } from "components/Assets"
import Overlay from "components/Assets/Overlay/Overlay"

import { getTranslation } from "helpers/translations"

class StudentAnonymousProfileUpdateComponent extends Component {
  constructor(props) {
    super(props)

    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  state = { newEmail: "", newPassword: "" }

  handleInputChange = event => {
    const {
      target: { name, checked, value, type },
    } = event
    const val = type === "checkbox" ? checked : value
    this.setState({ [name]: val })
  }

  handleCancel = () => {
    this.props.transition("PROFILE_UPDATE_CANCEL")
  }

  handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()

    const { newEmail, newPassword } = this.state

    this.props.transition("PROFILE_UPDATE_SUBMIT", { newEmail, newPassword })
  }

  render = () => {
    const { code, language } = this.props

    const getProfileUpdateErrorTranslation = getTranslation(language, "errors")

    return (
      <Overlay show>
        <div className="d-flex h-100 w-100">
          <div className="col-10 col-md-8 col-lg-6 col-xl-4 mx-auto px-0 py-4 m-auto">
            <Form onSubmit={this.handleSubmit}>
              <div
                className="card card-body d-flex flex-column justify-content-center align-content-center h-100"
                style={{ borderRadius: "2.5rem" }}
              >
                <h2 className="overlay-title text-center text-brainimals-dark">
                  Lernkonto registrieren
                </h2>
                <div className="landing-character">
                  <Owl startWith="happy" />
                </div>
                <div className="py-3 text-center">
                  Super! Einfach E-Mail-Addresse und Passwort angeben. Damit
                  kannst du dich dann auf allen Geräten anmelden, um dort weiter
                  zu üben.
                </div>
                <div className="form-label-group">
                  <Input
                    autoComplete="email"
                    bsSize="lg"
                    type="email"
                    placeholder="username"
                    id="newEmail"
                    name="newEmail"
                    onChange={this.handleInputChange}
                    required
                    autoFocus
                  />
                  <Label htmlFor="newEmail">Email</Label>
                </div>
                <div className="form-label-group">
                  <Input
                    autoComplete="new-password"
                    bsSize="lg"
                    type="password"
                    placeholder="password"
                    id="newPassword"
                    name="newPassword"
                    onChange={this.handleInputChange}
                    required
                  />
                  <Label htmlFor="newPassword">Passwort</Label>
                </div>

                <State is="*ProfileUpdateFailure">
                  <Alert
                    className="text-center font-size-sm p-2"
                    color="warning"
                  >
                    {getProfileUpdateErrorTranslation(code)}
                  </Alert>
                </State>
                <Button block color="secondary" type="submit" size="lg">
                  Lernkonto registrieren
                </Button>
                <Button
                  block
                  color="light"
                  onClick={this.handleCancel}
                  size="lg"
                  type="button"
                >
                  Zurück
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Overlay>
    )
  }
}

const StudentAnonymousProfileUpdate = () => (
  <EnvironmentContext.Consumer>
    {environmentProps => (
      <StateMachineContext.Consumer>
        {stateMachineProps => (
          <StudentAnonymousProfileUpdateComponent
            {...environmentProps}
            {...stateMachineProps}
          />
        )}
      </StateMachineContext.Consumer>
    )}
  </EnvironmentContext.Consumer>
)

export default StudentAnonymousProfileUpdate
