import "./StudentProgramDone.scss"

import React, { Component } from "react"
import { TreeAnimated } from "components/Assets"
import { StateMachineContext } from "contexts"

import FadeInOut from "components/Animations/FadeInOut"
import { Button } from "reactstrap"

const CelebratingTree = (
  <TreeAnimated startWith="celebrate" defaultAnimation="celebrate" />
)

class StudentProgramDone extends Component {
  constructor(props) {
    super(props)
    this.goToLanding = this.goToLanding.bind(this)
  }

  goToLanding = () => {
    this.props.transition("GOTO_LANDING")
  }

  // Tracking for StudentProgramDone is located in StudentExerciseFinished

  render() {
    return (
      <div className="d-flex flex-column h-100">
        <div>
          <FadeInOut delay={500}>
            <h2 className="program-done-celebration-title text-center text-brainimals-dark">
              Super, alle Aufgaben gelöst!
            </h2>
          </FadeInOut>
        </div>
        <div className="program-done-celebration position-relative overflow-hidden flex-grow-1">
          <FadeInOut delay={250}>
            <div className="program-done-celebration__level1 position-absolute m-auto w-100 h-100 d-flex align-items-center justify-content-center">
              <FadeInOut delay={400}>{CelebratingTree}</FadeInOut>
              <FadeInOut delay={200}>{CelebratingTree}</FadeInOut>
              <FadeInOut delay={600}>{CelebratingTree}</FadeInOut>
            </div>
          </FadeInOut>
        </div>
        <div>
          <div className="program-done-celebration-footer d-flex align-items-center justify-content-center">
            <FadeInOut delay={750}>
              <Button color="primary" onClick={this.goToLanding} size="lg">
                Weiter!
              </Button>
            </FadeInOut>
          </div>
        </div>
      </div>
    )
  }
}

const StudentProgramDoneContainer = () => (
  <StateMachineContext.Consumer>
    {props => <StudentProgramDone {...props} />}
  </StateMachineContext.Consumer>
)

export default StudentProgramDoneContainer
