import React, { Component } from "react"
import { Keyframes, config } from "react-spring"
import classnames from "classnames"

const Container = Keyframes.Spring(async (next, ownProps) => {
  await next({
    from: { opacity: 0, transform: "scale(0.8)" },
    to: { opacity: 1, transform: "scale(1)" },
    config: config.wobbly,
  })
})

export default class Card extends Component {
  render = () => {
    const { children, disabled, finished, ...props } = this.props
    const cardClassName = classnames(
      "exercise-card exercise-card__exercise text-white my-5",
      {
        "exercise-card__disabled": disabled,
        "exercise-card__finished": finished,
      },
    )

    return (
      <Container>
        {style => (
          <span
            ref={ref => (this.cardReference = ref)}
            className={cardClassName}
            {...props}
            style={style}
          >
            <h5 className="exercise-card-subtitle text-center">{props.subtitle}</h5>
            <h4 className="exercise-card-title text-center">{props.title}</h4>
            {children}
          </span>
        )}
      </Container>
    )
  }
}
