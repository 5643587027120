import "./StudentSignin.css"

import { State } from "react-automata"
import { EnvironmentContext, StateMachineContext } from "contexts"

import React, { Component } from "react"
import FadeInOut from "components/Animations/FadeInOut"

import { Alert, Button, Form, Label, Input } from "reactstrap"
import { Asset } from "components/Assets"

import { getTranslation } from "helpers/translations"
import { analytics } from "helpers/tracking"

class StudentSigninComponent extends Component {
  constructor(props) {
    super(props)

    this.state = { inputEmail: "", inputPassword: "" }
  }

  componentDidMount() {
    analytics.page(null, "Signin", { path: "/StudentSignin" })
  }

  handleInputChange = event => {
    const target = event.target

    const value = target.type === "checkbox" ? target.checked : target.value

    const name = target.name

    this.setState({ [name]: value })
  }

  handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()

    this.props.transition("SIGNIN_SUBMIT", this.state)
  }

  handleRecovery = event => {
    event.preventDefault()
    event.stopPropagation()

    this.props.transition("ACCOUNT_RECOVER")
  }

  handleReturn = () => {
    this.props.transition("SIGNIN_CANCEL")
  }
  render() {
    const { code, language } = this.props
    const getSigninErrorTranslation = getTranslation(language, "errors")

    return (
      <FadeInOut>
        <div className="d-flex flex-column h-100 overflow-y-scroll">
          <div className="d-flex flex-grow-1 flex-shrink-0 flex-basis-auto align-content-center">
            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-4 mx-auto landing-form my-auto">
              <div className="d-flex flex-column justify-content-center h-100">
                <Form onSubmit={this.handleSubmit}>
                  <FadeInOut>
                    <>
                      <div className="landing-header text-center">
                        <Asset
                          path="ollis.logo"
                          className="landing-logo"
                          type="svg"
                        />
                        <div className="landing-display">
                          Tierisch einfach zum Lernerfolg!
                        </div>
                      </div>
                      <div className="landing-character">
                        <Asset path="owl.default" type="svg" />
                      </div>
                    </>
                  </FadeInOut>

                  <div className="form-label-group">
                    <Input
                      autoComplete="email"
                      bsSize="lg"
                      type="email"
                      placeholder="username"
                      id="inputEmail"
                      name="inputEmail"
                      onChange={this.handleInputChange}
                      required
                      autoFocus
                    />
                    <Label htmlFor="inputEmail">Email</Label>
                  </div>

                  <div className="form-label-group">
                    <Input
                      autoComplete="current-password"
                      bsSize="lg"
                      type="password"
                      placeholder="password"
                      id="inputPassword"
                      name="inputPassword"
                      onChange={this.handleInputChange}
                      required
                    />
                    <Label htmlFor="inputPassword">Passwort</Label>
                  </div>

                  <State is="*SigninFailure">
                    <Alert
                      className="text-center font-size-sm p-2"
                      color="warning"
                    >
                      {getSigninErrorTranslation(code)}
                    </Alert>
                  </State>

                  <Button block color="primary" type="submit" size="lg">
                    Anmelden
                  </Button>
                  <Button
                    block
                    color="secondary"
                    onClick={this.handleReturn}
                    size="lg"
                    type="button"
                  >
                    Zurück
                  </Button>

                  <Button
                    block
                    color="link"
                    onClick={this.handleRecovery}
                    type="button"
                  >
                    Passwort vergessen?
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </FadeInOut>
    )
  }
}

const StudentSignin = () => (
  <EnvironmentContext.Consumer>
    {environmentProps => (
      <StateMachineContext.Consumer>
        {stateMachineProps => (
          <StudentSigninComponent
            {...environmentProps}
            {...stateMachineProps}
          />
        )}
      </StateMachineContext.Consumer>
    )}
  </EnvironmentContext.Consumer>
)

export default StudentSignin
