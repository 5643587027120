import React, { PureComponent } from "react"

import cond from "ramda/src/cond"
import compose from "ramda/src/compose"
import equals from "ramda/src/equals"
import not from "ramda/src/not"
import T from "ramda/src/T"

import treeDataAppear from "assets/animations/tree.disappear.json"
import treeDataCelebrate from "assets/animations/tree.celebrate.json"

import Animation from "components/Assets/Animation/Animation"

const animationDataList = {
  appear: treeDataAppear,
  celebrate: treeDataCelebrate,
}

class Tree extends PureComponent {
  constructor(props) {
    super(props)

    this.setAnimationType = this.setAnimationType.bind(this)
    this.playAnimation = this.playAnimation.bind(this)
    this.replayAnimation = this.replayAnimation.bind(this)
    this.handleOnComplete = this.handleOnComplete.bind(this)
  }

  componentDidMount() {
    const { defaultAnimation } = this.props
    this.playAnimation(defaultAnimation)
  }

  componentDidUpdate() {
    const { defaultAnimation } = this.props
    this.playAnimation(defaultAnimation)
  }

  setAnimationType(animation) {
    this.setState({ defaultAnimation: animation })
  }

  playAnimation(animation) {
    const { defaultAnimation } = this.props
    cond([
      [
        equals("appear"),
        () => this[`${animation}Ref`].playSegmentsBackwards([50, 8]),
      ],
      [T, () => this[`${animation}Ref`].play()],
    ])(defaultAnimation)
  }

  replayAnimation(animation) {
    const { defaultAnimation } = this.props
    cond([
      [
        equals("appear"),
        () => this[`${animation}Ref`].playSegmentsBackwards([50, 8]),
      ],
      [T, () => this[`${animation}Ref`].animation.goToAndPlay(0)],
    ])(defaultAnimation)
  }

  handleOnComplete(animation) {
    cond([
      [
        compose(
          not,
          equals("appear"),
        ),
        () => this.replayAnimation(animation),
      ],
    ])(animation)
  }

  componentWillUnmount() {
    clearTimeout(this.animationTimeout)
  }

  render() {
    const { defaultAnimation } = this.props
    return (
      <>
        {Object.keys(animationDataList).map(animation => (
          <Animation
            key={animation}
            data={animationDataList[animation]}
            hide={defaultAnimation !== animation}
            autoplay={false}
            onComplete={() => this.handleOnComplete(animation)}
            ref={ref => (this[`${animation}Ref`] = ref)}
          />
        ))}
      </>
    )
  }
}

Tree.defaultProps = {
  defaultAnimation: "appear",
  maxInterval: 6000,
  minInterval: 250,
}

export default Tree
