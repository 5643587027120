import React, { Component } from "react"

import { State } from "react-automata"
import { StateMachineContext } from "contexts"

import StudentProgramStart from "./StudentProgramStart/StudentProgramStart"
import StudentProgramDone from "./StudentProgramDone/StudentProgramDone"

class StudentProgram extends Component {
  render() {
    return (
      <>
        <State is="*.StudentProgramStart">
          <StudentProgramStart />
        </State>
        <State is="*.StudentProgramDone">
          <StudentProgramDone />
        </State>
        {/* <State is="*.StudentProgramContinue"><StudentProgramContinue /></State>
				<State is="*.StudentProgramRepeat"><StudentProgramRepeat /></State> */}
      </>
    )
  }
}

const StudentProgramContainer = () => (
  <StateMachineContext.Consumer>
    {props => <StudentProgram {...props} />}
  </StateMachineContext.Consumer>
)

export default StudentProgramContainer
