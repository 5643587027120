import React from "react"
import { StateMachineContext } from "contexts"
import { State } from "react-automata"

import Placeholder from "components/Chat/Placeholder/Placeholder"

import addIndex from "ramda/src/addIndex"
import always from "ramda/src/always"
import compose from "ramda/src/compose"
import cond from "ramda/src/cond"
import equals from "ramda/src/equals"
import filter from "ramda/src/filter"
import isNil from "ramda/src/isNil"
import length from "ramda/src/length"
import map from "ramda/src/map"
import not from "ramda/src/not"
import reverse from "ramda/src/reverse"
import slice from "ramda/src/slice"
import T from "ramda/src/T"
import take from "ramda/src/take"

const StudentQuestionAnswerThze = ({
  bucket,
  row,
  studentAnswer,
  questionAnswer,
}) => {
  const headerList = reverse(take(row.length, ["E", "Z", "H", "T"]))

  return (
    <table className="table-grid">
      <thead>
        <tr>
          {addIndex(map)(
            (header, hIndex) => (
              <th scope="col" key={hIndex}>
                <span>{header}</span>
              </th>
            ),
            headerList,
          )}
        </tr>
      </thead>
      <tbody>
        <tr>
          {addIndex(map)(
            (rowContent, rowContentIndex) =>
              cond([
                [
                  isNil,
                  () => {
                    const nullIndex = compose(
                      length,
                      filter(isNil),
                      slice(0, rowContentIndex),
                    )(row)

                    const position = bucket[nullIndex]

                    const hasSuccess = equals(
                      studentAnswer[position],
                      questionAnswer[position],
                    )
                    const hasError = not(hasSuccess)

                    return (
                      <React.Fragment key={rowContentIndex}>
                        <State is="*StudentQuestionAnswerEvaluation">
                          <Placeholder
                            type={"table-thze"}
                            hasError={hasError}
                            hasSuccess={hasSuccess}
                          >
                            {studentAnswer[position]}
                          </Placeholder>
                        </State>
                        <State
                          is={[
                            "*StudentQuestionAnswerEmpty",
                            "*StudentQuestionAnswerNotEmpty",
                            "*StudentQuestionAnswerComplete",
                          ]}
                        >
                          <Placeholder type={"table-thze"}>
                            {studentAnswer[position]}
                          </Placeholder>
                        </State>
                      </React.Fragment>
                    )
                  },
                ],
                [T, always(<span key={rowContentIndex}>{rowContent}</span>)],
              ])(rowContent),
            row,
          )}
        </tr>
      </tbody>
    </table>
  )
}

export default componentProps => (
  <StateMachineContext.Consumer>
    {props => <StudentQuestionAnswerThze {...componentProps} {...props} />}
  </StateMachineContext.Consumer>
)
