import "./StudentAnonymousProfileCheck.scss"
import React, { Component } from "react"
import { AuthenticationContext, StateMachineContext } from "contexts"
import { updateStudent } from "helpers/data/student"

import { Button } from "reactstrap"
import { Owl } from "components/Assets"
import Overlay from "components/Assets/Overlay/Overlay"

class StudentAnonymousProfileCheck extends Component {
  constructor(props) {
    super(props)

    this.handleProfileErase = this.handleProfileErase.bind(this)
    this.handleProfileUpdate = this.handleProfileUpdate.bind(this)
  }

  handleProfileErase = () => {
    const { student } = this.props
    const { _raw: studentRecord } = student

    updateStudent(studentRecord, {
      student_account_closed: true,
    }).fork(console.error, student.signOut)
  }

  handleProfileUpdate = () => {
    this.props.transition("PROFILE_UPDATE")
  }

  render = () => (
    <Overlay show>
      <div className="d-flex m-auto">
        <div className="col-10 col-md-8 col-lg-6 col-xl-4 mx-auto h-100 px-0 py-4">
          <div
            className="card card-body d-flex flex-column justify-content-center align-content-center h-100"
            style={{ borderRadius: "2.5rem" }}
          >
            <h2 className="overlay-title text-center text-brainimals-dark">
              Möchtest du später weitermachen?
            </h2>
            <div>
              <div className="landing-character">
                <Owl startWith="wave" />
              </div>
              <div className="py-3 text-center">
                Lege ein Lernkonto an, bevor du dich abmeldest. Dann bleiben
                deine gesammelten Sterne erhalten. Du kannst später weitere
                Aufgaben lösen, egal auf welchem Gerät (Computer, Tablet,
                Smartphone).
              </div>
            </div>
            <Button
              className="mt-3"
              color="secondary"
              block
              onClick={this.handleProfileUpdate}
              size="lg"
            >
              Lernkonto registrieren
            </Button>
            <Button
              className="mt-3"
              color="danger"
              block
              onClick={this.handleProfileErase}
              size="lg"
            >
              Lernfortschritt löschen
            </Button>
          </div>
        </div>
      </div>
    </Overlay>
  )
}

export default ownProps => (
  <AuthenticationContext.Consumer>
    {student => (
      <StateMachineContext.Consumer>
        {stateMachineProps => (
          <StudentAnonymousProfileCheck
            {...stateMachineProps}
            {...ownProps}
            student={student}
          />
        )}
      </StateMachineContext.Consumer>
    )}
  </AuthenticationContext.Consumer>
)
