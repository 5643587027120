import { StateMachineContext } from "contexts"

import React, { Component } from "react"
import FadeInOut from "components/Animations/FadeInOut"

import { Button } from "reactstrap"
import { Asset, Worm, Footer } from "components/Assets"
import { analytics } from "helpers/tracking"

import "./StudentSignedOutLanding.scss"

class StudentSignedOutLandingComponent extends Component {
  wormReference = null

  componentDidMount() {
    analytics.page(null, "Signedout Landing", {
      path: "/StudentSignedOutLanding",
    })
  }

  render() {
    const { transition } = this.props

    return (
      <FadeInOut>
        <div className="d-flex flex-column flex-grow-1 flex-shrink-0 flex-basis-auto h-100 overflow-y-scroll">
          <div className="d-flex flex-grow-1 flex-shrink-0 flex-basis-auto align-content-center">
            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-4 mx-auto landing-form my-auto">
              <div className="d-flex flex-column justify-content-center h-100">
                <FadeInOut>
                  <>
                    <div className="landing-header text-center">
                      <Asset
                        path="ollis.logo"
                        className="landing-logo"
                        type="svg"
                      />
                      <div className="landing-display">
                        Tierisch einfach zum Lernerfolg!
                      </div>
                    </div>
                    <div className="landing-character">
                      <Worm ref={ref => (this.wormReference = ref)} />
                    </div>
                  </>
                </FadeInOut>
                <Button
                  className="mt-3"
                  color="primary"
                  block
                  onClick={() => transition("ACCOUNT_ANONYMOUS_CREATE")}
                  size="lg"
                >
                  Sofort anfangen
                </Button>
                <Button
                  className="mt-3"
                  color="secondary"
                  block
                  onClick={() => transition("ACCOUNT_CREATE")}
                  size="lg"
                >
                  Lernkonto registrieren
                </Button>
                <Button
                  className="mt-3"
                  color="link"
                  block
                  onClick={() => transition("ACCOUNT_SIGNIN")}
                  size="md"
                >
                  Du hast bereits ein Lernkonto?
                  <br />
                  <strong>Anmelden</strong>
                </Button>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </FadeInOut>
    )
  }
}

const StudentSignedOutLanding = () => (
  <StateMachineContext.Consumer>
    {props => <StudentSignedOutLandingComponent {...props} />}
  </StateMachineContext.Consumer>
)

export default StudentSignedOutLanding
