import React from "react"
import "./GenericWarning.scss"
import { Offline, Online } from "react-detect-offline"
import ConnectionWarning from "components/Assets/ConnectionWarning/ConnectionWarning"

const GenericWarning = () => (
  <>
    <Offline
      polling={{
        url: "https://icanhazepoch.com",
        interval: 10000,
      }}
    >
      <ConnectionWarning />
    </Offline>
    <Online>
      <div
        className={`d-flex flex-column align-items-center justify-content-center h-100 w-100 bg-backdrop-brainimals-deep-sea-blue position-absolute zindex-backdrop px-3 env-padding`}
        style={{ zIndex: 2999 }}
      >
        <h1 className={"text-center text-brainimals-green-light"}>Oh oh...</h1>
        <p className="generic-warning text-white env-margin-left env-margin-right">
          Es gibt ein Problem mit der App. Bitte versuche es später nochmal.
        </p>
      </div>
    </Online>
  </>
)

export default GenericWarning
