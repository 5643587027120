/* eslint-disable react/display-name */

import React, { PureComponent } from "react"
import { node, bool, func, string, object, shape } from "prop-types"
import { Transition } from "react-spring"

import { StateMachineContext } from "contexts"
import { CloseButton } from "components/Assets"

class OverlayComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.handleCloseOverlay = this.handleCloseOverlay.bind(this)
  }

  handleCloseOverlay = () => {
    const { onClosePayload, transition } = this.props
    transition("OVERLAY_CLOSE", onClosePayload)
  }

  render = () => {
    const { show, overlayHeadline, children } = this.props

    return (
      <Transition
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
      >
        {show &&
          (styles => (
            <div
              style={styles}
              className="overlay overflow-y-auto d-flex flex-column h-100 w-100 bg-backdrop-brainimals-deep-sea-blue position-absolute zindex-backdrop fixed-top env-padding-top"
            >
              <CloseButton onClick={this.handleCloseOverlay} />
              {overlayHeadline && (
                <div>
                  <div className="col-11 col-sm-10 col-lg-8 mx-auto p-0">
                    <h2 className="overlay-title text-brainimals-green-light pt-4">
                      {overlayHeadline}
                    </h2>
                  </div>
                </div>
              )}
              {children}
            </div>
          ))}
      </Transition>
    )
  }
}

OverlayComponent.propTypes = {
  transition: func,
  show: bool,
  overlayHeadline: string,
  children: node,
  styles: object,
  onClosePayload: shape({ code: string }),
}

OverlayComponent.defaultProps = {
  onClosePayload: {},
}

const Overlay = ownProps => (
  <StateMachineContext.Consumer>
    {stateMachine => <OverlayComponent {...stateMachine} {...ownProps} />}
  </StateMachineContext.Consumer>
)

export default Overlay
