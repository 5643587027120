import React, { Component } from "react"
import { State } from "react-automata"
import { StateMachineContext } from "contexts"

import Overlay from "components/Assets/Overlay/Overlay"
import Agreement from "components/StudentSignupAgreement/Agreement"

class PrivacyAgreementComponent extends Component {
  agreementRef = React.createRef()

  constructor(props) {
    super(props)

    this.handleAccept = this.handleAccept.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleAccept = () => {
    this.props.transition("AGREEMENT_ACCEPT")
  }

  handleClose = () => {
    this.props.transition("AGREEMENT_CLOSE", {
      code: "ui/canceled-registration",
    })
  }

  render = () => (
    <Overlay
      overlayHeadline={"Datenschutz"}
      show
      onClosePayload={{
        code: "ui/closed-data-privacy-agreement",
      }}
    >
      <>
        <div className="overlay-content h-100 align-content-center overflow-y-scroll">
          <div className="col-11 col-sm-10 col-lg-8 mx-auto p-0">
            <div className="d-flex flex-column h-100">
              <div className="text-white overlay-copy">
                <Agreement />
              </div>
            </div>
          </div>
        </div>
        <State is={["*StudentSignupAgreement", "*StudentAnonymousProfileUpdateAgreement"]}>
          <div className="text-center py-3">
            <button
              className="btn btn-lg btn-secondary"
              onClick={this.handleAccept}
            >
              Zustimmen
            </button>
          </div>
        </State>
      </>
    </Overlay>
  )
}

export default ownProps => (
  <StateMachineContext.Consumer>
    {stateMachineProps => (
      <PrivacyAgreementComponent {...stateMachineProps} {...ownProps} />
    )}
  </StateMachineContext.Consumer>
)
