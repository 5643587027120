import Async from "crocks/Async"
import compose from "ramda/src/compose"
import map from "ramda/src/map"
import prop from "ramda/src/prop"
import invoker from "ramda/src/invoker"
import concat from "ramda/src/concat"
import converge from "ramda/src/converge"
import merge from "ramda/src/merge"

import { getDocument } from "helpers/firebase/firestore"
import {
  getProgramDocumentData,
  getProgramDocumentId,
} from "helpers/data/program"

export const getChapterIndex = prop("learning_chapter_index")
export const getChapterTitle = prop("learning_chapter_title")
export const getChapterPrograms = prop("learning_chapter_programs")

export const getChapterById = compose(
  chapterPath => getDocument(chapterPath).map(invoker(0, "data")),
  concat("learning_chapters/"),
)

export const getChapterRelatedPrograms = compose(
  Async.all,
  map(program =>
    getDocument(prop("path", program)).map(
      converge(merge, [getProgramDocumentData, getProgramDocumentId]),
    ),
  ),
)
