import compose from "ramda/src/compose"
import prop from "ramda/src/prop"
import { getDocumentData, simpleGet} from "helpers/firebase/firestore"
import { firestore } from "helpers/firebase"

export const getStudentHistoryExerciseId = compose(
  prop("id"),
  prop("learning_exercise"),
)

export const getStudentHistoryExercisesByProgramId = (studentId, programId) =>
  simpleGet(`school_students/${studentId}/student_history_exercises`, [
    "learning_program",
    "==",
    firestore.doc(`learning_programs/${programId}`),
  ]).map(({ docs }) => docs.map(getDocumentData))
