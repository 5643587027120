import React from "react"

import Bubble from "components/Chat/Bubble/Bubble"
import { Asset } from "components/Assets"
import classnames from "classnames"

import addIndex from "ramda/src/addIndex"
import always from "ramda/src/always"
import cond from "ramda/src/cond"
import equals from "ramda/src/equals"
import isEmpty from "ramda/src/isEmpty"
import map from "ramda/src/map"
import not from "ramda/src/not"
import T from "ramda/src/T"

export const NoticeError = ({ noticeList, icon }) => {
  return (
    <>
      {icon && (
        <Bubble>
          <span
            className="d-inline-block ml-3"
            style={{ position: "relative", bottom: "-0.4rem" }}
          >
            <Asset
              path={icon}
              style={{ height: "2.25rem", width: "2.25rem", marginTop: "-1rem" }}
              type="svg"
            />
          </span>
        </Bubble>
      )}
      {addIndex(map)(
        (content, index) => (
          <Bubble key={index} className="bubble-notice">
            {content}
          </Bubble>
        ),
        noticeList,
      )}
    </>
  )
}

export const NoticeSuccess = ({ children }) => {
  const messageNotEmpty = not(isEmpty(children))

  const iconClass = classnames("d-inline-block", {
    "ml-3": messageNotEmpty,
  })

  const iconStyle = cond([
    [equals(true), always({ height: "2rem", width: "2rem" })],
    [T, always({ height: "2.25rem", width: "2.25rem", marginTop: "-1rem" })],
  ])(messageNotEmpty)

  return (
    <Bubble className="bubble-notice">
      {messageNotEmpty && children}
      <span
        className={iconClass}
        style={{ position: "relative", bottom: "-0.4rem" }}
      >
        <Asset path="owl.answer.right_1" style={iconStyle} type="svg" />
      </span>
    </Bubble>
  )
}
