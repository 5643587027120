import "./ParentalGate.scss"

import React, { Component } from "react"
import { AgeFromDate } from "age-calculator"
import { StateMachineContext } from "contexts"
import { State } from "react-automata"

import { analytics } from "helpers/tracking"
import { Asset } from "components/Assets"
import FadeInOut from "components/Animations/FadeInOut"
import { Button, Form, Input, Label } from "reactstrap"

import Overlay from "components/Assets/Overlay/Overlay"

import cond from "ramda/src/cond"
import lte from "ramda/src/lte"
import T from "ramda/src/T"

class ParentalGate extends Component {
  agreementRef = React.createRef()

  state = {
    inputDay: null,
    inputMonth: null,
    inputYear: null,
  }

  componentDidMount() {
    analytics.page(null, "Parental Gate", { path: "/ParentalGate" })
  }

  constructor(props) {
    super(props)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.handleFailure = this.handleFailure.bind(this)
  }

  handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()

    const { inputDay, inputMonth, inputYear } = this.state
    const inputAge = new AgeFromDate(new Date(inputYear, inputMonth, inputDay))

    cond([[lte(16), this.handleSuccess], [T, this.handleFailure]])(inputAge.age)
  }

  handleSuccess = () => {
    this.props.transition("GATE_SUCCESS")
  }

  handleFailure = () => {
    this.props.transition("GATE_FAILURE")
  }

  handleRetry = () => {
    this.props.transition("GATE_RETRY")
  }

  handleCancel = () => {
    this.props.transition("GATE_CANCEL")
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name
    this.setState({ [name]: value })
  }

  render = () => (
    <Overlay
      show
      onClosePayload={{
        code: "ui/closed-parental-gate",
      }}
    >
      <>
        <div
          className={`d-flex flex-column align-items-center justify-content-center h-100 w-100 env-padding`}
        >
          <FadeInOut delay={250}>
            <Asset path="cat.standing" className="character-1" type="svg" />
          </FadeInOut>
          <FadeInOut delay={500}>
            <Asset
              path="bear.standing.smile"
              className="character-2"
              type="svg"
            />
          </FadeInOut>
          <div className="parental-gate-overlay__content d-flex flex-column align-items-center justify-content-center m-auto col-sm-8 col-md-6 col-lg-6 col-xl-5 bg-backdrop-brainimals-deep-sea-blue">
            <FadeInOut delay={1000}>
              <State is={"*ParentalGate"}>
                <div className={"text-center"}>
                  <h1 className="text-brainimals-green-light pt-4 my-0">
                    Moment mal!
                  </h1>
                </div>
                <p className="text-white text-center my-4">
                  Bitte deine Eltern, dass sie ein Konto für dich anlegen.
                  <br />
                  Voraussetzung ist ein Alter von mindestens 16 Jahre, um den
                  Datenschutzbestimmungen zuzustimmen.
                </p>
                <p className="text-brainimals-green-light text-center">
                  Wenn du ein Elternteil bist, gib dein Geburtsdatum unten an:
                </p>
                <Form onSubmit={this.handleSubmit}>
                  <div className="form-row">
                    <div className="col">
                      <div className="form-label-group">
                        <Input
                          bsSize="lg"
                          type="number"
                          placeholder="Tag"
                          id="inputDay"
                          name="inputDay"
                          min={1}
                          max={31}
                          onChange={this.handleInputChange}
                          required
                          autoFocus
                        />
                        <Label htmlFor="inputDay">Tag</Label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-label-group">
                        <Input
                          bsSize="lg"
                          type="number"
                          placeholder="Monat"
                          id="inputMonth"
                          name="inputMonth"
                          min={1}
                          max={12}
                          onChange={this.handleInputChange}
                          required
                        />
                        <Label htmlFor="inputMonth">Monat</Label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-label-group">
                        <Input
                          bsSize="lg"
                          type="number"
                          placeholder="Jahr"
                          id="inputYear"
                          name="inputYear"
                          min={new Date().getFullYear() - 150}
                          max={new Date().getFullYear()}
                          onChange={this.handleInputChange}
                          required
                        />
                        <Label htmlFor="inputYear">Jahr</Label>
                      </div>
                    </div>
                  </div>
                  <div className="text-center py-3">
                    <Button color="secondary" type="submit" size="lg">
                      Weiter
                    </Button>
                  </div>
                </Form>
              </State>
            </FadeInOut>
            <FadeInOut delay={200}>
              <State is={"*ParentalGateFailure"}>
                <div className={"text-center"}>
                  <h1 className="text-brainimals-green-light pt-4 mb-0">
                    Hmm...
                  </h1>
                </div>
                <FadeInOut delay={250}>
                  <p className="alert alert-warning text-center m-3">
                    Du must <strong>älter als 16 Jahre</strong> sein, um bei der
                    Registrierung fortfahren zu können.
                  </p>
                </FadeInOut>
                <FadeInOut delay={500}>
                  <p className="text-center text-white env-margin-left env-margin-right my-4">
                    Bitte deine Eltern, dass sie ein Konto für dich anlegen.
                    <br />
                    Voraussetzung ist ein Alter von mindestens 16 Jahre, um den
                    Datenschutzbestimmungen zuzustimmen.
                  </p>
                </FadeInOut>
                <FadeInOut delay={2500}>
                  <div className="text-center py-3">
                    <Button
                      color="secondary"
                      onClick={this.handleRetry}
                      size="lg"
                    >
                      Zurück
                    </Button>
                  </div>
                </FadeInOut>
              </State>
            </FadeInOut>
          </div>
        </div>
      </>
    </Overlay>
  )
}

export default ownProps => (
  <StateMachineContext.Consumer>
    {stateMachineProps => <ParentalGate {...stateMachineProps} {...ownProps} />}
  </StateMachineContext.Consumer>
)
