import React from "react"

import SVG from "./SVG/SVG"

import cond from "ramda/src/cond"
import equals from "ramda/src/equals"

const Asset = ({ type, ...props }) =>
  cond([[equals("svg"), () => <SVG {...props} />]])(type)

export default Asset
