import "./ApplicationLanding.css"

import React, { Component } from "react"
import { Container, Col } from "reactstrap"

import FadeInOut from "components/Animations/FadeInOut"
import { StateMachineContext } from "contexts"

import { Asset } from "components/Assets"

class ApplicationLandingComponent extends Component {
  constructor(props) {
    super(props)
    window.requestAnimationFrame(() => props.transition("SESSION_VERIFY"))
  }
  render() {
    return (
      <FadeInOut>
        <Container
          fluid
          className="d-flex align-items-center justify-content-center main-container"
        >
          <Col sm={6} className="d-flex">
            <Asset
              path="ollis.logo"
              style={{ height: "auto", width: "100%" }}
              type="svg"
            />
          </Col>
        </Container>
      </FadeInOut>
    )
  }
}

const ApplicationLanding = () => (
  <StateMachineContext.Consumer>
    {props => <ApplicationLandingComponent {...props} />}
  </StateMachineContext.Consumer>
)

export default ApplicationLanding
