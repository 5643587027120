import { State } from "react-automata"
import { StateMachineContext } from "contexts"

import Markdown from "react-markdown"
import Bubble from "components/Chat/Bubble/Bubble"

import React, { Component } from "react"

import FadeInOut from "components/Animations/FadeInOut"
import { Container } from "reactstrap"

import { Transition } from "react-spring"
import { Asset } from "components/Assets"

import { getQuestionHelpModal } from "helpers/data/question"

class StudentQuestionHelp extends Component {
  render = () => {
    const { handleClose } = this.props

    return (
      <Transition from={{ opacity: 0 }} enter={{ opacity: 1 }}>
        {styles => (
          <State is="*StudentQuestionHelpActive*">
            <Container
              fluid
              className="question-help d-flex flex-column align-items-center justify-content-between main-container h-100 w-100 position-fixed text-center bg-backdrop-brainimals-deep-sea-blue position-absolute zindex-backdrop"
              style={styles}
            >
              <FadeInOut delay={250}>
                <Asset
                  path="owl.helping"
                  className="container-help-owl"
                  type="svg"
                />
              </FadeInOut>
              <div className="col-10 col-md-8 col-lg-6 pt-4">
                <Bubble block align="center" className="bubble-help">
                  <Asset
                    path="help.lamp"
                    className="container-help-lightbulb mb-3"
                    type="svg"
                    style={{
                      transform: "scale(0.8)",
                    }}
                  />
                  <FadeInOut delay={350}>
                    <div className="mx-2 my-3">
                      <Markdown escapeHtml={false}>
                        {decodeURIComponent(
                          getQuestionHelpModal(this.props.question).replace(
                            "src='/",
                            "src='",
                          ),
                        )}
                      </Markdown>
                    </div>
                  </FadeInOut>
                </Bubble>
              </div>
              <FadeInOut delay={500}>
                <div className="container-help-close">
                  <button
                    className="btn btn-lg btn-primary"
                    onClick={handleClose}
                  >
                    Aufgabe lösen
                  </button>
                </div>
              </FadeInOut>
            </Container>
          </State>
        )}
      </Transition>
    )
  }
}

export default ownProps => (
  <StateMachineContext.Consumer>
    {stateMachineProps => (
      <StudentQuestionHelp {...stateMachineProps} {...ownProps} />
    )}
  </StateMachineContext.Consumer>
)
