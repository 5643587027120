import prop from "ramda/src/prop"

import compose from "ramda/src/compose"
import curry from "ramda/src/curry"
import objOf from "ramda/src/objOf"
import path from "ramda/src/path"

export const getProgramId = prop("learning_program_id")

export const getProgramDocumentData = doc => doc.data()
export const getProgramDocumentId = compose(
  objOf("learning_program_id"),
  prop("id"),
)
export const getProgramTitle = prop("learning_program_title")
export const getProgramCover = curry((theme, exerciseRecord) =>
  path(
    ["learning_program_theme", theme, "learning_program_cover"],
    exerciseRecord,
  ),
)
export const getProgramExercises = prop("learning_program_exercises")
export const getProgramIsAssessment = prop("learning_program_is_assessment")
