import { StateMachineContext } from "contexts"
import { signInWithEmailAndPassword } from "helpers/firebase/auth"

import React, { Component } from "react"

import { Loader } from "components/Assets"

class HasValidCredentialsComponent extends Component {
  constructor(props) {
    super(props)

    const { inputEmail, inputPassword } = props

    this.handleSignin(inputEmail, inputPassword)
  }

  handleSignin = (inputEmail, inputPassword) =>
    signInWithEmailAndPassword(inputEmail, inputPassword).fork(
    _ => {
      // Promise seems never be resolved
      // firebase bug?
      // But auth.onAuthStateChanged in StudentHasSesion updates context
      this.props.transition("CREDENTIALS_FAILURE", _)
    },
      _ => this.props.transition("CREDENTIALS_SUCCESS", _),
    )

  render() {
    return <Loader />
  }
}

const HasValidCredentials = () => (
  <StateMachineContext.Consumer>
    {props => <HasValidCredentialsComponent {...props} />}
  </StateMachineContext.Consumer>
)

export default HasValidCredentials
