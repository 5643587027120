import React, { Component } from "react"
import { object, number, bool, func } from "prop-types"
import classnames from "classnames"
import lottie from "lottie-web"
import curry from "ramda/src/curry"

export const delayDisplay = curry(
  (factor, bubbleIndex) => (bubbleIndex + 1) * factor,
)

class Animation extends Component {
  static propTypes = {
    data: object.isRequired,
    speed: number,
    loop: bool,
    autoplay: bool,
    hide: bool,
    onComplete: func,
  }

  static defaultProps = {
    autoplay: true,
    speed: 1,
    loop: false,
    onComplete: () => {},
  }

  componentDidUpdate(prevProps) {
    const { hide, autoplay } = this.props
    if (hide !== prevProps.hide && !hide && autoplay) {
      this.play()
    }
  }

  play() {
    this.animation.goToAndPlay(0)
  }

  playSegmentsBackwards(segments) {
    this.animation.goToAndStop(segments[0], true)
    this.animation.setDirection(-1)
    this.animation.playSegments(segments)
  }

  componentDidMount() {
    const { data, speed, loop, autoplay } = this.props

    this.animation = lottie.loadAnimation({
      container: this.lottieTarget,
      renderer: "svg",
      loop,
      autoplay,
      animationData: data,
    })

    this.animation.setSpeed(speed)

    this.animation.addEventListener("complete", this.props.onComplete)
  }

  componentWillUnmount() {
    this.animation.removeEventListener("complete")
    this.animation.destroy()
  }

  render() {
    const animationClassname = classnames({
      "d-none": this.props.hide,
    })

    return (
      <div
        className={animationClassname}
        ref={ref => (this.lottieTarget = ref)}
      />
    )
  }
}

export default Animation
