const firebaseErrors = new Map([
  [
    "auth/email-already-in-use",
    {
      "de-DE": "Diese E-Mail ist bereits bei uns registriert.",
    },
  ],
  [
    "auth/invalid-email",
    {
      "de-DE":
        "Dies ist leider kein richtiges E-Mail Format. Bitte überprüfe noch einmal deine Eingabe.",
    },
  ],
  [
    "auth/operation-not-allowed",
    {
      "de-DE": "Deine Zugangsdaten sind nicht aktiv. Schreibe uns eine E-Mail oder registriere dich neu.",
    },
  ],
  [
    "auth/weak-password",
    {
      "de-DE": "Wähle ein stärkeres Passwort. Du kannst Groß-, Kleinbuchstaben, Zahlen und Sonderzeichen kombinieren.",
    },
  ],
  [
    "auth/user-disabled",
    {
      "de-DE": "Deine Zugangsdaten wurden deaktiviert. Schreibe uns eine E-Mail oder registriere dich neu.",
    },
  ],
  [
    "auth/user-not-found",
    {
      "de-DE": "Unbekannte E-Mail. Bitte überprüfe noch einmal deine Eingabe.",
    },
  ],
  [
    "auth/wrong-password",
    {
      "de-DE": "Leider passt das Passwort nicht zu deiner E-Mail. Überprüfe noch einmal die Eingaben. Schreibe uns eine E-Mail, wenn du Hilfe brauchst.",
    },
  ],
  [
    "auth/expired-action-code",
    {
      "de-DE": "Leider ist der Link zum Zurücksetzen deines Passworts abgelaufen. Fordere ihn noch einmal neu an.",
    },
  ],
  [
    "auth/invalid-action-code",
    {
      "de-DE": "Leider funktioniert der Link zum Zurücksetzen deines Passworts nicht. Fordere ihn noch einmal neu an.",
    },
  ],
])

const internalErrors = new Map([
  [
    "ui/closed-data-privacy-agreement",
    {
      "de-DE": "Um die OLLIS App zu nutzen musst du unserer Datenschutzerklärung zustimmen",
    },
  ],
  [
    "ui/closed-parental-gate",
    {
      "de-DE": "Um die OLLIS App zu nutzen musst du unserer Datenschutzerklärung zustimmen",
    },
  ],
])

export default new Map([...firebaseErrors, ...internalErrors])
