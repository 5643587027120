export default {
  id: "Interface",
  parallel: true,
  on: {},
  states: {
    Overlay: {
      id: "Overlay",
      on: {
        OVERLAY_CLOSE: "#OverlayClosed",
        OVERLAY_OPEN_DATA_PRIVACY: "#DataPrivacy",
        OVERLAY_OPEN_IMPRINT: "#Imprint",
        OVERLAY_OPEN_PARENTAL_GATE: "#ParentalGate",
      },
      states: {
        OverlayClosed: {
          id: "OverlayClosed",
        },
        DataPrivacy: {
          id: "DataPrivacy",
        },
        Imprint: {
          id: "Imprint",
        },
        ParentalGate: {
          id: "ParentalGate",
          on: {
            GATE_SUCCESS: "#StudentSignup",
            GATE_FAILURE: "#StudentSignupParentalGateFailure",
          },
        },
      },
    },
    Notification: {
      id: "Notification",
      on: {
        NOTIFICATION_CLOSE: "#NotificationClosed",
        NOTIFICATION_OPEN: "#NotificationOpen",
      },
      states: {
        NotificationClosed: {
          id: "NotificationClosed",
        },
        NotificationOpen: {
          id: "NotificationOpen",
        },
      },
    },
  },
}
