import React, { Component } from "react"
import FadeInOut from "components/Animations/FadeInOut"

import classnames from "classnames"

import always from "ramda/src/always"
import cond from "ramda/src/cond"
import equals from "ramda/src/equals"
import T from "ramda/src/T"

const getPlaceholderClass = cond([
  [equals("bubble"), always("placeholder__bubble")],
  [equals("underscore"), always("placeholder__underscore")],
  [equals("table-thze"), always("placeholder__underscore")],
])

const getPlaceholderErrorClass = cond([
  [equals("bubble"), always("placeholder__bubble__danger")],
  [equals("underscore"), always("placeholder__underscore__danger")],
  [equals("table-thze"), always("placeholder__underscore__danger")],
])

const getPlaceholderSuccessClass = cond([
  [equals("bubble"), always("placeholder__bubble__success")],
  [equals("underscore"), always("placeholder__underscore__success")],
  [equals("table-thze"), always("placeholder__underscore__success")],
])

export default class Placeholder extends Component {
  render = () => {
    const { children, hasError, hasSuccess, type, ...props } = this.props

    const placeholderClassname = classnames(
      "placeholder",
      getPlaceholderClass(type),
      {
        [`${getPlaceholderErrorClass(type)}`]: hasError,
        [`${getPlaceholderSuccessClass(type)}`]: hasSuccess,
      },
    )

    return cond([
      [
        equals("table-thze"),
        always(
          <td>
            <span className={placeholderClassname} {...props}>
              {children}
            </span>
          </td>,
        ),
      ],
      [
        T,
        always(
          <FadeInOut>
            <span className={placeholderClassname} {...props}>
              {children}
            </span>
          </FadeInOut>,
        ),
      ],
    ])(type)
  }
}
