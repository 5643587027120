import React from "react"
import Overlay from "components/Assets/Overlay/Overlay"

const Imprint = () => (
  <Overlay overlayHeadline={"Impressum"} show>
    <div className="overlay-content h-100 align-content-center overflow-y-scroll">
      <div className="col-11 col-sm-10 col-lg-8 mx-auto p-0">
        <div className="d-flex flex-column h-100">
          <div className="text-white overlay-copy">
            <>
              <h2>Angaben gemäß § 5 TMG</h2>
              <p>
                Brainimals GmbH
                <br />
                Möckernstraße 96
                <br />
                10963 Berlin
              </p>
              <p>
                Handelsregister: HRB 197504
                <br />
                Registergericht: Berlin Charlottenburg
              </p>
              <p>
                <strong>Vertreten durch die Geschäftsführung:</strong>
                <br />
                Martina Gstöttmayr
                <br />
              </p>
              E-Mail: martina@ollis.app
              <br />
              <br />
              <h2>Umsatzsteuer-ID</h2>
              <p>
                Umsatzsteuer-Identifikationsnummer gemäß §27 a
                Umsatzsteuergesetz:
                <br />
                DE319992055
              </p>
              <br />
              <p>
                Die Lern-Anwendung OLLIS wird unterstützt durch Förderungen der{" "}
                <strong>
                  Berliner Senatsverwaltung für Wirtschaft, Technologie und
                  Frauen
                </strong>{" "}
                sowie der <strong>Investitionsbank Berlin.</strong>
              </p>
              OLLIS ist eine plattform-übergreifende Lern-Anwendung (Web und
              Mobile), die individualisiertes und selbstständiges Lernen auf
              allen Geräten ermöglicht. Ziel ist es, dass jedes Kind damit die
              gleiche Chance beim Lernen bekommt.
              <br />
              <br />
              <p>
                Bei Domainstreitigkeiten, wettbewerbsrechtlichen oder ähnlichen
                Vorbehalten, die es gegenüber uns gibt, bitten wir zur
                Vermeidung unnötiger Kosten und Rechtsstreitigkeiten um
                frühzeitige Kontaktaufnahme. Eine Abmahnung ohne vorherige
                Kontaktaufnahme könnte andernfalls als rechtsmißbräuchlich zu
                qualifizieren und eine entsprechende Kostennote (z. B. unter
                Schadensminderungsgesichtspunkten) unbegründet sein.
              </p>
              <br />
              <h3>Haftung für Inhalte</h3>
              <p>
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                gespeicherte fremde Informationen zu überwachen oder nach
                Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                hinweisen.
              </p>
              <p>
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                Informationen nach den allgemeinen Gesetzen bleiben hiervon
                unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
                Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
                Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden
                wir diese Inhalte umgehend entfernen.
              </p>
              <br />
              <h3>Urheberrecht</h3>
              <p>
                Die durch die Betreiber erstellten Inhalte und Werke auf diesen
                Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten,
                nicht kommerziellen Gebrauch gestattet.
              </p>
              <br />
              <br />
            </>
          </div>
        </div>
      </div>
    </div>
  </Overlay>
)

export default Imprint
