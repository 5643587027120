import Interface from "statecharts/Interface/Interface.xsc"
import StudentSignedOut from "statecharts/StudentSignedOut/StudentSignedOut.xsc"
import StudentSignedIn from "statecharts/StudentSignedIn/StudentSignedIn.xsc"

export default {
  id: "root",
  parallel: true,
  on: {},
  states: {
    Interface: Interface,
    Views: {
      id: "Views",
      initial: "ApplicationLanding",
      on: {},
      states: {
        ApplicationLanding: {
          id: "ApplicationLanding",
          on: {
            SESSION_VERIFY: "#StudentHasSession?",
          },
          states: {},
        },
        "StudentHasSession?": {
          id: "StudentHasSession?",
          on: {
            SESSION_EMPTY: "#StudentSignedOut",
            SESSION_EXISTS: "#StudentSignedIn",
          },
          states: {},
        },
        StudentSignedIn: StudentSignedIn,
        StudentSignedOut: StudentSignedOut,
      },
    },
  },
}
