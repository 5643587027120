import { StateMachineContext } from "contexts"
import { State } from "react-automata"
import React, { Component } from "react"

import FadeInOut from "components/Animations/FadeInOut"
import ViewBackground from "components/Assets/Backgrounds/Trees"

import StudentQuestionActive from "./StudentQuestionActive/StudentQuestionActive"
import StudentQuestionSetup from "./StudentQuestionSetup/StudentQuestionSetup"

import StudentQuestionFooter from "./StudentQuestionFooter/StudentQuestionFooter"

// Tracking for StudentQuestion is located in StudentQuestionSetup

class StudentQuestionComponent extends Component {
  render() {
    const { questionList } = this.props
    return (
      <FadeInOut>
        <>
          <div className="clouds-wrap">
            <FadeInOut delay={300}>
              <div className="cloud-x1">
                <div className="cloud" />
              </div>
            </FadeInOut>
            <FadeInOut delay={1500}>
              <div className="cloud-x2">
                <div className="cloud" />
              </div>
            </FadeInOut>
            <FadeInOut delay={900}>
              <div className="cloud-x3">
                <div className="cloud" />
              </div>
            </FadeInOut>
            <FadeInOut delay={2700}>
              <div className="cloud-x4">
                <div className="cloud" />
              </div>
            </FadeInOut>
            <FadeInOut delay={2100}>
              <div className="cloud-x5">
                <div className="cloud" />
              </div>
            </FadeInOut>
          </div>
          <State is={["*QuestionSetup*"]}>
            <StudentQuestionSetup questionList={questionList} />
          </State>
          <State is={["*QuestionActive*"]}>
            <StudentQuestionActive />
          </State>
          <State
            is={[
              "*StudentExerciseCalculateRewards",
              "*StudentExerciseHasFollowingQuestion?",
              "*QuestionSetup*",
            ]}
          >
            <div className="d-flex flex-column h-100 env-margin-top">
              <div className="d-flex flex-grow-1 flex-basis-auto align-content-center position-relative p-2 h-100" />
              <StudentQuestionFooter />
            </div>
          </State>
          <ViewBackground />
        </>
      </FadeInOut>
    )
  }
}

const StudentQuestion = () => (
  <StateMachineContext.Consumer>
    {props => <StudentQuestionComponent {...props} />}
  </StateMachineContext.Consumer>
)

export default StudentQuestion
