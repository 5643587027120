import React from "react"

const Agreement = () => (
  <>
    <p>
      Mit den OLLIS haben wir uns zum Ziel gesetzt eine Lernplattform
      anzubieten, die erfolgreiches und zugleich spielerisches Lernen
      ermöglicht. OLLIS ist eine intelligente Lern-Anwendung, die man
      herunterladen kann oder unter www.ollis.app aufrufen kann. Jedes Kind soll
      damit die gleiche Chance beim Lernen bekommen.
    </p>
    <p>
      Um unsere Lern-Anwendung sinnvoll zu betreiben, benötigen wir von den
      Nutzern Informationen. Diese sind notwendig, um die technische
      Verfügbarkeit zu gewährleisten und unser Angebot immer weiter zu
      optimieren. Ferner kann damit der Lernfortschritt gespeichert und zu einem
      späteren Zeitpunkt wieder zur Verfügung gestellt werden.{" "}
    </p>
    <p>
      Wir informieren unsere Nutzer umfänglich, wie wir die Daten nutzen. Wir
      versichern, dass der Datenschutz bei uns höchste Priorität hat und wir die
      Daten ausschließlich im Sinne unserer Nutzer einsetzen.
    </p>
    <p>
      Fragen zum Datenschutz können uns unter datenschutz@ollis.app geschrieben werden.
    </p>
    <h2>1. So gehen wir mit den Daten um: Datenschutz auf einen Blick</h2>
    <h3>Allgemeine Hinweise</h3>{" "}
    <p>
      Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
      deinen personenbezogenen Daten passiert, wenn du unsere Lern-Anwendung
      besuchst. Personenbezogene Daten sind alle Daten, mit denen du persönlich
      identifiziert werden kannst. Ausführliche Informationen zum Thema
      Datenschutz findest du in der unter diesem Text aufgeführten
      Datenschutzerklärung.
    </p>
    <h3>Datenerfassung in unserer Lern-Anwendung</h3>
    <p>
      <strong>
        Wer ist verantwortlich für die Datenerfassung in der Lern-Anwendung?
      </strong>
    </p>
    <p>
      Die Datenverarbeitung in unserer Lern-Anwendung erfolgt durch den
      Betreiber. Dessen Kontaktdaten kann dem Impressum entnommen werden.
    </p>
    <p>
      <strong>Wie erfassen wir deine Daten?</strong>
    </p>
    <p>
      Deine Daten werden zum einen dadurch erhoben, dass du uns diese mitteilst.
      Hierbei kann es sich z.B. um Daten handeln, die du z.B. in ein
      Registrierungsformular eingibst.
    </p>
    <p>
      Andere Daten werden automatisch beim Besuch der Lern-Anwendung erfasst.
      Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem
      oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
      automatisch, sobald du unsere Lern-Anwendung aufrufst.
    </p>
    <p>
      <strong>Wofür nutzen wir deine Daten?</strong>
    </p>
    <p>
      Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
      Lern-Anwendung zu gewährleisten. Andere Daten können zur Analyse des
      Nutzerverhaltens verwendet werden um unsere Lern-Anwendung noch besser zu
      machen.
    </p>{" "}
    <h3>Datenerfassung im Lernkonto</h3>
    <p>
      Im Lernkonto speichern wir den Lernfortschritt, um eine optimale
      Lernunterstützung und damit ein positives Lernerlebnis zu bieten.
    </p>
    <h3>Man kann zwischen zwei möglichen Lernkonten wählen:</h3>
    <p>
      <strong>
        1. Pseudonymisiertes Lernkonto (Nur auf einem Gerät nutzbar, ohne
        Anmeldung direkt loslegen)
      </strong>
    </p>
    <p>
      Hier speichern wir den <i>User Agent</i> und die IP Adresse.
    </p>
    <p>
      <strong>
        2. Registriertes Lernkonto (Auf mehreren Geräten nutzbar, mit Login)
      </strong>
    </p>
    <p>
      Hier speichern wir <i>User Agent</i>, IP Adresse, E-Mail (z.B. von den
      Eltern) und Passwort.
    </p>
    Bei beiden Lernkonten sind keine persönlichen Daten vom Kind erforderlich.
    <p>
      <strong>Welche Rechte hast du bezüglich der Daten?</strong>
    </p>{" "}
    <p>
      Jeder Nutzer hat das Recht jederzeit unentgeltlich Auskunft über Herkunft,
      Empfänger und Zweck der gespeicherten personenbezogenen Daten zu erhalten.
      Er hat außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser
      Daten verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz
      kannst du dich sich jederzeit unter der im Impressum angegebenen Adresse
      an uns wenden. Des Weiteren steht dir ein Beschwerderecht bei der
      zuständigen Aufsichtsbehörde zu.
    </p>
    <p>
      Friedrichstrasse 219, 10969 Berlin, Besichereingang Puttkamerstrasse
      16-18, Telefon: 0049-30-138890, E-Mail: mailbox@datenschutz-berlin.de
    </p>
    <p>
      Außerdem habst du das Recht, unter bestimmten Umständen die Einschränkung
      der Verarbeitung deiner personenbezogenen Daten zu verlangen. Details
      hierzu kannst du der Datenschutzerklärung unter „Recht auf Einschränkung
      der Verarbeitung“ entnehmen.
    </p>
    <h2>2. Allgemeine Hinweise und Pflichtinformationen</h2>
    <h3>Datenschutz</h3>{" "}
    <p>
      Wir als die Betreiber der Lern-Anwendung nehmen den Schutz deiner
      persönlichen Daten sehr ernst. Wir behandeln die personenbezogenen Daten
      vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften
      sowie dieser Datenschutzerklärung.
    </p>{" "}
    <p>
      Wenn du diese Lern-Anwendung benutzt, werden verschiedene personenbezogene
      Daten erhoben. Personenbezogene Daten sind Daten, mit denen du persönlich
      identifiziert werden können. Die vorliegende Datenschutzerklärung
      erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
      erläutert auch, wie und zu welchem Zweck das geschieht.
    </p>{" "}
    <p>
      Wir weisen darauf hin, dass die Datenübertragung im Internet
      Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem
      Zugriff durch Dritte ist nicht möglich.
    </p>
    <h3>Hinweis zur verantwortlichen Stelle</h3>{" "}
    <p>
      Die verantwortliche Stelle für die Datenverarbeitung in der Lern-Anwendung
      ist:
    </p>{" "}
    <p>
      Martina Gstöttmayr
      <br />
      Möckernstraße 96
      <br />
      10963 Berlin
    </p>
    <p>E-Mail: martina@ollis.app</p>
    <p>
      Verantwortliche Stelle ist die natürliche oder juristische Person, die
      allein oder gemeinsam mit anderen über die Zwecke und Mittel der
      Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o.
      Ä.) entscheidet.
    </p>
    <h3>Widerruf der Einwilligung zur Datenverarbeitung</h3>{" "}
    <p>
      Viele Datenverarbeitungsvorgänge sind nur mit deiner ausdrücklichen
      Einwilligung möglich. Du kannst eine bereits erteilte Einwilligung
      jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
      uns (datenschutz@ollis.app). Die Rechtmäßigkeit der bis zum Widerruf
      erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
    </p>
    <h3>
      Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
      Direktwerbung (Art. 21 DSGVO)
    </h3>{" "}
    <p>
      <strong>
        Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f
        DSGVO erfolgt, hast du jederzeit das Recht, aus Gründen, die sich aus
        deiner besonderen Situation ergeben, gegen die Verarbeitung deiner
        personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für ein
        auf diese Bestimmungen gestütztes Profiling. Die jeweilige
        Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnimmst du dieser
        Datenschutzerklärung. Wenn du Widerspruch einlegst, werden wir deine
        betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei denn,
        wir können zwingende schutzwürdige Gründe für die Verarbeitung
        nachweisen, die deine Interessen, Rechte und Freiheiten überwiegen oder
        die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung
        von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO).
      </strong>
    </p>
    <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>{" "}
    <p>
      Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
      Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
      Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
      des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
      unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
      Rechtsbehelfe.
    </p>
    <h3>Recht auf Datenübertragbarkeit</h3>{" "}
    <p>
      Du hast das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in
      Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen
      Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen.
      Sofern du die direkte Übertragung der Daten an einen anderen
      Verantwortlichen verlangst, erfolgt dies nur, soweit es technisch machbar
      ist.
    </p>
    <h3>SSL- bzw. TLS-Verschlüsselung</h3>{" "}
    <p>
      Diese Lern-Anwendung nutzt aus Sicherheitsgründen und zum Schutz der
      Übertragung vertraulicher Inhalte eine SSL-bzw. TLS-Verschlüsselung. Eine
      verschlüsselte Verbindung erkennst du daran, dass die Adresszeile des
      Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol
      in Ihrer Browserzeile.
    </p>{" "}
    <p>
      Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
      die du an uns übermittelst, nicht von Dritten mitgelesen werden.
    </p>
    <h3>Auskunft, Sperrung, Löschung und Berichtigung</h3>{" "}
    <p>
      Jeder Nutzer hat im Rahmen der geltenden gesetzlichen Bestimmungen
      jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
      personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
      Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder
      Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
      personenbezogene Daten kann er sich jederzeit unter der im Impressum
      angegebenen Adresse an uns wenden.
    </p>
    <h3>Recht auf Einschränkung der Verarbeitung</h3>{" "}
    <p>
      Jeder Nutzer hat das Recht, die Einschränkung der Verarbeitung seiner
      personenbezogenen Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum
      Thema personenbezogene Daten kann er sich jederzeit unter der im Impressum
      angegebenen Adresse an uns wenden. Das Recht auf Einschränkung der
      Verarbeitung besteht in folgenden Fällen:
    </p>{" "}
    <ul>
      {" "}
      <li>
        Wenn du die Richtigkeit deiner bei uns gespeicherten personenbezogenen
        Daten bestreitest, benötigen wir in der Regel Zeit, um dies zu
        überprüfen. Für die Dauer der Prüfung hast du das Recht, die
        Einschränkung der Verarbeitung einer personenbezogenen Daten zu
        verlangen.
      </li>{" "}
      <li>
        Wenn die Verarbeitung deiner personenbezogenen Daten unrechtmäßig
        geschah / geschieht, kannst statt der Löschung die Einschränkung der
        Datenverarbeitung verlangen.
      </li>{" "}
      <li>
        Wenn wir deine personenbezogenen Daten nicht mehr benötigen, du sie
        jedoch zur Ausübung, Verteidigung oder Geltendmachung von
        Rechtsansprüchen benötigst, hast du das Recht, statt der Löschung die
        Einschränkung der Verarbeitung deiner personenbezogenen Daten zu
        verlangen.
      </li>{" "}
      <li>
        Wenn du einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt hast, muss
        eine Abwägung zwischen deiner und unseren Interessen vorgenommen werden.
        Solange noch nicht feststeht, wessen Interessen überwiegen, hast du das
        Recht, die Einschränkung der Verarbeitung deiner personenbezogenen Daten
        zu verlangen.
      </li>{" "}
    </ul>{" "}
    <p>
      Wenn wir die Verarbeitung deiner personenbezogenen Daten eingeschränkt
      haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
      deiner Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
      von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
      oder juristischen Person oder aus Gründen eines wichtigen öffentlichen
      Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet
      werden.
    </p>
    <h2>3. Datenerfassung in unserer Lern-Anwendung</h2>
    <h3>Cookies</h3>{" "}
    <p>
      Die Lern-Anwendung verwendet teilweise so genannte Cookies. Cookies
      richten auf deinem Gerät keinen Schaden an und enthält keine Viren.
      Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
      sicherer zu machen. Cookies sind kleine Textdateien, die auf deinem Gerät
      abgelegt werden und die dein Browser speichert.
    </p>{" "}
    <p>
      Die meisten der von uns verwendeten Cookies sind so genannte
      “Session-Cookies”. Sie werden nach Ende der Nutzung automatisch gelöscht.
      Andere Cookies bleiben auf deinem Endgerät gespeichert bis du diese
      löschst. Diese Cookies ermöglichen es uns, deinen Browser beim nächsten
      Besuch wiederzuerkennen.
    </p>{" "}
    <p>
      Du kannst deinen Browser so einstellen, dass du über das Setzen von
      Cookies informiert wirst und Cookies nur im Einzelfall erlauben, die
      Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
      das automatische Löschen der Cookies beim Schließen des Browser
      aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität der
      Lern-Anwendung eingeschränkt sein.
    </p>{" "}
    <p>
      Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs
      oder zur Bereitstellung bestimmter, erwünschter Funktionen erforderlich
      sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der
      Betreiber hat ein berechtigtes Interesse an der Speicherung von Cookies
      zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste.
      Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens)
      gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert
      behandelt.
    </p>
    <h3>Webanalyseprogramme</h3>
    <p>
      Bei dem Betrieb unserer Lern-Anwendung verwenden wir Funktionen des
      Webanalysedienstes Google Analytics, um die Nutzung von dieser zu messen
      und diese weiter optimieren zu können. Anbieter ist die Google Inc. 1600
      Amphitheatre Parkway Mountain View, CA 94043, USA. Google Analytics
      verwendet sog. {"Cookies"}. Siehe Infos bei beim Abschnitt “Cookies”. Die
      durch den Cookie erzeugten Informationen über deine Benutzung dieser
      Anwendung werden in der Regel an einen Server von Google in den USA
      übertragen und dort gespeichert.
    </p>
    <p>
      Im Falle der Aktivierung der IP-Anonymisierung in dieser Anwendung wird
      deine IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der
      Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
      Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die
      volle IP-Adresse an einen Server von Google in den USA übertragen und dort
      gekürzt. Im Auftrag des Betreibers dieser Anwendung wird Google diese
      Informationen benutzen, um deine Nutzung der Anwendung auszuwerten, um
      Reports über die Aktivitäten zusammenzustellen und um weitere mit der
      Websitenutzung und der Internetnutzung verbundene Dienstleistungen
      gegenüber dem Betreiber der Anwendung zu erbringen. Die im Rahmen von
      Google Analytics von deinem Browser übermittelte IP-Adresse wird nicht mit
      anderen Daten von Google zusammengeführt.
    </p>
    <p>
      Weitere Informationen und die geltenden Datenschutzbestimmungen von Google
      (mit Informationen zur Erhebung, Verarbeitung und Nutzung von
      personenbezogenen Daten durch Google und deinen diesbezüglichen
      Schutzmöglichkeiten) kannst du unter
      https://www.google.de/intl/de/policies/privacy/ und unter
      http://www.google.com/analytics/terms/de.html abrufen. Google Analytics
      wird unter diesem Link https://www.google.com/intl/de_de/analytics/
      genauer erläutert.
    </p>
    <h3>Server-Log-Dateien</h3>{" "}
    <p>
      Der Provider der Seiten erhebt und speichert automatisch Informationen in
      so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
      übermittelt. Dies sind:
    </p>{" "}
    <ul>
      {" "}
      <li>Browsertyp und Browserversion</li> <li>verwendetes Betriebssystem</li>{" "}
      <li>Referrer URL</li> <li>Hostname des zugreifenden Rechners</li>{" "}
      <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>{" "}
    </ul>{" "}
    <p>
      Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
      vorgenommen.
    </p>{" "}
    <p>
      Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
      DSGVO. Der Anwendungsbetreiber hat ein berechtigtes Interesse an der
      technisch fehlerfreien Darstellung und der Optimierung seiner Anwendung –
      hierzu müssen die Server-Log-Files erfasst werden.
    </p>
    <h3>Anfrage per E-Mail </h3>{" "}
    <p>
      Wenn du uns per E-Mail kontaktierst, wird deine Anfrage inklusive aller
      daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke
      der Bearbeitung deines Anliegens bei uns gespeichert und verarbeitet.
      Diese Daten geben wir nicht ohne deine Einwilligung weiter.
    </p>{" "}
    <p>
      Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
      b DSGVO, sofern deine Anfrage mit der Erfüllung eines Vertrags
      zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
      erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
      Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und / oder auf unseren
      berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir ein
      berechtigtes Interesse an der effektiven Bearbeitung der an uns
      gerichteten Anfragen haben.
    </p>{" "}
    <p>
      Die von dir an uns per Kontaktanfragen übersandten Daten verbleiben bei
      uns, bis du uns zur Löschung auffordern, deine Einwilligung zur
      Speicherung widerrufst oder der Zweck für die Datenspeicherung entfällt
      (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
      gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen –
      bleiben unberührt.
    </p>
    <h3>Registrierung in der Lern-Anwendung</h3>{" "}
    <p>
      Du kannst dich registrieren, um das Lernkonto auf verschiedenen Geräten zu
      nutzen. Die dazu eingegebenen Daten verwenden wir nur zu diesem Zwecke.
      Die bei der Registrierung abgefragten Pflichtangaben müssen vollständig
      angegeben werden. Andernfalls werden wir die Registrierung ablehnen.
    </p>{" "}
    <p>
      Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch
      notwendigen Änderungen nutzen wir die bei der Registrierung angegebene
      E-Mail-Adresse, um dich auf diesem Wege zu informieren.
    </p>{" "}
    <p>
      Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf
      Grundlage deiner Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Du kannst eine
      von dir erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine
      formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bereits
      erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
    </p>{" "}
    <p>
      Die bei der Registrierung erfassten Daten werden von uns gespeichert,
      solange du in unserer Lern-Anwendung registriert bist und werden
      anschließend gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
    </p>
    <h3>Verarbeiten von Daten (Nutzerdaten in der Lern-Anwendung)</h3>{" "}
    <p>
      Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie
      für die Begründung, inhaltliche Ausgestaltung oder Änderung des
      Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt auf
      Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten
      zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
      Personenbezogene Daten über die Inanspruchnahme unserer Internetseiten
      (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies
      erforderlich ist, um dem Nutzer die Inanspruchnahme des Dienstes zu
      ermöglichen oder abzurechnen.
    </p>{" "}
    <p>Gesetzliche Aufbewahrungsfristen bleiben unberührt.</p>
    <h2>4. Weitergabe an Dritte</h2>
    <p>Wir werden die „personenbezogenen Daten“ unserer Nutzer nie verkaufen oder zu Werbezwecken an Dritte weitergeben. Im Gegenteil verwenden wir diese Daten nur in der in dieser Datenschutzerklärung beschrieben Art und Weise.</p>
    <h2>5. Newsletter</h2>
    <h3>Newsletterdaten</h3>{" "}
    <p>
      Wenn du den angebotenen Newsletter beziehen möchtest, benötigen wir von
      dir eine E-Mail-Adresse sowie Informationen, welche uns die Überprüfung
      gestatten, dass du der Inhaber der angegebenen E-Mail-Adresse bist und mit
      dem Empfang des Newsletters einverstanden bist. Weitere Daten werden nicht
      bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir
      ausschließlich für den Versand der angeforderten Informationen und geben
      diese nicht an Dritte weiter.
    </p>{" "}
    <p>
      Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten
      erfolgt ausschließlich auf Grundlage deiner Einwilligung (Art. 6 Abs. 1
      lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten, der
      E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters kann
      jederzeit widerrufen werden, etwa über den "Austragen"-Link im Newsletter.
      Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt
      vom Widerruf unberührt.
    </p>{" "}
    <p>
      Die von dir zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten
      werden von uns bis zu deiner Austragung aus dem Newsletter gespeichert und
      nach der Abbestellung des Newsletters gelöscht. Daten, die zu anderen
      Zwecken bei uns gespeichert wurden bleiben hiervon unberührt.
    </p>
  </>
)

export default Agreement
