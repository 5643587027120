import React, { Component } from "react"

import { StateMachineContext } from "contexts"

import { Loader } from "components/Assets"

class StudentExerciseExecuteLaneAdjustmentsComponent extends Component {
  render() {
    return <Loader />
  }
}

const StudentExerciseExecuteLaneAdjustments = () => (
  <StateMachineContext.Consumer>
    {props => <StudentExerciseExecuteLaneAdjustmentsComponent {...props} />}
  </StateMachineContext.Consumer>
)

export default StudentExerciseExecuteLaneAdjustments
