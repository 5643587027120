import React, { Component } from "react"

import { State } from "react-automata"
import { StateMachineContext } from "contexts"

import {
  StudentSignedOutLanding,
  StudentSignin,
  StudentSignup,
  StudentAnonymousSignup,
  HasValidCredentials,
  HasValidSignupData,
  HasValidAnonymousSignupData,
  AccountRecovery,
  IsAccountRecoverySuccessful,
} from "./index"

import PrivacyAgreement from "components/Assets/Overlay/PrivacyAgreement/PrivacyAgreement"

class StudentSignedOutComponent extends Component {
  render() {
    return (
      <>
        <State is={["*Landing", "*SignupAgreement", "*ParentalGate", "*ParentalGateFailure"]}>
          <StudentSignedOutLanding />
        </State>
        <State is={["*Signin", "*SigninFailure"]}>
          <StudentSignin />
        </State>
        <State is={["*SignupAgreement"]}>
          <PrivacyAgreement />
        </State>
        <State
          is={["*StudentSignup", "PrivacyAgreement", "*SignupFailure"]}
        >
          <StudentSignup />
        </State>
        <State is={["*AnonymousSignup", "*AnonymousSignupFailure"]}>
          <StudentAnonymousSignup />
        </State>
        <State is={["*HasValidCredentials?"]}>
          <HasValidCredentials />
        </State>
        <State is={["*HasValidSignupData?"]}>
          <HasValidSignupData />
        </State>
        <State is={["*HasValidAnonymousSignupData?"]}>
          <HasValidAnonymousSignupData />
        </State>
        <State is={["*AccountRecovery", "*AccountRecoveryFailure"]}>
          <AccountRecovery />
        </State>
        <State is={["*IsAccountRecoverySuccessful?"]}>
          <IsAccountRecoverySuccessful />
        </State>
      </>
    )
  }
}

const StudentSignedOut = () => (
  <StateMachineContext.Consumer>
    {props => <StudentSignedOutComponent {...props} />}
  </StateMachineContext.Consumer>
)

export default StudentSignedOut
