import StudentExercise from "statecharts/StudentExercise/StudentExercise.xsc"
import StudentQuestion from "statecharts/StudentQuestion/StudentQuestion.xsc"

export default {
  id: "StudentSignedIn",
  states: {
    SessionClose: {
      id: "SessionClose",
      states: {},
      on: {
        SESSION_CLOSED: "#StudentSignedOut",
      },
    },
    StudentSignedInOnboarding: {
      id: "StudentSignedInOnboarding",
      states: {},
      on: {
        GOTO_LANDING: "#StudentSignedInLanding",
      },
    },
    StudentSignedInLanding: {
      id: "StudentSignedInLanding",
      states: {},
      on: {
        GOTO_PROFILE: "#StudentProfile",
        GOTO_PROGRAM: "#StudentProgramStart",
        PROFILE_CHECK: "#StudentAnonymousProfileCheck",
        SESSION_CLOSE: "#SessionClose",
      },
    },
    StudentAnonymousProfile: {
      id: "StudentAnonymousProfile",
      states: {
        StudentAnonymousProfileView: {
          id: "StudentAnonymousProfileView",
          states: {},
          on: {
            PROFILE_UPDATE: "#StudentAnonymousProfileUpdate",
            PROFILE_CLOSE: "#StudentSignedInLanding",
          },
        },
        StudentAnonymousProfileCheck: {
          id: "StudentAnonymousProfileCheck",
          states: {},
          on: {
            OVERLAY_CLOSE: "#StudentSignedInLanding",
            PROFILE_UPDATE: "#StudentAnonymousProfileParentalGate",
            PROFILE_ERASE: "#SessionClose",
          },
        },
        StudentAnonymousProfileParentalGate: {
          id: "StudentAnonymousProfileParentalGate",
          states: {},
          on: {
            OVERLAY_CLOSE: "#StudentAnonymousProfileCheck",
            GATE_SUCCESS: "#StudentAnonymousProfileUpdate",
            GATE_FAILURE: "#StudentAnonymousProfileParentalGateFailure",
          },
        },
        StudentAnonymousProfileParentalGateFailure: {
          id: "StudentAnonymousProfileParentalGateFailure",
          states: {},
          on: {
            OVERLAY_CLOSE: "#StudentAnonymousProfileCheck",
            GATE_RETRY: "#StudentAnonymousProfileParentalGate",
          },
        },
        StudentAnonymousProfileUpdate: {
          id: "StudentAnonymousProfileUpdate",
          states: {},
          on: {
            OVERLAY_CLOSE: "#StudentSignedInLanding",
            PROFILE_UPDATE_CANCEL: "#StudentSignedInLanding",
            PROFILE_UPDATE_SUBMIT: "#StudentAnonymousProfileUpdateAgreement",
          },
        },
        "HasValidProfileUpdateData?": {
          id: "HasValidProfileUpdateData?",
          states: {},
          on: {
            ANONYMOUS_PROFILE_UPDATE_FAILURE:
              "#StudentAnonymousProfileUpdateFailure",
            ANONYMOUS_PROFILE_UPDATE_SUCCESS: "#StudentSignedInLanding",
            PROFILE_UPDATE_FAILURE: "#StudentProfileUpdateFailure",
            PROFILE_UPDATE_SUCCESS: "#StudentSignedInLanding",
          },
        },
        StudentAnonymousProfileUpdateAgreement: {
          id: "StudentAnonymousProfileUpdateAgreement",
          states: {},
          on: {
            AGREEMENT_ACCEPT: "#HasValidProfileUpdateData?",
            OVERLAY_CLOSE: "#StudentAnonymousProfileUpdateFailure",
          },
        },
        StudentAnonymousProfileUpdateFailure: {
          id: "StudentAnonymousProfileUpdateFailure",
          states: {},
          on: {
            PROFILE_UPDATE_CANCEL: "#StudentSignedInLanding",
            PROFILE_UPDATE_SUBMIT: "#StudentAnonymousProfileUpdateAgreement",
          },
        },
      },
      initial: "StudentAnonymousProfileView",
      on: {},
    },
    StudentProfile: {
      id: "StudentProfile",
      states: {
        StudentProfileView: {
          id: "StudentProfileView",
          states: {},
          on: {
            PROFILE_UPDATE: "#StudentProfileUpdate",
            PROFILE_CLOSE: "#StudentSignedInLanding",
          },
        },
        StudentProfileUpdate: {
          id: "StudentProfileUpdate",
          states: {},
          on: {
            PROFILE_UPDATE_CANCEL: "#StudentProfile",
            PROFILE_UPDATE_SUBMIT: "#HasValidProfileData?",
          },
        },
        "HasValidProfileData?": {
          id: "HasValidProfileData?",
          states: {},
          on: {
            PROFILE_UPDATE_FAILURE: "#StudentProfileUpdateFailure",
            PROFILE_UPDATE_SUCCESS: "#StudentProfile",
          },
        },
        StudentProfileUpdateFailure: {
          id: "StudentProfileUpdateFailure",
          states: {},
          on: {
            PROFILE_UPDATE_CANCEL: "#StudentProfile",
            PROFILE_UPDATE_SUBMIT: "#HasValidProfileData?",
          },
        },
      },
      initial: "StudentProfileView",
      on: {},
    },
    StudentProgram: {
      id: "StudentProgram",
      states: {
        "IsProgramActive?": {
          id: "IsProgramActive?",
          states: {},
          on: {
            PROGRAM_IS_ACTIVE: "#StudentProgramContinue",
            PROGRAM_IS_DONE: "#StudentProgramDone",
            PROGRAM_IS_NEW: "#StudentProgramStart",
          },
        },
        StudentProgramStart: {
          id: "StudentProgramStart",
          states: {},
          on: {
            EXERCISE_ENTER: "#StudentQuestion",
            GOTO_LANDING: "#StudentSignedInLanding",
          },
        },
        StudentProgramContinue: {
          id: "StudentProgramContinue",
          states: {},
          on: {},
        },
        StudentProgramDone: {
          id: "StudentProgramDone",
          states: {},
          on: {
            GOTO_PROFILE: "#StudentProfile",
            GOTO_LANDING: "#StudentSignedInLanding",
          },
        },
        StudentProgramRepeat: {
          id: "StudentProgramRepeat",
          states: {},
          on: {},
        },
      },
      initial: "IsProgramActive?",
      on: {
        PROGRAM_VERIFY: "#IsProgramActive?",
      },
    },
    StudentExercise: StudentExercise,
    StudentQuestion: StudentQuestion,
  },
  initial: "StudentSignedInOnboarding",
  on: {
    SESSION_CLOSED: "#StudentSignedOut",
  },
}
