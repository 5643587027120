import "./Header.scss"

import React from "react"
import FadeInOut from "components/Animations/FadeInOut"

export default props => (
  <FadeInOut>
    <div className="main-header w-100 position-fixed d-flex pb-2 px-2 border-bottom border-brainimals-topaz bg-backdrop-brainimals-aqua__pale">
      {props.children}
    </div>
  </FadeInOut>
)
