import "./StudentProgramStart.scss"
import "swiper/src/swiper.scss"

import { AuthenticationContext, StateMachineContext } from "contexts"
import React, { Component } from "react"

import Swiper from "react-id-swiper/lib/custom"

import { object } from "prop-types"

import cond from "ramda/src/cond"
import contains from "ramda/src/contains"
import complement from "ramda/src/complement"
import head from "ramda/src/head"
import length from "ramda/src/length"
import lte from "ramda/src/lte"
import isEmpty from "ramda/src/isEmpty"
import prop from "ramda/src/prop"
import T from "ramda/src/T"
import tail from "ramda/src/tail"
import uniqBy from "ramda/src/uniqBy"

import FadeInOut from "components/Animations/FadeInOut"
import ExerciseCard from "components/Chat/ExerciseCard/ExerciseCard"

import { Ground, Loader } from "components/Assets"

import { getProgramExercises, getProgramId } from "helpers/data/program"
import { analytics } from "helpers/tracking"
import { getHistoryExercisesByProgramId } from "helpers/data/student"

const notContains = complement(contains)

class StudentProgramStart extends Component {
  constructor(props) {
    super(props)

    this.swipeBackward = this.swipeBackward.bind(this)
    this.swipeForward = this.swipeForward.bind(this)
    this.slideTo = this.slideTo.bind(this)
  }
  static propTypes = {
    program: object,
    student: object,
  }

  state = {
    exercises: [],
    finishedExercises: [],
    program: null,
  }

  componentDidMount() {
    this.getProgramData()
  }

  async getProgramData() {
    const { program } = this.props
    const exercises = getProgramExercises(program)
    const historyExercises = await getHistoryExercisesByProgramId(
      getProgramId(program),
    )
    const finishedExercises = uniqBy(prop("id"), historyExercises)

    this.setState({ exercises, program, finishedExercises })

    analytics.page(null, "Program Start", {
      path: `/StudentProgramStart/${program.learning_program_id}`,
    })
  }

  componentDidUpdate() {
    if (this.owlReference) {
      this.owlReference.setAnimationType("move")
    }
    window.requestAnimationFrame(() => this.swiperReference.update())
  }

  swipeBackward() {
    this.swiperReference.slidePrev()
  }

  swipeForward() {
    this.swiperReference.slideNext()
  }

  slideTo = index => this.swiperReference.slideTo(index)

  onLockedCardNotificationClose = () => {
    const { finishedExercises } = this.state
    this.slideTo(finishedExercises.length)
  }

  render() {
    const { program, exercises, finishedExercises } = this.state
    const finishedExercisesIds = finishedExercises.map(ex => ex.id)
    const pristineExercises = exercises.filter(({ id }) =>
      notContains(id, finishedExercisesIds),
    )

    const currentExercise = isEmpty(pristineExercises) ? null : (
      <div>
        <ExerciseCard
          exerciseId={head(pristineExercises).id}
          program={program}
          finished={false}
        />
      </div>
    )

    const finishedExerciseList = finishedExercises.map((exercise, index) => {
      const exerciseReference = React.createRef()
      return (
        <div ref={exerciseReference} key={index}>
          <ExerciseCard
            exerciseId={exercise.id}
            program={program}
            finished={true}
          />
        </div>
      )
    })

    const pristineExerciseList = tail(pristineExercises).map(
      (exercise, index) => {
        const exerciseReference = React.createRef()
        return (
          <div ref={exerciseReference} key={index}>
            <ExerciseCard
              exerciseId={exercise.id}
              program={program}
              finished={false}
              onNotificationClose={this.onLockedCardNotificationClose}
              disabled
            />
          </div>
        )
      },
    )

    const exercisesCount = length(exercises)
    const finishedExercisesCount = length(finishedExercises)

    const initialSlide = cond([
      [lte(exercisesCount), () => exercisesCount - 1],
      [T, () => finishedExercisesCount],
    ])(finishedExercisesCount)

    const swiperBaseOptions = {
      centeredSlides: true,
      centerInsufficientSlides: true,
      initialSlide,
      iOSEdgeSwipeDetection: true,
      preventClicks: true,
      preventClicksPropagation: true,
      shouldSwiperUpdate: true,
      slideToClickedSlide: true,
      slidesPerView: "auto",
      renderPrevButton: () => (
        <div className={"program-content-move program-content-move__left"} />
      ),
      renderNextButton: () => (
        <div className={"program-content-move program-content-move__right"} />
      ),
      navigation: {
        prevEl: ".program-content-move__left",
        nextEl: ".program-content-move__right",
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
        renderBullet: (index, className) => `<button class="${className}" />`,
      },
    }

    return (
      <>
        {exercises.length ? (
          <FadeInOut>
            <div className={"h-100 pt-5 d-flex flex-column justify-content-center"}>
              <div className="my-auto">
                <Swiper
                  {...swiperBaseOptions}
                  ref={node =>
                    node ? (this.swiperReference = node.swiper) : null
                  }
                >
                  {finishedExerciseList}
                  {currentExercise}
                  {pristineExerciseList}
                </Swiper>
              </div>
            </div>
          </FadeInOut>
        ) : (
          <Loader />
        )}
        <Ground />
      </>
    )
  }
}

const StudentProgramStartContainer = () => (
  <AuthenticationContext.Consumer>
    {student => (
      <StateMachineContext.Consumer>
        {props => <StudentProgramStart {...props} student={student} />}
      </StateMachineContext.Consumer>
    )}
  </AuthenticationContext.Consumer>
)

export default StudentProgramStartContainer
