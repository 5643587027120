import React from "react"
import FadeInOut from "components/Animations/FadeInOut"
import { ProgressIndicator } from "components/Assets"

const StudentQuestionHeader = ({ currentProgress, totalProgress }) => (
  <FadeInOut>
    <div className="p-2">
      <div
        className="col-8 col-sm-8 col-lg-6 mx-auto mx-auto"
        style={{ width: "100%" }}
      >
        <ProgressIndicator current={currentProgress} total={totalProgress} />
      </div>
    </div>
  </FadeInOut>
)

export default StudentQuestionHeader
