import React, { Component } from "react"
import { func, string } from "prop-types"

import { StateMachineContext, AuthenticationContext } from "contexts"
import { signupWithEmailAndPassword } from "helpers/firebase/auth"
import { setupStudent, getDocumentById } from "helpers/firebase/firestore"
import { Loader } from "components/Assets"

class HasValidSignupData extends Component {
  static propTypes = {
    transition: func.isRequired,
    updateStudentSession: func.isRequired,
    inputEmail: string.isRequired,
    inputPassword: string.isRequired,
  }

  constructor(props) {
    super(props)

    const { inputEmail, inputPassword } = props
    this.handleSignup(inputEmail, inputPassword)
  }

  handleSignup = (inputEmail, inputPassword) => {
    const { transition, updateStudentSession } = this.props

    const fail = _ => transition("SIGNUP_FAILURE", _)
    const success = _ => {
      updateStudentSession(_)
      transition("SIGNUP_SUCCESS", _)
    }

    signupWithEmailAndPassword(inputEmail, inputPassword)
      .chain(setupStudent)
      .chain(({ data: { uid } }) => getDocumentById("school_students", uid))
      .fork(fail, success)
  }

  render() {
    return <Loader />
  }
}

const HasValidSignupDataEnhanced = () => (
  <AuthenticationContext.Consumer>
    {({ updateStudentSession }) => (
      <StateMachineContext.Consumer>
        {props => (
          <HasValidSignupData {...{ ...props, updateStudentSession }} />
        )}
      </StateMachineContext.Consumer>
    )}
  </AuthenticationContext.Consumer>
)

export default HasValidSignupDataEnhanced
