import classnames from "classnames"

import React, { Component } from "react"
import FadeInOut from "components/Animations/FadeInOut"

export default class Button extends Component {
  render = () => {
    const {
      children,
      delay,
      duration,
      from,
      to,
      provided,
      isDragging,
      ...props
    } = this.props
    const { className, disabled, type } = props

    const buttonClassname = classnames(
      "keyboard-button",
      `keyboard-button__${type}`,
      {
        className,
        disabled: disabled,
        "keyboard-button__enabled": !disabled,
        "keyboard-button__dragging": isDragging,
      },
    )

    if (provided) {
      return (
        <FadeInOut delay={delay} duration={duration} from={from} to={to}>
          <span
            disabled={props.disabled}
            onClick={props.onClick}
            className="p-1 rounded"
            {...props}
            style={{
              borderRadius: "100%",
              cursor: props.disabled ? "default" : "pointer",
            }}
          >
            <span
              data-value={props["data-value"]}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={buttonClassname}
            >
              {children}
            </span>
          </span>
        </FadeInOut>
      )
    } else {
      return (
        <FadeInOut delay={delay} duration={duration} from={from} to={to}>
          <span
            disabled={props.disabled}
            className="p-1 rounded"
            {...props}
            style={{
              borderRadius: "100%",
              cursor: props.disabled ? "default" : "pointer",
            }}
          >
            <span className={buttonClassname}>{children}</span>
          </span>
        </FadeInOut>
      )
    }
  }
}
