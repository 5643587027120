import ReactGA from "react-ga"
import cond from "ramda/src/cond"
import compose from "ramda/src/compose"
import not from "ramda/src/not"
import isNil from "ramda/src/isNil"

import { getDocumentById } from "helpers/firebase/firestore"
import { auth } from "helpers/firebase"
import { consoleError } from "helpers/generic"

const { NODE_ENV, REACT_APP_GA_TRACKING_ID, REACT_APP_CORDOVA } = process.env
const isProduction = NODE_ENV === "production"
const isNotNil = compose(
  not,
  isNil,
)

const handleAuthed = ({ uid }) => {
  getUser(uid)
}

const getUser = uid => {
  getDocumentById("school_students", uid).fork(
    () =>
      consoleError("ERROR LOADING STUDENT TO DETECT FOR ANALYTICS ENABLING"),
    student => {
      const unsubscribe = student.ref.onSnapshot(snap => {
        if (snap.data()) {
          ReactGA.set({ userId: uid })
        }
        unsubscribe()
      })
    },
  )
}

export const analytics = {
  init() {
    ReactGA.initialize(REACT_APP_GA_TRACKING_ID, {
      debug: !isProduction,
      titleCase: false,
      testMode: !isProduction,
    })
    ReactGA.set({ anonymizeIp: true })
    // Required to make ga work in Cordova environment
    // Without this the ga does not fire the events
    if (REACT_APP_CORDOVA) {
      ReactGA.set({ checkProtocolTask: null, checkStorageTask: null })
    }
    auth.onAuthStateChanged(cond([[isNotNil, handleAuthed]]))
  },
  // eslint-disable-next-line no-unused-vars
  page(category, name, properties, options, callback) {
    ReactGA.pageview(properties.path, null, name)
  },
  // eslint-disable-next-line no-unused-vars
  track(event, properties, options, callback) {
    ReactGA.event({
      ...properties,
      action: event,
    })
  },
}
