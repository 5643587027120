import React, { PureComponent } from "react"
import { number } from "prop-types"

import { Asset } from "components/Assets"

class ProgressIndicator extends PureComponent {
  state = {
    isMounted: false,
  }

  componentDidMount() {
    requestAnimationFrame(() => this.setState({ isMounted: true }))
  }

  getStartPercentage() {
    const { current, total } = this.props
    return ((current - 1) / total) * 100
  }

  getEndPercentage() {
    const { current, total } = this.props
    return (current / total) * 100
  }

  render() {
    const percent = this.state.isMounted
      ? this.getEndPercentage()
      : this.getStartPercentage()

    const progressStyle = {
      transform: `translate3d(calc(${percent}% - 16px), 0, 0)`,
    }

    return (
      <div className="progress-indicator">
        <div className="progress-indicator-icon" style={progressStyle}>
          <Asset type="svg" path="tree.green.light" />
        </div>
        <div className="progress" style={{ height: "6px" }}>
          <div
            className="progress-bar bg-brainimals-green-light"
            role="progressbar"
            data-progress={percent}
            style={{ width: `${percent}%` }}
            aria-valuenow={percent}
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
      </div>
    )
  }
}

ProgressIndicator.propTypes = {
  current: number.isRequired,
  total: number.isRequired,
}

export default ProgressIndicator
