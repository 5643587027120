import { StateMachineContext } from "contexts"
import { State } from "react-automata"

import React from "react"
import FadeInOut from "components/Animations/FadeInOut"

import { KeyboardMultiple, KeyboardNumpad } from "components/Exercise/Keyboards"
import NoticeAction from "../StudentQuestionNoticeAction/StudentQuestionNoticeAction"

import "./StudentQuestionFooter.scss"

const StudentQuestionFooter = ({
  addAnswer,
  eraseAnswer,
  evaluateAnswer,
  handleCalculateRewards,
  handleRetry,
  hasMultipleAnswers,
  optionList,
}) =>
  optionList ? (
    <div className="question-footer d-flex  flex-shrink-0 justify-content-around align-items-center bg-brainimals-dark-cyan py-2">
      <FadeInOut>
        <>
          <State
            is={[
              "*StudentQuestionAnswerEmpty",
              "*StudentQuestionAnswerNotEmpty",
              "*StudentQuestionAnswerComplete",
            ]}
          >
            <State is="*StudentQuestionTypeMultiple">
              <KeyboardMultiple
                addAnswer={addAnswer}
                eraseAnswer={eraseAnswer}
                hasMultipleAnswers={hasMultipleAnswers}
                optionList={optionList}
              />
            </State>
            <State
              is={["*StudentQuestionTypeNumpad", "*StudentQuestionTypeThze"]}
            >
              <KeyboardNumpad
                addAnswer={addAnswer}
                eraseAnswer={eraseAnswer}
                evaluateAnswer={evaluateAnswer}
              />
            </State>
          </State>
          <State is="*StudentQuestionErrorActive*">
            <State
              is={[
                "*StudentQuestionErrorActive1st",
                "*StudentQuestionErrorActive2nd",
              ]}
            >
              <NoticeAction action={handleRetry}>Nochmal!</NoticeAction>
            </State>
            <State is="*StudentQuestionErrorActive3rd">
              <NoticeAction type="secondary" action={handleCalculateRewards}>
                Nächste Aufgabe!
              </NoticeAction>
            </State>
          </State>
          <State is="*StudentQuestionSuccessActive">
            <NoticeAction type="secondary" action={handleCalculateRewards}>
              Weiter!
            </NoticeAction>
          </State>
        </>
      </FadeInOut>
    </div>
  ) : (
    <div className="question-footer d-flex  flex-shrink-0 justify-content-around align-items-center bg-brainimals-dark-cyan py-2" />
  )

export default componentProps => (
  <StateMachineContext.Consumer>
    {props => <StudentQuestionFooter {...componentProps} {...props} />}
  </StateMachineContext.Consumer>
)
