import React from "react"
import { func } from "prop-types"

import "./CloseButton.scss"

import { Asset } from "components/Assets"
import FadeInOut from "components/Animations/FadeInOut"

const CloseButton = props =>
  props.style ? (
    <span
      className="close-button zindex-action env-padding-top env-padding-right"
      {...props}
    >
      <Asset type="svg" path="close" />
    </span>
  ) : (
    <FadeInOut>
      <span
        className="close-button zindex-action env-padding-top env-padding-right"
        {...props}
      >
        <Asset type="svg" path="close" />
      </span>
    </FadeInOut>
  )

CloseButton.propTypes = {
  onClick: func.isRequired,
}

export default CloseButton
