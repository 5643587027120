import "./App.scss"

import React, { Component } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"

import classnames from "classnames"
import { Offline } from "react-detect-offline"

import { State } from "react-automata"
import {
  withAuthentication,
  withEnvironment,
  withStateMachine,
} from "components/hocs"
import { withWindow } from "react-window-decorators"

import compose from "ramda/src/compose"

import ApplicationLanding from "./ApplicationLanding/ApplicationLanding"
import { UserContextProvider } from "contexts/UserContext"

import StudentHasSession from "components/StudentHasSession/StudentHasSession"
import StudentSignedIn from "components/StudentSignedIn/StudentSignedIn"
import StudentSignedOut from "components/StudentSignedOut/StudentSignedOut"

import Stats from "components/Stats/Stats"
import DataPolicy from "components/DataPolicy/DataPolicy"

import Imprint from "components/Assets/Overlay/Imprint/Imprint"
import ParentalGate from "components/Assets/Overlay/ParentalGate/ParentalGate"
import PrivacyAgreement from "components/Assets/Overlay/PrivacyAgreement/PrivacyAgreement"

import OrientationWarning from "components/Assets/OrientationWarning/OrientationWarning"
import ConnectionWarning from "components/Assets/ConnectionWarning/ConnectionWarning"
import FadeInOut from "components/Animations/FadeInOut"

import { analytics } from "helpers/tracking"

import { Provider } from "react-redux"
import { createStore } from "redux"
import { devToolsEnhancer } from "redux-devtools-extension"

import { SizeMe } from "react-sizeme"
import Confetti from "react-confetti"

const store = createStore(state => state, devToolsEnhancer())

analytics.init()

class Application extends Component {
  render() {
    const { isTouchDevice, orientation } = this.props

    const isInteractive =
      orientation === "portrait" ||
      (orientation === "landscape" && !isTouchDevice)

    const orientationWarningClassname = classnames({
      invisible: isInteractive,
      visible: !isInteractive,
    })

    return (
      <UserContextProvider>
        <BrowserRouter>
          <Provider store={store}>
            <Switch>
              <Route path="/data-policy" component={DataPolicy} />
              <Route path="/datenschutz" component={DataPolicy} />
              <Route path="/stats" component={Stats} />
              <Route
                path="/"
                render={() => (
                  <>
                    <SizeMe monitorHeight>
                      {({ size }) => (
                        <div className="position-absolute h-100 w-100">
                          <State is={["*StudentProgramDone"]}>
                            <FadeInOut>
                              <Confetti
                                numberOfPieces={200}
                                gravity={0.25}
                                recycle={false}
                                {...size}
                              />
                            </FadeInOut>
                          </State>
                        </div>
                      )}
                    </SizeMe>

                    <State
                      is={[
                        "*StudentSignedInLanding*",
                        "*StudentProgram*",
                        "*StudentExercise*",
                        "*StudentQuestion*",
                        "*StudentOnboarding*",
                      ]}
                    >
                      <OrientationWarning
                        className={orientationWarningClassname}
                      />
                    </State>

                    <Offline
                      polling={{
                        url: "https://icanhazepoch.com",
                        interval: 10000,
                      }}
                    >
                      <ConnectionWarning />
                    </Offline>

                    <State is={["*Overlay.Imprint"]}>
                      <Imprint />
                    </State>
                    <State is={["*Overlay.DataPrivacy"]}>
                      <PrivacyAgreement />
                    </State>
                    <State is={["*ParentalGate", "*ParentalGateFailure"]}>
                      <ParentalGate />
                    </State>

                    <div className={"d-flex flex-column h-100"}>
                      <State is={["*ApplicationLanding*"]}>
                        <ApplicationLanding />
                      </State>
                      <State is={["*StudentHasSession?"]}>
                        <StudentHasSession />
                      </State>
                      <State is={["*StudentSignedOut*"]}>
                        <StudentSignedOut />
                      </State>
                      <State is={["*StudentSignedIn*"]}>
                        <StudentSignedIn />
                      </State>
                    </div>
                  </>
                )}
              />
            </Switch>
          </Provider>
        </BrowserRouter>
      </UserContextProvider>
    )
  }
}

export default compose(
  withAuthentication,
  withEnvironment,
  withStateMachine,
  withWindow,
)(Application)
