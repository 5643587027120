import { State } from "react-automata"
import { EnvironmentContext, StateMachineContext } from "contexts"

import FadeInOut from "components/Animations/FadeInOut"

import React, { Component } from "react"
import { Alert, Button, Container, Col } from "reactstrap"
import { Loader } from "components/Assets"

import { getTranslation } from "helpers/translations"
import { analytics } from "helpers/tracking"

export const generateAnonymousAccountEmail = reference =>
  `${String(reference)}.anonymous-account@brainimals.de`

class StudentAnonymousSignupComponent extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmit()
  }

  state = {
    accountReference: Date.now(),
  }

  handleSubmit = () =>
    window.requestAnimationFrame(() =>
      this.props.transition("ANONYMOUS_SIGNUP_SUBMIT", {
        inputEmail: generateAnonymousAccountEmail(this.state.accountReference),
        inputPassword: String(this.state.accountReference),
      }),
    )

  componentDidMount() {
    analytics.page(null, "Anonymous Signup", {
      path: "/StudentAnonymousSignup",
    })
  }

  render() {
    const { code, language } = this.props
    const getSignupErrorTranslation = getTranslation(language, "errors")

    return (
      <FadeInOut>
        <Container
          fluid
          className="d-flex align-items-center justify-content-center main-container"
        >
          <Col sm={6} className="d-flex">
            <State is="*AnonymousSignupFailure">
              <Alert className="text-center font-size-sm p-2" color="warning">
                {getSignupErrorTranslation(code)}
              </Alert>
              <Button
                block
                color="dark"
                onClick={this.handleReturn}
                type="button"
              >
                Nochmal versuchen
              </Button>
            </State>
            <State is="*AnonymousSignup">
              <Loader />
            </State>
          </Col>
        </Container>
      </FadeInOut>
    )
  }
}

const StudentAnonymousSignup = () => (
  <EnvironmentContext.Consumer>
    {environmentProps => (
      <StateMachineContext.Consumer>
        {stateMachineProps => (
          <StudentAnonymousSignupComponent
            {...environmentProps}
            {...stateMachineProps}
          />
        )}
      </StateMachineContext.Consumer>
    )}
  </EnvironmentContext.Consumer>
)

export default StudentAnonymousSignup
