import React, { Component } from "react"
import { func, object } from "prop-types"

import { State } from "react-automata"
import { AuthenticationContext, StateMachineContext } from "contexts"

import { auth } from "helpers/firebase"
import { BackButton, ProgressIcon } from "components/Assets"

import cond from "ramda/src/cond"
import isNil from "ramda/src/isNil"
import equals from "ramda/src/equals"
import T from "ramda/src/T"

import {
  StudentAnonymousProfile,
  // StudentProfile,
  HasValidProfileUpdateData,
  StudentExercise,
  StudentSignedInLanding,
  StudentSignedInOnboarding,
  StudentProgram,
  StudentQuestion,
} from "./index"

import { LogoutButton, Notification } from "components/Assets"
import Header from "components/Header/Header"

import PrivacyAgreement from "components/Assets/Overlay/PrivacyAgreement/PrivacyAgreement"

class StudentSignedInComponent extends Component {
  static propTypes = {
    transition: func.isRequired,
    student: object.isRequired,
  }

  constructor(props) {
    super(props)
    auth.onAuthStateChanged(cond([[isNil, this.handleCloseSession]]))
  }

  handleCloseSession = () => this.props.transition("SESSION_CLOSED")

  handleReturnToLanding = () => this.props.transition("GOTO_LANDING")

  handleProfileCheck = () => this.props.transition("PROFILE_CHECK")

  handleSignout = () => {
    const { student } = this.props
    cond([[equals(true), this.handleProfileCheck], [T, student.signOut]])(
      student.isAnonymous(),
    )
  }

  render() {
    return (
      <>
        <State is={["*StudentProgramStart", "*StudentSignedInLanding*"]}>
          <Header>
            <div className="d-flex flex-grow-1 align-items-center">
              <ProgressIcon />
            </div>
            <State is={["*StudentSignedInLanding*"]}>
              <LogoutButton onClick={this.handleSignout} />
            </State>
            <State is={["*StudentProgramStart"]}>
              <BackButton onClick={this.handleReturnToLanding} />
            </State>
          </Header>
        </State>
        <State
          is={[
            "*StudentAnonymousProfile*",
            "*ParentalGate",
            "*ParentalGateFailure",
          ]}
        >
          <StudentAnonymousProfile />
        </State>
        {/* <State is={["*StudentProfile*"]}>
          <StudentProfile />
        </State> */}
        <State is={["*Notification*"]}>
          <Notification />
        </State>
        <State is={["*ProfileUpdateAgreement"]}>
          <PrivacyAgreement />
        </State>
        <State is={["*HasValidProfileUpdateData?"]}>
          <HasValidProfileUpdateData />
        </State>
        <State is={["*Onboarding"]}>
          <StudentSignedInOnboarding />
        </State>
        <State is={["*Landing", "*Student*Profile*"]}>
          <StudentSignedInLanding />
        </State>
        <State is={["*StudentProgram.*"]}>
          <StudentProgram />
        </State>
        <State is={["*StudentExercise.*"]}>
          <StudentExercise />
        </State>
        <State is={["*StudentExercise.*", "*StudentQuestion.*"]}>
          <StudentQuestion />
        </State>
      </>
    )
  }
}

const StudentSignedIn = () => (
  <AuthenticationContext.Consumer>
    {student => (
      <StateMachineContext.Consumer>
        {props => <StudentSignedInComponent {...props} {...{ student }} />}
      </StateMachineContext.Consumer>
    )}
  </AuthenticationContext.Consumer>
)

export default StudentSignedIn
