const {
  REACT_APP_FS_API_KEY: apiKey,
  REACT_APP_FS_AUTH_DOMAIN: authDomain,
  REACT_APP_FS_DATABASE_URL: databaseURL,
  REACT_APP_FS_PROJECT_ID: projectId,
  REACT_APP_FS_STORAGE_BUCKET: storageBucket,
  REACT_APP_FS_MESSAGING_SENDER_ID: messagingSenderId,
} = process.env

const config = {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
}

export default config
