import React from "react"

import Markdown from "react-markdown"
import Bubble from "components/Chat/Bubble/Bubble"

import equals from "ramda/src/equals"

const StudentQuestionTip = ({ children }) => (
  <Bubble type={"tip"} title={"Mein Tipp"}>
    <div className={"bubble__tip-content px-3"}>
      {equals("object", typeof children) ? (
        children
      ) : (
        <Markdown escapeHtml={false}>
          {decodeURIComponent(children.replace("src='/", "src='"))}
        </Markdown>
      )}
    </div>
  </Bubble>
)

export default StudentQuestionTip
