import React from "react"
import "./OrientationWarning.scss"

import { Asset } from "components/Assets"

const OrientationWarning = ({ className }) => (
  <div
    className={`d-flex flex-column align-items-center justify-content-center h-100 w-100 bg-brainimals-aqua px-3 env-padding ${className}`}
    style={{ zIndex: 2999 }}
  >
    <Asset path="devices.landscape" type="svg" />
    <p className="orientation-warning">
      Bitte drehe deinen Bildschirm, sonst fallen die Bäume um…
    </p>
  </div>
)

export default OrientationWarning
