import React, { Component } from "react"
import { func, string } from "prop-types"

import { StateMachineContext, AuthenticationContext } from "contexts"

import {
  reauthenticate,
  updateFirebaseEmail,
  updateEmail,
  updatePassword,
} from "helpers/firebase/auth"
import { getDocumentById } from "helpers/firebase/firestore"

import { Loader } from "components/Assets"

import compose from "ramda/src/compose"
import cond from "ramda/src/cond"
import curry from "ramda/src/curry"
import equals from "ramda/src/equals"
import head from "ramda/src/head"
import split from "ramda/src/split"
import T from "ramda/src/T"

class HasValidProfileUpdateData extends Component {
  static propTypes = {
    transition: func.isRequired,
    currentPassword: string,
    newEmail: string.isRequired,
    newPassword: string.isRequired,
  }

  constructor(props) {
    super(props)

    const { newEmail, newPassword, student } = props
    const { _raw: studentRecord } = student

    const isAnonymous = student.isAnonymous()
    const studentEmail = student.getStudentEmail()

    const studentPassword = cond([
      [
        equals(true),
        () =>
          compose(
            head,
            split("."),
          )(studentEmail),
      ],
      [T, () => this.props.currentPassword],
    ])(isAnonymous)

    reauthenticate(studentEmail, studentPassword)
      .chain(updateFirebaseEmail(newEmail))
      .chain(updateEmail(studentRecord, newEmail))
      .chain(updatePassword(newPassword))
      .chain(() => getDocumentById("school_students", student.getStudentUid()))
      .map(student.updateStudentSession)
      .fork(
        this.handleFailure({ isAnonymous }),
        this.handleSuccess({ isAnonymous }),
      )
  }

  handleFailure = curry(({ isAnonymous }, _) =>
    cond([
      [
        equals(true),
        () => this.props.transition("ANONYMOUS_PROFILE_UPDATE_FAILURE", _),
      ],
      [T, () => this.props.transition("PROFILE_UPDATE_FAILURE", _)],
    ])(isAnonymous),
  )

  handleSuccess = curry(({ isAnonymous }, _) =>
    cond([
      [
        equals(true),
        () => this.props.transition("ANONYMOUS_PROFILE_UPDATE_SUCCESS", _),
      ],
      [T, () => this.props.transition("PROFILE_UPDATE_SUCCESS", _)],
    ])(isAnonymous),
  )

  render() {
    return <Loader />
  }
}

const HasValidProfileUpdateDataEnhanced = ownProps => (
  <AuthenticationContext.Consumer>
    {student => (
      <StateMachineContext.Consumer>
        {props => (
          <HasValidProfileUpdateData
            {...props}
            {...ownProps}
            student={student}
          />
        )}
      </StateMachineContext.Consumer>
    )}
  </AuthenticationContext.Consumer>
)

export default HasValidProfileUpdateDataEnhanced
