import { EnvironmentContext, StateMachineContext } from "contexts"
import { State } from "react-automata"

import FadeInOut from "components/Animations/FadeInOut"

import React, { Component } from "react"
import { Alert, Button, Form, Label, Input } from "reactstrap"

import { getTranslation } from "helpers/translations"

class AccountRecoveryComponent extends Component {
  constructor(props) {
    super(props)

    this.state = { inputEmail: "" }
  }

  handleInputChange = event => {
    const target = event.target

    const value = target.type === "checkbox" ? target.checked : target.value

    const name = target.name

    this.setState({ [name]: value })
  }

  handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()

    this.props.transition("RECOVERY_SUBMIT", this.state)
  }

  handleReturn = () => {
    this.props.transition("RECOVERY_CANCEL")
  }

  render() {
    const { code, language } = this.props
    const getSignupErrorTranslation = getTranslation(language, "errors")

    return (
      <FadeInOut>
        <div className="d-flex flex-column h-100 overflow-y-scroll">
          <div className="d-flex flex-grow-1 flex-shrink-0 flex-basis-auto align-content-center">
            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-4 mx-auto landing-form my-auto">
              <div className="d-flex flex-column justify-content-center h-100">
                <Form className="form-signin" onSubmit={this.handleSubmit}>
                  <div className="text-center text-brainimals-dark-grey-blue">
                    <h1 className="h3 mb-3 font-weight-normal text-brainimals-dark">
                      Passwort zurücksetzen
                    </h1>
                    <p>Bitte gib deine E-Mail-Adresse ein und wir senden dir einen Link, um dein Passwort zu erneuern.</p>
                    <p>Das kann einen Moment dauern – bitte warte kurz.</p>
                    <p>Schaue auch sicherheitshalber in dem Werbung-Postfach nach.</p>
                    <br/>
                  </div>

                  <div className="form-label-group">
                    <Input
                      type="email"
                      bsSize="lg"
                      placeholder="username"
                      id="inputEmail"
                      name="inputEmail"
                      onChange={this.handleInputChange}
                      required
                      autoFocus
                    />
                    <Label htmlFor="inputEmail">E-Mail</Label>
                  </div>

                  <State is="*RecoveryFailure">
                    <Alert className="text-center font-size-sm p-2" color="warning">
                      {getSignupErrorTranslation(code)}
                    </Alert>
                  </State>

                  <Button block color="primary" type="submit" size="lg">
                    Absenden
                  </Button>
                  <Button
                    block
                    color="light"
                    onClick={this.handleReturn}
                    size="lg"
                    type="button"
                  >
                    Zurück
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </FadeInOut>
    )
  }
}

const AccountRecovery = () => (
  <EnvironmentContext.Consumer>
    {environmentProps => (
      <StateMachineContext.Consumer>
        {stateMachineProps => (
          <AccountRecoveryComponent {...environmentProps} {...stateMachineProps} />
        )}
      </StateMachineContext.Consumer>
    )}
  </EnvironmentContext.Consumer>
)

export default AccountRecovery
