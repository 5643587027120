import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import cond from "ramda/src/cond"
import equals from "ramda/src/equals"
import T from "ramda/src/T"
import always from "ramda/src/always"
import keys from "ramda/src/keys"
import compose from "ramda/src/compose"
import head from "ramda/src/head"
import bind from "ramda/src/bind"
import map from "ramda/src/map"
import addIndex from "ramda/src/addIndex"
import concat from "ramda/src/concat"

import "./Stats.css"
import { retrieveUserAuthentication } from "helpers/firebase/auth"
import { getStatistics } from "helpers/firebase/firestore"

class Stats extends Component {
  state = {
    authed: -1,
    stats: [],
  }

  componentDidMount() {
    retrieveUserAuthentication.fork(
      () => this.setState({ authed: 0 }),
      () => {
        this.loadStats()
        this.setState({ authed: 1 })
      },
    )
  }

  async loadStats() {
    const questions = await getStatistics("AS01")
    const stats = questions.map(q => ({
      attempts: q.history_question_attempts,
      helpCount: q.history_question_help_count,
      points: q.points,
      possiblePoints: q.history_question_points_possible,
      type: q.history_question_type,
    }))
    this.setState({ stats })
  }

  renderStats() {
    const { stats } = this.state
    if (!stats.length) return false
    const cols = compose(
      keys,
      head,
    )(this.state.stats)
    return (
      <div className="statsContainer overflow-y-scroll">
        <table className="statsTable">
          <thead>
            <tr>
              {map(
                col => (
                  <th key={col}>{col}</th>
                ),
                cols,
              )}
            </tr>
          </thead>
          <tbody>
            {addIndex(map)(
              (row, n) => (
                <tr key={n}>
                  {addIndex(map)(
                    (col, m) => (
                      <td key={concat(String(n), String(m))}>{row[col]}</td>
                    ),
                    cols,
                  )}
                </tr>
              ),
              stats,
            )}
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    return cond([
      [equals(-1), always(<div>...</div>)],
      [equals(0), always(<Redirect to="/" />)],
      [T, bind(this.renderStats, this)],
    ])(this.state.authed)
  }
}

export default Stats
