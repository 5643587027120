import React from "react"
import { StateMachineContext } from "contexts"
import { withStateMachine } from "react-automata"

import root from "statecharts/root.xsc"
import compose from "ramda/src/compose"
import cond from "ramda/src/cond"
import equals from "ramda/src/equals"
import not from "ramda/src/not"

const StateMachineContainer = Component =>
  class WithStateMachine extends React.Component {
    debugMachineState = () => {
      console.debug(this.props.machineState.value)
    }

    render() {
      window.machineState = this.props.machineState
      cond([
        [
          compose(
            not,
            equals("production"),
          ),
          this.debugMachineState,
        ],
      ])(process.env.REACT_APP_ENVIRONMENT)

      return (
        <StateMachineContext.Provider value={this.props}>
          <Component />
        </StateMachineContext.Provider>
      )
    }
  }

export default compose(
  withStateMachine(root, { devTools: true }),
  StateMachineContainer,
)
