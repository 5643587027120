import React, { Component } from "react"
import { StateMachineContext, AuthenticationContext } from "contexts"

import { Loader } from "components/Assets"

import { getExerciseQuestionsForLevel } from "helpers/data/exercise"

import concat from "ramda/src/concat"
import converge from "ramda/src/converge"
import reduce from "ramda/src/reduce"
import compose from "ramda/src/compose"

class StudentExerciseSetupComponent extends Component {
  constructor(props) {
    super(props)

    const { exercise, program, student, transition } = props

    const mergeExercises = compose(
      reduce(concat, []),
      Array.of,
    )

    const studentDifficultyLevel = student.getStudentDifficultyLevel()

    const questionList = converge(mergeExercises, [
      getExerciseQuestionsForLevel(studentDifficultyLevel, 1),
      getExerciseQuestionsForLevel(studentDifficultyLevel, 2),
      getExerciseQuestionsForLevel(studentDifficultyLevel, 3),
    ])(exercise)

    window.requestAnimationFrame(() =>
      transition("SETUP_COMPLETE", { exercise, questionList, program }),
    )
  }
  render() {
    return <Loader />
  }
}

const StudentExerciseSetup = () => (
  <AuthenticationContext.Consumer>
    {student => (
      <StateMachineContext.Consumer>
        {props => (
          <StudentExerciseSetupComponent {...props} student={student} />
        )}
      </StateMachineContext.Consumer>
    )}
  </AuthenticationContext.Consumer>
)

export default StudentExerciseSetup
