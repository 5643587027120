import { auth, EmailAuthProvider } from "./index"
import { updateStudent } from "helpers/data/student"
import Async from "crocks/Async"

import compose from "ramda/src/compose"
import curry from "ramda/src/curry"
import cond from "ramda/src/cond"
import isNil from "ramda/src/isNil"
import not from "ramda/src/not"
import T from "ramda/src/T"

export const retrieveUserAuthentication = Async((failure, success) =>
  auth.onAuthStateChanged(
    cond([
      [
        compose(
          not,
          isNil,
        ),
        success,
      ],
      [T, failure],
    ]),
  ),
)

export const sendPasswordResetEmail = inputEmail =>
  Async((failure, success) =>
    auth
      .sendPasswordResetEmail(inputEmail)
      .then(success)
      .catch(failure),
  )

export const signInWithEmailAndPassword = curry((inputEmail, inputPassword) =>
  Async((failure, success) =>
    auth
      .signInWithEmailAndPassword(inputEmail, inputPassword)
      .then(success)
      .catch(failure),
  ),
)

export const signupWithEmailAndPassword = curry((inputEmail, inputPassword) =>
  Async((failure, success) =>
    auth
      .createUserWithEmailAndPassword(inputEmail, inputPassword)
      .then(success)
      .catch(failure),
  ),
)

export const reauthenticate = curry((userEmail, userPassword) =>
  Async((failure, success) => {
    const credential = EmailAuthProvider.credential(userEmail, userPassword)

    auth.currentUser
      .reauthenticateAndRetrieveDataWithCredential(credential)
      .then(success)
      .catch(failure)
  }),
)

export const updateFirebaseEmail = inputEmail => () =>
  Async((failure, success) =>
    auth.currentUser
      .updateEmail(inputEmail)
      .then(success)
      .catch(failure),
  )

export const updateEmail = curry((studentRecord, inputEmail) => () =>
  updateStudent(studentRecord, {
    student_email: inputEmail,
    student_is_anonymous: false,
  }),
)

export const updatePassword = inputPassword => () =>
  Async((failure, success) =>
    auth.currentUser
      .updatePassword(inputPassword)
      .then(success)
      .catch(failure),
  )
