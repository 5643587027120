import firebaseConfig from "config/firebase"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"

let firebaseApp

if (!firebase.apps.length) {
  firebaseApp = firebase.initializeApp(firebaseConfig)
  firebase.firestore().settings({ timestampsInSnapshots: true })
}

export const auth = firebase.auth()
export const EmailAuthProvider = firebase.auth.EmailAuthProvider
window.auth = auth

export const firestore = firebase.firestore()
window.firebase = firebase
window.firestore = firestore

export const functions = firebaseApp.functions("europe-west1")
