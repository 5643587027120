import React from "react"
import { EnvironmentContext } from "contexts"

export default Component =>
  class WithEnvironment extends React.Component {
    state = { language: (navigator && navigator.language) || "de-DE" }
    render = () => (
      <EnvironmentContext.Provider value={this.state}>
        <Component />
      </EnvironmentContext.Provider>
    )
  }
