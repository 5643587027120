import React from "react"
import { func } from "prop-types"

import "./BackButton.scss"

import { Asset } from "components/Assets"
import FadeInOut from "components/Animations/FadeInOut"

const BackButton = ({ onClick }) => (
  <FadeInOut>
    <span className="back-button d-flex align-items-center" {...{ onClick }}>
      <Asset type="svg" path="button_back" />
    </span>
  </FadeInOut>
)

BackButton.propTypes = {
  onClick: func.isRequired,
}

export default BackButton
