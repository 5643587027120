import React from "react"

import "./DataPolicy.css"
import Agreement from "components/StudentSignupAgreement/Agreement"

const DataPolicy = () =>
  <div className="d-flex flex-column h-100 w-100 bg-backdrop-brainimals-deep-sea-blue position-absolute zindex-backdrop fixed-top">
    <div>
      <div className="col-11 col-sm-10 col-lg-8 mx-auto p-0">
        <h2 className="overlay-title text-brainimals-green-light pt-4">
          Datenschutzerklärung
        </h2>
      </div>
    </div>
    <div className="overlay-content h-100 align-content-center overflow-y-scroll">
      <div className="col-11 col-sm-10 col-lg-8 mx-auto p-0">
        <div className="d-flex flex-column h-100">
          <div className="text-white overlay-copy">
            <Agreement />
          </div>
        </div>
      </div>
    </div>
    <div className="py-3" />
  </div>

export default DataPolicy
