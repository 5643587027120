import compose from "ramda/src/compose"
import cond from "ramda/src/cond"
import curry from "ramda/src/curry"
import equals from "ramda/src/equals"
import not from "ramda/src/not"

export const noop = () => {}

export const logger = curry((type, label) => (...values) =>
  cond([
    [
      compose(
        not,
        equals("production"),
      ),
      () => console[type](label, ...values), // eslint-disable-line no-console
    ],
  ])(process.env.REACT_APP_ENVIRONMENT),
)

export const callDebugger = value => {
  debugger // eslint-disable-line no-debugger
  return value
}

export const consoleDebug = logger("debug")
export const consoleError = logger("error")
export const consoleInfo = logger("info")
export const consoleLog = logger("log")
export const consoleTable = logger("table")
