import "./Notification.scss"

import React, { PureComponent } from "react"
import { bool, func, string, object, shape, number } from "prop-types"
import { Transition } from "react-spring"

import { StateMachineContext } from "contexts"
import { State } from "react-automata"
import { Asset, CloseButton } from "components/Assets"

class NotificationComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.handleCloseNotification = this.handleCloseNotification.bind(this)
  }

  state = {
    isReadyToExit: false,
  }

  static defaultProps = {
    onClosePayload: {},
    exitDelay: 2000,
    notificationHeadline: "Headline",
    message: "Message...",
    icon: "owl.default",
    isDismissable: true,
  }

  static propTypes = {
    transition: func,
    exitDelay: number,
    notificationHeadline: string,
    icon: string,
    isDismissable: bool,
    message: string,
    styles: object,
    onCloseCallback: func,
    onClosePayload: shape({ code: string }),
  }

  handleCloseNotification = () => {
    const { onCloseCallback, onClosePayload, transition } = this.props

    if (this.state.isReadyToExit) {
      transition("NOTIFICATION_CLOSE", onClosePayload)

      if (onCloseCallback) {
        onCloseCallback()
      }
    }
  }

  componentDidMount() {
    setTimeout(
      () =>
        this.setState({
          isReadyToExit: true,
        }),
      this.props.exitDelay,
    )
  }

  render() {
    const { notificationHeadline, message, icon, isDismissable } = this.props

    return (
      <State is={["*.NotificationOpen"]}>
        <>
          <Transition
            delay={500}
            from={{ opacity: 0 }}
            enter={{ opacity: 1 }}
            leave={{ opacity: 0 }}
          >
            {backdropStyle => (
              <div
                className={"position-fixed zindex-backdrop"}
                onClick={this.handleCloseNotification}
                style={{
                  ...backdropStyle,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  top: 0,
                  backgroundColor: "rgba(0,0,0,0.25)",
                }}
              />
            )}
          </Transition>
          <Transition
            from={{ opacity: 0, marginTop: "-100%" }}
            enter={{ opacity: 1, marginTop: "0%" }}
            leave={{ opacity: 0, marginTop: "-100%" }}
          >
            {notificationStyle => (
              <div
                style={notificationStyle}
                className="notification notification-brainimals-topaz w-100 zindex-backdrop env-padding-top"
              >
                {isDismissable && (
                  <Transition
                    delay={500}
                    from={{ opacity: 0 }}
                    enter={{ opacity: 1 }}
                    leave={{ opacity: 0 }}
                  >
                    {closeButtonStyles => (
                      <CloseButton
                        onClick={this.handleCloseNotification}
                        style={closeButtonStyles}
                      />
                    )}
                  </Transition>
                )}
                <div className={"col-11 d-flex flex-row mt-4 px-0"}>
                  <div className={"pr-2"}>
                    {icon && (
                      <Asset
                        path={icon}
                        className="notification-icon"
                        type="svg"
                      />
                    )}
                  </div>
                  <div>
                    {message ? (
                      <>
                        <h6
                          className={"text-brainimals-white font-weight-bold"}
                        >
                          {notificationHeadline}
                        </h6>
                        <p>{message}</p>
                      </>
                    ) : (
                      <h6
                        className={
                          "text-brainimals-white font-weight-bold my-3"
                        }
                      >
                        {notificationHeadline}
                      </h6>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Transition>
        </>
      </State>
    )
  }
}

const Notification = ownProps => (
  <StateMachineContext.Consumer>
    {stateMachine => <NotificationComponent {...stateMachine} {...ownProps} />}
  </StateMachineContext.Consumer>
)

export default Notification
