export default {
  id: "StudentSignedOut",
  states: {
    StudentSigninFailure: {
      id: "StudentSigninFailure",
      states: {},
      on: {
        ACCOUNT_RECOVER: "#AccountRecovery",
        SIGNIN_CANCEL: "#StudentSignedOut",
        SIGNIN_SUBMIT: "#HasValidCredentials?",
      },
    },
    StudentSignedOutLanding: {
      id: "StudentSignedOutLanding",
      states: {},
      on: {
        ACCOUNT_ANONYMOUS_CREATE: "#StudentAnonymousSignup",
        ACCOUNT_CREATE: "#StudentSignupParentalGate",
        ACCOUNT_SIGNIN: "#StudentSignin",
      },
    },
    "HasValidCredentials?": {
      id: "HasValidCredentials?",
      states: {},
      on: {
        CREDENTIALS_FAILURE: "#StudentSigninFailure",
        CREDENTIALS_SUCCESS: "#StudentSignedIn",
      },
    },
    "HasValidSignupData?": {
      id: "HasValidSignupData?",
      states: {},
      on: {
        SIGNUP_FAILURE: "#StudentSignupFailure",
        SIGNUP_SUCCESS: "#StudentSignedIn",
      },
    },
    AccountRecoveryFailure: {
      id: "AccountRecoveryFailure",
      states: {},
      on: {
        RECOVERY_CANCEL: "#StudentSignedIn",
        RECOVERY_SUBMIT: "#IsAccountRecoverySuccessful?",
      },
    },
    StudentSignin: {
      id: "StudentSignin",
      states: {},
      on: {
        ACCOUNT_RECOVER: "#AccountRecovery",
        SIGNIN_CANCEL: "#StudentSignedOut",
        SIGNIN_SUBMIT: "#HasValidCredentials?",
      },
    },
    StudentSignup: {
      id: "StudentSignup",
      states: {},
      on: {
        SIGNUP_CANCEL: "#StudentSignedOut",
        SIGNUP_SUBMIT: "#StudentSignupAgreement",
      },
    },
    AccountRecovery: {
      id: "AccountRecovery",
      states: {},
      on: {
        RECOVERY_CANCEL: "#StudentSignin",
        RECOVERY_SUBMIT: "#IsAccountRecoverySuccessful?",
      },
    },
    StudentSignupParentalGate: {
      id: "StudentSignupParentalGate",
      states: {},
      on: {
        OVERLAY_CLOSE: "#StudentSignedOutLanding",
        GATE_SUCCESS: "#StudentSignup",
        GATE_FAILURE: "#StudentSignupParentalGateFailure",
      },
    },
    StudentSignupParentalGateFailure: {
      id: "StudentSignupParentalGateFailure",
      states: {},
      on: {
        OVERLAY_CLOSE: "#StudentSignedOutLanding",
        GATE_RETRY: "#StudentSignupParentalGate",
      },
    },
    StudentSignupAgreement: {
      id: "StudentSignupAgreement",
      states: {},
      on: {
        AGREEMENT_ACCEPT: "#HasValidSignupData?",
        OVERLAY_CLOSE: "#StudentSignup",
      },
    },
    StudentAnonymousSignupFailure: {
      id: "StudentAnonymousSignupFailure",
      states: {},
      on: {
        ANONYMOUS_SIGNUP_CANCEL: "#StudentSignedOut",
        ANONYMOUS_SIGNUP_SUBMIT: "#HasValidAnonymousSignupData?",
      },
    },
    StudentSignupFailure: {
      id: "StudentSignupFailure",
      states: {},
      on: {
        SIGNUP_CANCEL: "#StudentSignedOut",
        SIGNUP_SUBMIT: "#HasValidSignupData?",
      },
    },
    StudentAnonymousSignup: {
      id: "StudentAnonymousSignup",
      states: {},
      on: {
        ANONYMOUS_SIGNUP_CANCEL: "#StudentSignedOut",
        ANONYMOUS_SIGNUP_SUBMIT: "#HasValidAnonymousSignupData?",
      },
    },
    "IsAccountRecoverySuccessful?": {
      id: "IsAccountRecoverySuccessful?",
      states: {},
      on: {
        RECOVERY_FAILURE: "#AccountRecoveryFailure",
        RECOVERY_SUCCESS: "#StudentSignin",
      },
    },
    "HasValidAnonymousSignupData?": {
      id: "HasValidAnonymousSignupData?",
      states: {},
      on: {
        ANONYMOUS_SIGNUP_FAILURE: "#StudentAnonymousSignupFailure",
        ANONYMOUS_SIGNUP_SUCCESS: "#StudentSignedIn",
      },
    },
  },
  initial: "StudentSignedOutLanding",
  on: {},
}
