import React, { Component } from "react"
import Markdown from "react-markdown"
import FadeInOut from "components/Animations/FadeInOut"

import classnames from "classnames"

import always from "ramda/src/always"
import cond from "ramda/src/cond"
import curry from "ramda/src/curry"
import equals from "ramda/src/equals"
import isNil from "ramda/src/isNil"
import T from "ramda/src/T"

const getBubbleClass = cond([
  [equals("to"), always("bubble-default bubble__sent")],
  [equals("interactive"), always("bubble__interactive")],
  [equals("info"), always("bubble__info")],
  [equals("tip"), always("bubble__tip")],
  [equals("option"), always("bubble__option")],
  [T, always("bubble-default bubble__received")],
])

export const getBubbleDelay = curry(
  (factor, bubbleIndex) => (bubbleIndex + 1) * factor,
)

export default class Bubble extends Component {
  render = () => {
    const {
      align,
      block,
      children,
      className,
      delay = 0,
      duration = 200,
      provided,
      isDraggingOver,
      ...props
    } = this.props
    const { title, type } = props

    const bubbleClassname = classnames(
      "bubble",
      `${getBubbleClass(type)}`,
      {
        "bubble__dragging-over": isDraggingOver,
        "bubble-block": block,
        "text-center": equals("center", align),
      },
      className,
    )

    const baseBubbleProps = {
      className: bubbleClassname,
      ...props,
    }

    const bubbleProps = cond([
      [isNil, () => baseBubbleProps],
      [
        T,
        () => ({
          ref: provided.innerRef,
          ...provided.droppableProps,
          ...baseBubbleProps,
        }),
      ],
    ])(provided)

    return (
      <FadeInOut
        delay={delay}
        duration={duration}
        from={{
          opacity: 0,
          transform: "translateY(0.3rem)",
        }}
        to={{
          opacity: 1,
          transform: "translateY(0rem)",
        }}
      >
        <span {...bubbleProps}>
          {type === "info" &&
            title && <span className="bubble__info-title">{title}</span>}
          {type === "tip" &&
            title && <span className="bubble__tip-title text-left">{title}</span>}
          {equals("object", typeof children) ? (
            children
          ) : (
            <Markdown escapeHtml={false}>
              {decodeURIComponent(children)}
            </Markdown>
          )}
        </span>
      </FadeInOut>
    )
  }
}
