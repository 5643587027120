import React from "react"
import "./ConnectionWarning.scss"

const ConnectionWarning = () => (
  <div
    className={`d-flex flex-column align-items-center justify-content-center h-100 w-100 bg-backdrop-brainimals-deep-sea-blue position-absolute zindex-backdrop px-3 env-padding`}
    style={{ zIndex: 2999 }}
  >
    <h1 className={"text-center text-brainimals-green-light"}>Du bist gerade offline.</h1>
    <p className="connection-warning text-white env-margin-left env-margin-right">
      Bitte stelle wieder eine Internetverbindung her, um OLLIS weiter nutzen zu können.
    </p>
  </div>
)

export default ConnectionWarning
