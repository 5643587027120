import "./StudentQuestionActive.scss"

import { AuthenticationContext, StateMachineContext } from "contexts"
import { State } from "react-automata"

import React, { Component } from "react"

import { DragDropContext } from "react-beautiful-dnd"

import { CloseButton } from "components/Assets"

import StudentQuestionAnswer from "../StudentQuestionAnswer/StudentQuestionAnswer"
import StudentQuestionDialog from "../StudentQuestionDialog/StudentQuestionDialog"
import StudentQuestionHelp from "../StudentQuestionHelp/StudentQuestionHelp"
import StudentQuestionHeader from "../StudentQuestionHeader/StudentQuestionHeader"
import StudentQuestionFooter from "../StudentQuestionFooter/StudentQuestionFooter"

import {
  NoticeError,
  NoticeSuccess,
} from "../StudentQuestionNotice/StudentQuestionNotice"

import always from "ramda/src/always"
import and from "ramda/src/and"
import cond from "ramda/src/cond"
import equals from "ramda/src/equals"
import gt from "ramda/src/gt"
import isNil from "ramda/src/isNil"
import length from "ramda/src/length"
import not from "ramda/src/not"
import T from "ramda/src/T"
import take from "ramda/src/take"

import { analytics } from "helpers/tracking"

import Owl from "components/Assets/Owl/Owl"
import FadeInOut from "components/Animations/FadeInOut"

import {
  getQuestionAnswers,
  getQuestionOptionsList,
  getQuestionTitle,
  getQuestionType,
  getQuestionDialogList,
  getQuestionErrorNoticeList1st,
  getQuestionErrorNoticeList2nd,
  getQuestionErrorNoticeList3rd,
  getQuestionErrorNoticeTip2nd,
  getQuestionMessageSuccess,
  getQuestionPlaceholdersList,
} from "helpers/data/question"
import StudentQuestionTip from "../StudentQuestionTip/StudentQuestionTip"

class StudentQuestionActiveComponent extends Component {
  owlReference = React.createRef()
  owlCornerReference = React.createRef()

  constructor(props) {
    super(props)

    this.handleOpenHelp = this.handleOpenHelp.bind(this)
    this.handleCloseHelp = this.handleCloseHelp.bind(this)

    this.handleCalculateRewards = this.handleCalculateRewards.bind(this)
    this.handleRetry = this.handleRetry.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.onDragEnd = this.onDragEnd.bind(this)
  }

  static defaultProps = {
    timestampStart: Date.now(),
  }

  state = {
    canOpenHelp: true,
    hadAccessToHelp: false,
    helpCount: 0,
    currentAttempts: 0,
    studentAnswer: [],
  }

  addAnswer = value => {
    const { transition } = this.props
    this.setState(
      {
        studentAnswer: [...this.state.studentAnswer, value],
      },
      () => {
        cond([
          [
            equals,
            () => {
              transition("ANSWER_ADD_AND_DISABLE")
              window.requestAnimationFrame(() =>
                cond([[equals("multiple"), this.evaluateAnswer]])(
                  this.questionType,
                ),
              )
            },
          ],
          [
            T,
            () => {
              transition("ANSWER_ADD")
            },
          ],
        ])(length(this.state.studentAnswer), length(this.questionAnswer))
      },
    )
  }

  eraseAnswer = () => {
    const { studentAnswer } = this.state
    const { transition } = this.props

    const takeCount = length(studentAnswer) - 1

    this.setState(
      {
        studentAnswer: take(takeCount, studentAnswer),
      },
      () => {
        cond([
          [equals(0), () => transition("ANSWER_ERASE_AND_DISABLE")],
          [T, () => transition("ANSWER_ERASE")],
        ])(length(this.state.studentAnswer))
      },
    )
  }

  evaluateAnswer = () => {
    const { currentAttempts, studentAnswer } = this.state
    const { transition } = this.props

    transition("ANSWER_SUBMIT")

    const increasedAttempts = {
      currentAttempts: currentAttempts + 1,
    }

    const triggerSuccess = () =>
      window.requestAnimationFrame(() => {
        this.owlReference.setAnimationType("happy")
        this.owlCornerReference.setAnimationType("happy")
        this.setState(increasedAttempts, () => transition("DISPLAY_SUCCESS"))
      })

    const triggerFailure = () =>
      window.requestAnimationFrame(() => {
        this.owlReference.setAnimationType("sad")
        this.owlCornerReference.setAnimationType("sad")
        this.setState(increasedAttempts, () => transition("DISPLAY_ERROR"))

        window.requestAnimationFrame(this.displayErrorNotice)
      })

    return cond([[equals, triggerSuccess], [T, triggerFailure]])(
      studentAnswer,
      this.questionAnswer,
    )
  }

  displayErrorNotice = () => {
    this.props.transition(`ERROR_${this.state.currentAttempts}`)
  }

  handleOpenHelp = () => {
    this.setState(
      {
        canOpenHelp: false,
        hadAccessToHelp: true,
        helpCount: this.state.helpCount + 1,
      },
      () => {
        analytics.track("Opened Help", {
          category: "User",
          label: "Question",
          value: this.state.helpCount,
          nonInteraction: false,
        })
        this.props.transition("HELP_OPEN", { canOpenHelp: true })
      },
    )
  }

  handleCloseHelp = () => {
    this.setState({ canOpenHelp: true }, () =>
      this.props.transition("HELP_CLOSE"),
    )
  }

  handleCalculateRewards = () => {
    const {
      studentAnswer: activeAnswers,
      currentAttempts: activeAttempts,
      hadAccessToHelp: hadAccessToHelpModal,
      helpCount,
    } = this.state

    // INFO: There's a bit of remapping below to match following views
    const {
      currentStep,
      exercise,
      question: activeQuestion,
      questionExecutionCount,
      questionList,
      program,
      timestampStart,
    } = this.props

    // INFO: There's a bit of remapping below to match following views
    const extendedState = {
      activeAnswers,
      activeAttempts,
      nextStep: currentStep + 1,
      hadAccessToHelpModal,
      helpCount,
      exercise,
      activeQuestion,
      questionExecutionCount,
      questionList,
      program,
      timestampStart,
    }

    this.props.transition("CONTINUE", extendedState)
  }

  handleRetry = () => {
    this.setState(
      { studentAnswer: [] },
      this.props.transition("QUESTION_RETRY"),
    )
  }

  handleClose = () => {
    this.props.transition("QUESTION_EXIT")
  }

  fstNoticeProps = {
    noticeList: getQuestionErrorNoticeList1st(this.props.question),
    icon: "owl.answer.wrong_2",
  }

  sndNoticeProps = {
    noticeList: getQuestionErrorNoticeList2nd(this.props.question),
    // icon: "owl.answer.wrong_1",
  }

  sndNoticeTip = getQuestionErrorNoticeTip2nd(this.props.question)

  trdNoticeProps = {
    noticeList: getQuestionErrorNoticeList3rd(this.props.question),
    icon: "owl.answer.wrong_2",
  }

  currentProgress = this.props.currentStep + 1
  totalProgress = length(this.props.questionList)
  questionTitle = getQuestionTitle(this.props.question)
  questionType = getQuestionType(this.props.question)
  questionDialogList = getQuestionDialogList(this.props.question)
  questionOptionList = getQuestionOptionsList(this.props.question)
  questionMessageSuccess = getQuestionMessageSuccess(this.props.question)
  questionAnswer = getQuestionAnswers(this.props.question)
  hasMultipleAnswers = gt(length(this.questionAnswer), 1)

  questionPlaceholderList = cond([
    [isNil, always([])],
    [T, () => getQuestionPlaceholdersList(this.props.question)],
  ])(this.props.question)

  onDragEnd = dragInfo => {
    const { draggableId, destination } = dragInfo

    if (isNil(destination)) {
      return
    }

    const { droppableId } = destination

    cond([[and, () => this.addAnswer(draggableId)]])(
      not(isNil(draggableId)),
      equals("studentAnswerBubble", droppableId),
    )
  }

  render = () => (
    <DragDropContext onDragEnd={this.onDragEnd}>
      <State is="*StudentQuestion*">
        <CloseButton onClick={this.handleClose} />
      </State>

      <State
        is={[
          "*StudentQuestionAnswerEmpty",
          "*StudentQuestionAnswerNotEmpty",
          "*StudentQuestionAnswerComplete",
        ]}
      >
        <FadeInOut delay={200}>
          <div
            className="help-button cursor-pointer env-margin-top"
            onClick={this.handleOpenHelp}
          >
            <Owl ref={ref => (this.owlCornerReference = ref)} />
          </div>
        </FadeInOut>
      </State>

      <State is="*StudentQuestionAnswerEvaluation">
        <FadeInOut delay={200}>
          <div className="help-button env-margin-top">
            <Owl ref={ref => (this.owlCornerReference = ref)} />
          </div>
        </FadeInOut>
      </State>

      <StudentQuestionHelp handleClose={this.handleCloseHelp} />
      <div className="d-flex flex-column h-100 env-margin-top">
        <StudentQuestionHeader
          currentProgress={this.currentProgress}
          totalProgress={this.totalProgress}
        />
        <div className="d-flex flex-grow-1 flex-basis-auto align-content-center position-relative p-2">
          <div
            className="question-dialog-container col-11 col-sm-8 col-lg-6 mx-auto"
            style={{ whiteSpace: "pre-line" }}
          >
            <div className="question-dialog d-flex flex-column h-100">
              <State
                is={[
                  "*StudentQuestionAnswerEmpty",
                  "*StudentQuestionAnswerNotEmpty",
                  "*StudentQuestionAnswerComplete",
                ]}
              >
                <FadeInOut delay={200}>
                  <div
                    className="help-character cursor-pointer"
                    onClick={this.handleOpenHelp}
                  >
                    <Owl ref={ref => (this.owlReference = ref)} />
                  </div>
                </FadeInOut>
              </State>

              <State is="*StudentQuestionAnswerEvaluation">
                <FadeInOut delay={200}>
                  <div className="help-character">
                    <Owl ref={ref => (this.owlReference = ref)} />
                  </div>
                </FadeInOut>
              </State>

              {/* <State is="*StudentQuestionErrorEmpty"> */}
              <StudentQuestionDialog dialogList={this.questionDialogList} />
              {/* </State> */}
              <div className="question-dialog-footer d-flex flex-column flex-shrink-0 p-2 mt-auto">
                <State is={["*StudentQuestionAnswer*"]}>
                  <StudentQuestionAnswer
                    questionPlaceholderList={this.questionPlaceholderList}
                    studentAnswer={this.state.studentAnswer}
                    questionAnswer={this.questionAnswer}
                  />
                </State>

                <State is="*StudentQuestionSuccessActive">
                  <NoticeSuccess>{this.questionMessageSuccess}</NoticeSuccess>
                </State>

                <State is="*StudentQuestionErrorActive1st">
                  <NoticeError {...this.fstNoticeProps} />
                </State>
                <State is="*StudentQuestionErrorActive2nd">
                  <NoticeError {...this.sndNoticeProps} />
                  <StudentQuestionTip>{this.sndNoticeTip}</StudentQuestionTip>
                </State>
                <State is="*StudentQuestionErrorActive3rd">
                  <NoticeError {...this.trdNoticeProps} />
                  <StudentQuestionAnswer
                    notice
                    questionPlaceholderList={this.questionPlaceholderList}
                    studentAnswer={this.questionAnswer}
                    questionAnswer={this.questionAnswer}
                  />
                </State>
              </div>
            </div>
          </div>
        </div>
        <StudentQuestionFooter
          optionList={this.questionOptionList}
          addAnswer={this.addAnswer}
          eraseAnswer={this.eraseAnswer}
          evaluateAnswer={this.evaluateAnswer}
          handleRetry={this.handleRetry}
          handleCalculateRewards={this.handleCalculateRewards}
          hasMultipleAnswers={this.hasMultipleAnswers}
        />
      </div>
    </DragDropContext>
  )
}

const StudentQuestionActive = () => (
  <AuthenticationContext.Consumer>
    {student => (
      <StateMachineContext.Consumer>
        {props => (
          <StudentQuestionActiveComponent {...props} student={student} />
        )}
      </StateMachineContext.Consumer>
    )}
  </AuthenticationContext.Consumer>
)

export default StudentQuestionActive
