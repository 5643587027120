import cond from "ramda/src/cond"
import equals from "ramda/src/equals"
import T from "ramda/src/T"
import { analytics } from "helpers/tracking"

import Async from "crocks/Async"

export const requestAppReview = Async((failure, success) => {
  if (!window.cordova) {
    const label =
      "Not a native application instance. Rating request will be ignored."
    analytics.track("Ignored Request", {
      category: "App Review",
      label,
      nonInteraction: true,
    })
    failure(label)
  } else {
    const MAX_DIALOG_WAIT_TIME = 5000
    const { LaunchReview } = window
    let requestTimeout

    const timeoutRatingRequest = () => {
      const label = `Rating request timed out after: ${MAX_DIALOG_WAIT_TIME}ms`
      analytics.track("Timed Out Request", {
        category: "App Review",
        label,
        nonInteraction: true,
      })
      failure(label)
      clearTimeout(requestTimeout)
    }

    const handleFailure = error => {
      const label = `Rating request errored with: ${error}`
      analytics.track("Errored Request", {
        category: "App Review",
        label,
        nonInteraction: true,
      })
      failure(label)
    }

    const handleSuccess = cond([
      [
        equals("requested"),
        () => {
          const label = "App Review dialog requested"
          analytics.track("Triggered Request", {
            category: "App Review",
            label,
            nonInteraction: true,
          })
          requestTimeout = setTimeout(
            timeoutRatingRequest,
            MAX_DIALOG_WAIT_TIME,
          )
        },
      ],
      [
        equals("shown"),
        () => {
          clearTimeout(requestTimeout)
          const label = "App Review dialog displayed"
          analytics.track("Displayed Request", {
            category: "App Review",
            label,
            nonInteraction: true,
          })
          success(label)
        },
      ],
      [
        equals("dismissed"),
        () => {
          const label = "App Review dialog dismissed"
          analytics.track("Dismissed Request", {
            category: "App Review",
            label,
            nonInteraction: false,
          })
          failure(label)
        },
      ],
      [
        T,
        _ => {
          const label = `Rating dialog has triggered a call with an unhandled value: ${_}`
          analytics.track("Triggered Unhandled Event", {
            category: "App Review",
            label,
            nonInteraction: true,
          })
          failure(label)
        },
      ],
    ])

    if (LaunchReview.isRatingSupported()) {
      LaunchReview.rating(handleSuccess, handleFailure)
    } else {
      LaunchReview.launch(handleSuccess, handleFailure)
    }
  }
})
