import { StateMachineContext } from "contexts"

import React, { Component } from "react"
import Card from "components/Chat/Card/Card"
import { Asset } from "components/Assets"

import FadeInOut from "components/Animations/FadeInOut"
import {
  getExerciseById,
  getExerciseCover,
  getExerciseTitle,
  getExerciseSubtitle,
} from "helpers/data/exercise"

import cond from "ramda/src/cond"
import equals from "ramda/src/equals"
import isNil from "ramda/src/isNil"
import T from "ramda/src/T"

class ExerciseCard extends Component {
  componentDidMount() {
    const { exerciseId } = this.props
    getExerciseById(exerciseId).fork(console.error, exercise =>
      this.setState({ exercise }),
    )
  }

  state = { exercise: null }

  gotoExercise = exercise => () => {
    const { transition, program } = this.props
    return transition("EXERCISE_ENTER", { exercise, program })
  }

  triggerDisabledNotification = () => {
    this.props.transition("NOTIFICATION_OPEN", {
      notificationHeadline: "Hallo,",
      message: "löse die Karten der Reihe nach.",
      onCloseCallback: this.props.onNotificationClose,
    })
  }

  render() {
    const { exercise } = this.state
    const { disabled, finished } = this.props

    const cardCover = cond([
      [
        isNil,
        () => (
          <div className="d-flex justify-content-center align-items-center position-absolute h-100 w-100">
            <div className="exercise-card-bg-element">
              <Asset
                type="svg"
                path="card.bg.1"
                style={{ height: "140px", width: "140px" }}
              />
            </div>
            <div className="exercise-card-bg-element">
              <Asset
                type="svg"
                path="card.bg.2"
                style={{ height: "140px", width: "140px" }}
              />
            </div>
            <div className="exercise-card-bg-symbol">
              <Asset
                type="svg"
                path="tree.green.light.eyes"
                style={{ height: "100px", width: "100px" }}
              />
            </div>
          </div>
        ),
      ],
      [
        T,
        () => (
          <div className="exercise-card-cover">
            <Asset
              alt={getExerciseTitle(exercise)}
              type="svg"
              path={`exercises/${getExerciseCover(
                "default",
                this.state.exercise,
              )}`}
            />
          </div>
        ),
      ],
    ])(getExerciseCover("default", this.state.exercise))

    const cardAction = cond([
      [
        equals(true),
        () => (
          <h3 className="exercise-card-action">
            <span className={"exercise-card-action-icon"}>
              <Asset path={"icon.locked"} type="svg" />
            </span>
          </h3>
        ),
      ],
      [
        T,
        () =>
          cond([
            [
              equals(true),
              () => (
                <h3 className="exercise-card-action">
                  <span className={"exercise-card-action-icon"}>
                    <Asset path={"icon.repeat"} type="svg" />
                  </span>
                  Wiederholen
                </h3>
              ),
            ],
            [T, () => <h3 className="exercise-card-action">Aufgabe lösen</h3>],
          ])(finished),
      ],
    ])(disabled)

    const cardBadge = disabled ? null : (
      <span className={"exercise-card-badge"}>
        <Asset path={finished ? "coin-active" : "coin-inactive"} type="svg" />
      </span>
    )

    return exercise ? (
      <Card
        finished={finished}
        disabled={disabled}
        onClick={disabled ? this.triggerDisabledNotification : this.gotoExercise(exercise)}
        title={getExerciseTitle(exercise)}
        subtitle={getExerciseSubtitle(exercise)}
      >
        <>
          {cardBadge}
          <FadeInOut delay={250}>
            <>
              {cardCover}
              {cardAction}
            </>
          </FadeInOut>
        </>
      </Card>
    ) : (
      <Card onClick={() => {}} title="" subtitle="">
        <div className="text-center position-relative" />
      </Card>
    )
  }
}

export default props => (
  <StateMachineContext.Consumer>
    {machineProps => <ExerciseCard {...machineProps} {...props} />}
  </StateMachineContext.Consumer>
)
