import React, { Component } from "react"
import { StateMachineContext } from "contexts"
import { State } from "react-automata"

// import { Draggable, Droppable } from "react-beautiful-dnd"

import Asset from "components/Assets/Asset"
import Button from "components/Exercise/Keyboards/Button/Button"
import { getBubbleDelay } from "components/Chat/Bubble/Bubble"

import addIndex from "ramda/src/addIndex"
import curry from "ramda/src/curry"
import map from "ramda/src/map"

const makeOptionList = curry((actionFn, list) =>
  addIndex(map)((option, index) => {
    // <Draggable key={index} draggableId={String(option)} index={index}>
    //   {(provided, snapshot) => {
    const optionBaseProps = {
      delay: getBubbleDelay(index),
      type: "numpad",
      disabled: true,
    }

    const optionActiveProps = {
      // ...provided.draggableProps,
      // ...provided.dragHandleProps,
      ...optionBaseProps,
      // provided: provided,
      // isDragging: snapshot.isDragging,
      "data-value": option,
      onClick: () => actionFn(option),
      disabled: false,
    }

    return (
      <React.Fragment key={index}>
        <State
          is={["*StudentQuestionAnswerEmpty", "*StudentQuestionAnswerNotEmpty"]}
        >
          <Button {...optionActiveProps}>{option}</Button>
        </State>
        <State is={["*StudentQuestionAnswerComplete"]}>
          <Button {...optionBaseProps}>{option}</Button>
        </State>
      </React.Fragment>
    )
    //   }}
    // </Draggable>
  })(list),
)

class KeyboardNumpadComponent extends Component {
  evaluateButtonBaseProps = {
    children: <Asset path="check" type="svg" />,
    delay: getBubbleDelay(12),
    duration: 50,
    key: "confirm",
    type: "confirm",
    disabled: true,
  }

  evaluateButtonActiveProps = {
    ...this.evaluateButtonBaseProps,
    onClick: this.props.evaluateAnswer,
    disabled: false,
  }

  eraseButtonBaseProps = {
    children: <Asset path="eraser" type="svg" />,
    delay: getBubbleDelay(22),
    duration: 50,
    key: "erase",
    type: "erase",
    disabled: true,
  }

  eraseButtonActiveProps = {
    ...this.eraseButtonBaseProps,
    onClick: this.props.eraseAnswer,
    disabled: false,
  }

  render = () => {
    const { addAnswer } = this.props

    return (
      // <Droppable droppableId={"numpadKeyboard"} isDropDisabled>
      //   {provided => (
      //     <span ref={provided.innerRef} {...provided.droppableProps}>
      <span>
        <div className="d-flex justify-content-between mx-auto">
          {makeOptionList(addAnswer, ["1", "2", "3", "4", "5"])}
          <State
            is={[
              "*StudentQuestionAnswerEmpty",
              "*StudentQuestionAnswerNotEmpty",
            ]}
          >
            <Button {...this.evaluateButtonBaseProps} />
          </State>
          <State is="*StudentQuestionAnswerComplete">
            <Button {...this.evaluateButtonActiveProps} />
          </State>
        </div>
        <div className="d-flex justify-content-between mx-auto">
          {makeOptionList(addAnswer, ["6", "7", "8", "9", "0"])}
          <State is="*StudentQuestionAnswerEmpty">
            <Button {...this.eraseButtonBaseProps} />
          </State>
          <State
            is={[
              "*StudentQuestionAnswerNotEmpty",
              "*StudentQuestionAnswerComplete",
            ]}
          >
            <Button {...this.eraseButtonActiveProps} />
          </State>
        </div>
      </span>
      //   )}
      // </Droppable>
    )
  }
}

const KeyboardNumpad = componentProps => (
  <StateMachineContext.Consumer>
    {props => <KeyboardNumpadComponent {...componentProps} {...props} />}
  </StateMachineContext.Consumer>
)

export default KeyboardNumpad
