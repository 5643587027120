/*eslint-disable no-console*/
import "./StudentSignedInLanding.scss"

import { UserContext, StateMachineContext } from "contexts"

import Async from "crocks/Async"
import React, { Component } from "react"
import { func, object } from "prop-types"
import FadeInOut from "components/Animations/FadeInOut"
import Bubble from "components/Chat/Bubble/Bubble"
import ProgressItem from "components/Chat/ProgressItem/ProgressItem"

import addIndex from "ramda/src/addIndex"
import map from "ramda/src/map"
import compose from "ramda/src/compose"
import cond from "ramda/src/cond"
import length from "ramda/src/length"
import isEmpty from "ramda/src/isEmpty"
import merge from "ramda/src/merge"
import not from "ramda/src/not"
import path from "ramda/src/path"
import prop from "ramda/src/prop"
import sortBy from "ramda/src/sortBy"
import T from "ramda/src/T"
import uniqBy from "ramda/src/uniqBy"
import nth from "ramda/src/nth"

import { Container, Col, Row } from "reactstrap"
import { Worm, Loader, Footer, Asset } from "components/Assets"

import { getStudentHistoryExercisesByProgramId } from "helpers/data/studentHistoryExercises"
import {
  getChapterById,
  getChapterPrograms,
  getChapterIndex,
  getChapterTitle,
  getChapterRelatedPrograms,
} from "helpers/data/chapter"
import { getProgramExercises, getProgramId } from "helpers/data/program"
import { analytics } from "helpers/tracking"
import { withUser } from "contexts/UserContext"

const TealTree = () => (
  <Asset type="svg" path="tree.default" style={{ width: "100%" }} />
)

const TealFlower = () => (
  <Asset type="svg" path="flower.teal" style={{ width: "100%" }} />
)

class StudentSignedInLanding extends Component {
  static propTypes = {
    transition: func,
    student: object,
  }

  state = {
    chapters: [],
    programsDone: [],
    programsPending: [],
    programsLocked: [],
    loadingInitialized: false,
  }

  constructor(props) {
    super(props)
    this.goToProgram = this.goToProgram.bind(this)
  }

  componentDidMount() {
    analytics.page(null, "Signedin Landing", {
      path: "/StudentSignedInLanding",
    })
    this.fetchData()

    document.addEventListener("online", this.fetchData, false)
  }

  componentDidUpdate() {
    this.fetchData()
  }

  componentWillUnmount() {
    document.removeEventListener("online", this.fetchData)
  }

  hydrateProgramList = (program, doneExercises) => {
    const nextExercises = getProgramExercises(program)
    const nextExercisesTotal = length(nextExercises)
    const doneExercisesTotal = compose(
      length,
      uniqBy(path(["learning_exercise", "id"])),
    )(doneExercises)

    const baseProps = {
      countExercisesTotal: nextExercisesTotal,
      countExercisesDone: doneExercisesTotal,
      active: true,
    }

    return cond([
      [
        compose(
          not,
          isEmpty,
        ),
        () => merge(baseProps, program),
      ],
      [T, () => []],
    ])(program)
  }

  fetchData() {
    const { student } = this.props
    const { loadingInitialized } = this.state
    if (!student || loadingInitialized) {
      // Skip when student is yet not available from context (will try again via componentDidUpdate)
      // or loading already initialized
      return
    }
    this.setState({ loadingInitialized: true })

    Async.all([getChapterById("MATH01"), getChapterById("MATH02")]).fork(
      console.error,
      chapterList => map(this.getChapterPrograms, chapterList),
    )
  }

  getChapterPrograms = chapter => {
    const { student } = this.props
    const chapterIndex = getChapterIndex(chapter)
    const chapterTitle = getChapterTitle(chapter)
    const chapterPrograms = getChapterPrograms(chapter)

    getChapterRelatedPrograms(chapterPrograms).fork(
      console.error,
      chapterRelatedPrograms => {
        const programExerciseCountRequestList = map(
          _ =>
            getStudentHistoryExercisesByProgramId(student.id, getProgramId(_)),
          chapterRelatedPrograms,
        )

        Async.all(programExerciseCountRequestList).fork(
          console.error,
          programHistoryList =>
            this.setState({
              chapters: [
                ...this.state.chapters,
                {
                  chapterIndex,
                  chapterTitle,
                  chapterProgramList: addIndex(map)(
                    (program, index) =>
                      this.hydrateProgramList(
                        program,
                        programHistoryList[index],
                      ),
                    chapterRelatedPrograms,
                  ),
                },
              ],
            }),
        )
      },
    )
  }

  goToProgram = program => () => {
    this.props.transition("GOTO_PROGRAM", { program })
  }

  render() {
    const { chapters } = this.state
    const { userContext: { learningRecommendation } } = this.props

    if (chapters.length) {
      const makeProgramList = addIndex(map)((program, index) => (
        <ProgressItem
          learningRecommendationProgramId={nth(learningRecommendation, ['AS01','TP01', 'TP05'])}
          key={index}
          program={program}
          onClick={this.goToProgram(program)}
        />
      ))

      const makeChapterList = addIndex(map)(
        ({ chapterTitle, chapterProgramList }, index) => (
          <React.Fragment key={index}>
            <h4 className="signed-in-landing__headline">{chapterTitle}</h4>
            {makeProgramList(chapterProgramList)}
          </React.Fragment>
        ),
        sortBy(prop("chapterIndex"), this.state.chapters),
      )

      return (
        <div className={"overflow-y-scroll h-100 env-margin-top"}>
          <Container
            fluid
            className="d-flex flex-column justify-content-between signed-in-landing__container px-0 h-100"
          >
            <Col
              xs={12}
              sm={11}
              md={10}
              lg={8}
              xl={6}
              className="d-flex flex-grow-1 flex-basis-auto flex-column justify-content-between mx-auto"
            >
              <Row />
              <Row className="signed-in-landing__intro">
                <Col xs={3} sm={2} className={"order-2 order-sm-1 mx-auto"}>
                  <div className="signed-in-landing__character">
                    <Worm />
                  </div>
                </Col>
                <Col xs={12} sm={10} className={"order-1 order-sm-2"}>
                  <div className="signed-in-landing__salutation">
                    <Bubble className={"font-size-base mb-0"}>
                      Löse diese Aufgaben! Gute Rechner bekommen einen Stern.
                    </Bubble>
                  </div>
                </Col>
              </Row>
              <FadeInOut>
                <div className="signed-in-landing__wrapper">
                  {makeChapterList}
                  <p className="signed-in-landing__more-text">
                    Bald kommen neue Aufgaben!
                  </p>
                </div>
              </FadeInOut>
              <div className="signed-in-landing__bottom-items">
                <TealTree />
                <TealFlower />
                <TealTree />
                <TealFlower />
              </div>
            </Col>
            <Footer />
          </Container>
        </div>
      )
    } else {
      return <Loader />
    }
  }
}

const StudentSignedInLandingContainer = ({ userContext }) => (
  <UserContext.Consumer>
    {({ student }) => (
      <StateMachineContext.Consumer>
        {props => (
          <StudentSignedInLanding
            userContext={userContext}
            {...props}
            student={student}
          />
        )}
      </StateMachineContext.Consumer>
    )}
  </UserContext.Consumer>
)

export default withUser(StudentSignedInLandingContainer)
