import React, { PureComponent } from "react"

import cond from "ramda/src/cond"
import equals from "ramda/src/equals"

import owlDataBlink from "assets/animations/owl.blink.json"
import owlDataSad from "assets/animations/owl.sad.json"
import owlDataHappy from "assets/animations/owl.happy.json"
import owlDataWave from "assets/animations/owl.wave.json"
import owlDataMove from "assets/animations/owl.move.json"

import Animation from "components/Assets/Animation/Animation"

const animationDataList = {
  blink: owlDataBlink,
  sad: owlDataSad,
  happy: owlDataHappy,
  wave: owlDataWave,
  move: owlDataMove,
}

class Owl extends PureComponent {
  state = {
    active: this.props.startWith,
  }

  constructor(props) {
    super(props)

    this.setAnimationType = this.setAnimationType.bind(this)
    this.playAnimation = this.playAnimation.bind(this)
    this.replayAnimation = this.replayAnimation.bind(this)
    this.handleOnComplete = this.handleOnComplete.bind(this)
  }

  componentDidMount() {
    const { active } = this.state
    this.playAnimation(active)
  }

  componentDidUpdate() {
    const { active } = this.state
    this.playAnimation(active)
  }

  setAnimationType(animation) {
    this.setState({ active: animation })
  }

  playAnimation(animation) {
    this[`${animation}Ref`].play()
  }

  replayAnimation(animation) {
    this[`${animation}Ref`].animation.goToAndPlay(0)
  }

  animationTimeout = null

  handleOnComplete(animation) {
    const { active } = this.state
    const { defaultAnimation, maxInterval, minInterval } = this.props

    const parsedMaxInterval =
      maxInterval > minInterval ? maxInterval : minInterval

    cond([
      [equals(animation), () => this.setState({ active: defaultAnimation })],
    ])(active)

    cond([
      [
        equals(defaultAnimation),
        () => {
          this.animationTimeout = setTimeout(
            () => this.replayAnimation(animation),
            Math.floor(Math.random() * parsedMaxInterval) + minInterval,
          )
        },
      ],
    ])(active)
  }

  componentWillUnmount() {
    clearTimeout(this.animationTimeout)
  }

  render() {
    const { active } = this.state
    return (
      <>
        {Object.keys(animationDataList).map(animation => (
          <Animation
            key={animation}
            data={animationDataList[animation]}
            hide={active !== animation}
            autoplay={false}
            onComplete={() => this.handleOnComplete(animation)}
            ref={ref => (this[`${animation}Ref`] = ref)}
          />
        ))}
      </>
    )
  }
}

Owl.defaultProps = {
  startWith: "blink",
  defaultAnimation: "blink",
  maxInterval: 6000,
  minInterval: 250,
}

export default Owl
